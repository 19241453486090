import React, { useState, useContext, useEffect, useRef, useMemo, useReducer } from 'react';
import { GoogleMap } from '../../../utils/googlemaps';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { Modal } from '../../components/modal/Modal';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { SearchTable } from '../../components/utils/SearchTable';
import { notify } from '../../../utils/utils';
import { useModal } from '../../../hooks/useModal';
import { useRegiones } from '../../../hooks/useRegiones';
import { useComisarias } from '../../../hooks/monitoreo/useComisarias'; 
import  iconoComisaria  from '../../../assets/images/IcoUbicacionComisaria04.png'
import { AuthFetch } from '../../../services/api';
import { UserContext } from '../../../context/provider/UserProvider';

var verticesPoligono = [
    { lat: -12.039203, lng: -77.066496 },
    { lat: -12.060808, lng: -77.057635 },
    { lat: -12.061757, lng: -77.027896 },
];

const googleMap = new GoogleMap();

const jurisdiccionDefault = {
    codDepartamento: 0,
    codProvincia: 0,
    codDistrito: 0,
    poligono: null,
    puntosPoligno: '',
    nombre: '',
    codJurisdiccion: 0,
    concatenadoComisarias: ''
}

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

const jurisdiccionTypes = {
    generarPoligono: 'generar-poligono',
    agregarPuntosPoligono: 'agregar-puntos-poligono',
    toggleComisaria: 'toggle-comisaria',
    agregarValoresEdicion: 'agregar-valores-edicion',
    editarValorJurisdiccion: 'editar-valor-jurisdiccion',
    reiniciarJurisdiccion: 'reiniciar-jurisdiccion'
}

const jurisdiccionReducer = (state, action) => {
    switch (action.type) {
        case jurisdiccionTypes.generarPoligono:
            try {
                googleMap.quitarPoligono('nuevo-poligono');
            } catch (err) {

            }

            const nuevoPoligono = googleMap.crearPoligono({
                id: 'nuevo-poligono',
                listLatLng: verticesPoligono,
                editable: true,
                draggable: true,
                color: '#2BA8FF'             
            })
    
            nuevoPoligono.addListener('contextmenu', (e) => {
                action.callaback();
            })
    
            return {
                ...state,
                poligono: nuevoPoligono
            }
        case jurisdiccionTypes.agregarPuntosPoligono: 
            const poligonoActual = action?.payload?.poligono || state.poligono;
            let puntos = '';

            poligonoActual.getPath().forEach(punto => {
                puntos += `${punto.lat()};${punto.lng()},`
            })

            puntos = puntos.substring(0, puntos.length - 1);
            
            return {
                ...state,
                puntosPoligno: puntos
            }        
        case jurisdiccionTypes.toggleComisaria: 
            const { comisaria, checked } = action.payload;
            let concatenado = ''
            if (checked) {
                concatenado = state.concatenadoComisarias + comisaria.codComisaria + ','
            } else {
                concatenado = state?.concatenadoComisarias?.replaceAll(comisaria.codComisaria + ',', '');
            }
            state.nombre=comisaria.nomComisaria
            return {
                ...state,
                concatenadoComisarias: concatenado
            }
        case jurisdiccionTypes.agregarValoresEdicion:
            return {
                ...state,
                ...action.payload
            }
        case jurisdiccionTypes.editarValorJurisdiccion:
            const { key, value } = action.payload;
            return {
                ...state,
                [key] : value
            }
        case jurisdiccionTypes.reiniciarJurisdiccion:
            action?.payload?.quitarPoligono && googleMap.quitarPoligono('nuevo-poligono');

            return {
                ...jurisdiccionDefault,
                poligono: action?.payload?.quitarPoligono ? null : state.poligono
            }
        default:
            throw new Error();
    }
}

const useCatastroComisaria = (openModal, obtenerComisariasSinJurisdiccion) => {
    const { comisarias, obtenerComisarias } = useComisarias();
    const [ , , departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ] = useRegiones();
    const [ state, dispatch ] = useReducer(jurisdiccionReducer, jurisdiccionDefault);

    const listarJurisdicciones = async () => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + `/Regiones/jurisdicciones`
        });

        if (respuesta.isValid) {
            const jurisdicciones = respuesta.content;
            googleMap.quitarTodosLosPoligonos();

            jurisdicciones.forEach(jurisdiccion => {
                const listLatLng = jurisdiccion.puntosJurisdiccionPoligonoMapa.split(',').map(latLng => {
                    const [ lat, lng ] = latLng.split(';');
                    
                    return {
                        lat: Number(lat), lng: Number(lng)
                    }
                    
                })

                if (!googleMap.poligonos[jurisdiccion.codJurisdiccion]) {
                    const poligono = googleMap.crearPoligono({
                        id: jurisdiccion.codJurisdiccion,
                        listLatLng: listLatLng,
                    })

                    poligono.addListener('dblclick', () => {
                        poligono.setOptions({
                            editable: !poligono.getEditable(),
                            draggable: !poligono.getDraggable()
                        })
                    })

                    poligono.addListener('contextmenu', (e) => {
                        openModal();
                        dispatch({type: jurisdiccionTypes.agregarPuntosPoligono, payload: { poligono }})
                        dispatch({ type: jurisdiccionTypes.agregarValoresEdicion, payload: { 
                            codDepartamento: jurisdiccion.codDepartamento,
                            codProvincia: jurisdiccion.codProvincia,
                            codDistrito: jurisdiccion.codDistrito,
                            // nombre: jurisdiccion.nomComisaria,
                            nombre: jurisdiccion.nomJurisdiccion,
                            codJurisdiccion: jurisdiccion.codJurisdiccion,
                        }})

                        obtenerComisariasSinJurisdiccion(jurisdiccion.comisarias)
                        jurisdiccion.comisarias.forEach(c => {
                            dispatch({ type: jurisdiccionTypes.toggleComisaria, payload: {
                                comisaria: c, 
                                checked: true
                            } })
                        })
                    })
                }
            })
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const guardarJurisdiccion = async (callback) => {
        if (state.nombre.trim().length === 0) {
            notify('Debe ingresar el nombre de la jurisdicción', 'error');
            return state;
        }

        if (state.codDistrito === 0) {
            notify('Debe seleccionar un distrito', 'error');
            return state;
        }

        if (state.concatenadoComisarias.trim().length === 0) {
            notify('Debe seleccionar al menos una comisaria', 'error');
            return state;
        }

        const parametros = {
            codJurisdiccion: state.codJurisdiccion,
            nomJurisdiccion: state.nombre,
            puntosJurisdiccionPoligonoMapa: state.puntosPoligno,
            codDistrito: Number(state.codDistrito),
            codUsuarioAccion: 1,
            concatenadoCodComisaria: state.concatenadoComisarias.substring(0, state.concatenadoComisarias.length - 1)
        }

        state.codJurisdiccion === 0 && (delete parametros['codJurisdiccion'])

        const respuesta = await AuthFetch({
            url: urlBasePlamin + `/Regiones/${state.codJurisdiccion === 0 ? 'generarJurisdiccion' : 'editarJurisdiccion'}`,
            method: state.codJurisdiccion > 0 ? 'PUT' : 'POST',
            body: JSON.stringify(parametros)
        });

        notify(respuesta.content, respuesta.isValid ? 'success' : 'error');

        if (respuesta.isValid) {
            callback();
            dispatch({type: jurisdiccionTypes.reiniciarJurisdiccion, payload: { quitarPoligono: state.codJurisdiccion === 0 }})
            listarJurisdicciones()
        }
    }

    
    useMemo(() => {
        obtenerComisarias();
    }, [])

    useMemo(() => {
        const infoWindow = googleMap.crearInfowindow({});
        comisarias.forEach(c => {
            const marcador = googleMap.crearMarcador({
                latLng: { lat: c.latitudComisaria, lng: c.longitudComisaria },
                icon: iconoComisaria,
                animation: null
            })
            marcador.addListener('click', () => {
                infoWindow.setContent(
                    `
                    <div style="text-align:center; margin:5px;"><h1 style="color:#ffffff" >${c.nomComisaria}</h1></div><hr>
                    <div style="color: #FFF; margin-top:8px; margin-bottom:8px ">
                            <label>Departamento: </label></br>
                            <label>Provincia: </label></br>
                            <label>Distrito: </label></br>
                            <label>Div Policial: </label><br>
                            <label>Telefono: </label><br>
                            <label>Direccion: </label><br>
                        <div style="text-align:center">
                            <button style="border: 1px solid; padding:8px; ">Guardar</button>
                        </div>
                    </div>
                    `);
                infoWindow.open({
                    anchor: marcador,
                    map: googleMap.map
                })
            })
        })
    }, [comisarias]);

    useMemo(() => {
        obtenerDepartamentos();
    }, [])

    useMemo(() => {
        obtenerProvincias(Number(state.codDepartamento))
    }, [state.codDepartamento])

    useMemo(() => {
        obtenerDistritos(Number(state.codProvincia))
    }, [state.codProvincia])

    return {
        state,
        dispatch,
        listarJurisdicciones,
        guardarJurisdiccion,
        departamentos,
        provincias,
        distritos
    }
}

export const CatastroComisaria= () => {

    const [ isOpen, openModal, closeModal ] = useModal();
    const { comisariasJurisdiccion, obtenerComisariasSinJurisdiccion } = useComisarias();
    const { state, dispatch, listarJurisdicciones, guardarJurisdiccion, departamentos, provincias, distritos } = useCatastroComisaria(openModal, obtenerComisariasSinJurisdiccion);
    const { stateUser } = useContext(UserContext)

    const mapDiv = useRef();

    const idTablaComisarias = "tabla-comisarias";    
    const classNameInput = "containerScroll  text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const classNameSelect = "containerScroll  text-white h-full rounded px-4 w-[250px] lg:w-[200px] bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"


    useEffect(() => {
        googleMap.inicializarMapa(mapDiv.current, { zoom: 11 });
    }, [])

    useMemo(() => {
        obtenerComisariasSinJurisdiccion();
        listarJurisdicciones();
    }, [])
    
    return (
        <>
            <ContenedorParametros
                titulo="Entidades"
            >
                {
                    stateUser.permisos.find(p => p.nomPermiso === 'Generar Poligono') && (
                        <div>
                            <BotonNuevo onClick={() => dispatch({type: jurisdiccionTypes.generarPoligono, callaback: () => {
                                openModal();
                                dispatch({type: jurisdiccionTypes.agregarPuntosPoligono})
                            }})}>Generar Poligono</BotonNuevo>
                        </div>
                    )
                }
            </ContenedorParametros>
            <div className="mapa-incidentes h-[90%]">
                <div className='w-full h-full' ref={mapDiv}></div>
            </div>

            <Modal
                isOpen={isOpen}
                closeModal={() => {
                    closeModal();
                    obtenerComisariasSinJurisdiccion();
                    dispatch({type: jurisdiccionTypes.reiniciarJurisdiccion})
                }}
                cerrarAlGuardar={false}
                title={"Registrar Poligono"}
                action={() => guardarJurisdiccion(() => {
                    obtenerComisariasSinJurisdiccion();
                    listarJurisdicciones();
                    closeModal();
                })}
                >
                <div className="w-[500px] px-4 flex-col">
                    <div className="flex flex-col justify-between w-full form-content">
                        <div>
                            <label>Nombre Jurisdicción: </label>
                            <div className="flex items-center gap-4 w-[250px]">
                                <input defaultValue={state.nombre} onChange={(e) => dispatch({type: jurisdiccionTypes.editarValorJurisdiccion, payload: {key: 'nombre', value: e.target.value}})} className={`${classNameInput}`} />
                            </div>
                        </div>
                        <div>
                            <label>Departamento: </label>
                            <div className="flex items-center gap-4 w-[250px]">
                                <select className={`${classNameInput}`} value={state.codDepartamento} onChange={(e) => dispatch({type: jurisdiccionTypes.editarValorJurisdiccion, payload: {key: 'codDepartamento', value: e.target.value}})}>
                                    <option value="0">-- Seleccionar --</option>
                                    {
                                        departamentos.map(d => {
                                            return <option key={d.codDepartamento} value={d.codDepartamento}>{d.nomDepartamento}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div>
                            {/* onChange={e => obtenerDistritos(e.target.value)}  */}
                            <label>Provincia: </label>
                            <div className="flex items-center gap-4 w-[250px]">
                                <select className={`${classNameInput}`} value={state.codProvincia} onChange={(e) => dispatch({type: jurisdiccionTypes.editarValorJurisdiccion, payload: {key: 'codProvincia', value: e.target.value}})}>
                                    <option value="0">-- Seleccionar --</option>
                                    {
                                        provincias.map(p => {
                                            return <option key={p.codProvincia} value={p.codProvincia}>{p.nomProvincia}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>Distrito: </label>
                            <div className="flex items-center gap-4 w-[250px]">
                                <select className={`${classNameInput}`} value={state.codDistrito} onChange={(e) => dispatch({type: jurisdiccionTypes.editarValorJurisdiccion, payload: {key: 'codDistrito', value: e.target.value}})}>
                                    <option value="0">-- Seleccionar --</option>
                                    {
                                        distritos.map(d => {
                                            return <option key={d.codDistrito} value={d.codDistrito}>{d.nomDistrito}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="h-[40px]">
                        <SearchTable tablaId={idTablaComisarias}></SearchTable>
                    </div>
                    <div className="containerScroll max-h-[200px] overflow-auto">
                        <table id={idTablaComisarias} className="table">
                            <thead>
                                <tr>
                                    <th>N</th>
                                    <th>COMISARIA</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    comisariasJurisdiccion.map((c, i) => {
                                        return (
                                            <tr key={ c.codComisaria }>
                                                <td>{ i + 1 }</td>
                                                <td>{ c.nomComisaria }</td>
                                                <td></td>
                                                <td>
                                                    <input type="checkbox" defaultChecked={c.checked} onChange={(e) => dispatch({type: jurisdiccionTypes.toggleComisaria, payload: {
                                                        comisaria: c,
                                                        checked: e.target.checked
                                                    }})}></input>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </>
    )
}