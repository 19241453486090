import { useState, useEffect, useContext } from 'react';
import { notify } from '../../utils/utils';
import { useModal } from '../useModal';
import fotoDefault from '../../../src/assets/images/usuarioDefault.jpg';
import { AuthFetch, Fetch } from '../../services/api';
import { UserContext } from '../../context/provider/UserProvider';


const usuarioDefault =  
    {
        uid: "",
        dni: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        correoElectronico: "",
        telefono: "",
        fotoUrl: "",
        rol: [],
        clave:''
    }

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useUsuariosIS = () => {

    
    const [ usuarios, setUsuarios ] = useState([]);
    const [ usuario, setUsuario ] = useState(usuarioDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();
    const [ cargandoDNI, setCargandoDNI ] = useState(false) 
    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlUsuario, setFotoUrlUsuario ] = useState(fotoDefault);
    const [ roles, setRoles ] = useState([])

    // const [ usuarioDNI, setUsuarioDNI ] = useState({})

    const {stateUser} = useContext(UserContext)
    
    const cargarDatosPersona = async (DNI) => {
        console.log("CARGANDO DATOS")
        setCargandoDNI(true);
    
        if (DNI?.length == 8) {
            const response = await AuthFetch({
                url: `${urlBasePlamin}/Person/dataByDNI?dni=${DNI}`
            });

    
            if (response.isValid) {
                notify('Se encontró información', 'info');
                // Clonar el objeto de usuario antes de modificarlo
                const nuevoUsuario = { ...usuario };
                nuevoUsuario.dni = response.content.dni;
                nuevoUsuario.numDocumento = response.content.dni;
                nuevoUsuario.nombre = response.content.nombres;
                nuevoUsuario.apellidoPaterno = response.content.apellidoPaterno;
                nuevoUsuario.apellidoMaterno = response.content.apellidoMaterno;
                nuevoUsuario.rol = [];
                nuevoUsuario.uidNuevo = (response.content.nombres[0] + response.content.apellidoPaterno + response.content.apellidoMaterno).replace(/\s+/g, '');
                console.log("nuevoUsuario -> ", nuevoUsuario)
                // nuevoUsuario.uidNuevo = (response.content.nombres[0] + (response.content.apellidoPaterno).Trim() + (response.content.apellidoMaterno).Trim()).Trim()


                setUsuario(nuevoUsuario);
            } else {
                notify('No se encontró información', 'info');
                setUsuario(usuarioDefault);
            }
        } else {
            notify('El DNI debe tener 8 caracteres', 'error');
            setUsuario(usuarioDefault);
        }
    
        setCargandoDNI(false);
    };
    

    useEffect(() => {
        listarUsuarios();
        listarRoles()
    }, []);


    const listarRoles = async () => {
        const response = await Fetch({
            url: 'https://api.plaminoficial.com/api/Rol',
        });

        if (response.isValid) {
            setRoles(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const listarUsuarios = async () => {
        const response = await AuthFetch({
            // url: urlBasePlamin + '/User?codEntidad=' + (stateUser.codUsuario == 1 ? 0 : stateUser.codEntidad),
            url: urlBasePlamin + `/Entities/${(stateUser.codUsuario == 1 ? 0 : stateUser.codEntidad)}/users`,
        });

        if (response.isValid) {
            setUsuarios(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const usuarioEliminar = async () =>{
        // if (codPersona) {
        //     const response = await AuthFetch({
        //         url: urlBasePlamin + '/Personas/' + codPersona
        //     });

        //     if (response.isValid) {
        //         setUsuario(response.content);
        //         verFotoUrlUsuario(response.content.nombreCarpetaFoto, response.content.nombreFoto)
        //     } else {
        //         notify(response.content, 'error');
        //     }
        // } else {
        //     setUsuario(usuarioDefault);
        //     setFotoUrlUsuario(fotoDefault)
        // }
        openModalEliminar();

    }

    const eliminarPersona = async (uid)=>{
        const response = await AuthFetch({
            url: urlBasePlamin + '/User',
            method:'DELETE',
            body: JSON.stringify({
                uid: uid
            })
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarUsuarios()
        }
        closeModalEliminar()
    }


    const asignarUsuario = async() =>{

        const responseAsignarRol = await AuthFetch({
            url: 'https://api.plaminoficial.com/api/User/assignRoleUser',
            method: 'POST',
            body: JSON.stringify({
                uid: usuario.uid,
                roles: usuario.rol.toString().split(',') || []
                // roles: (typeof usuario?.rol === 'string')? [usuario?.rol] : usuario?.rol,
            })
        })
        if(responseAsignarRol.isValid){
            notify(responseAsignarRol.content,'success' )
        }else{
            notify(responseAsignarRol.exceptions[0].description,'success' )
        }

        const responseCambioClave = await AuthFetch({
            url: 'https://api.plaminoficial.com/api/User/changePassword',
            method: 'PUT',
            body: JSON.stringify({
                uid: usuario.uid,
                claveNueva: usuario.clave
            })
        })
        if(responseCambioClave.isValid){
            notify(responseCambioClave.content,'success' )
        }else{
            notify(responseCambioClave.exceptions[0].description,'success' )
        }
    }


    const asignarEntidad = async(uidAsignar,codEntidadAsignar ) =>{

        const response = await AuthFetch({
            url: 'https://api.plaminoficial.com/api/Entities/uidAndEntity',
            method: 'POST',
            body: JSON.stringify({
                uid: uidAsignar,
                codEntidad: codEntidadAsignar,
            })
        })
        if(response.isValid){
            notify(response.content,'success' )
        }else{
            notify(response.exceptions[0].description,'success' )
        }
    }
    //GCamposGuerrero - 4924

    const obtenerUsuario = async (uid) => {
        if (uid) {
            const uidSpliteado = uid?.split(",")[0].split("=")[1] || uid
            const response = await AuthFetch({
                url: urlBasePlamin + '/User/' + uidSpliteado
            });

            if (response.isValid) {
                setUsuario(response.content);
                verFotoUrlUsuario(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setUsuario(usuarioDefault);
            setFotoUrlUsuario(fotoDefault)
        }
        openModal();
    }

    const guardarEditarUsuario = async () => {
        
            const esGuardar = usuario?.uid? false : true;
            
           
            if (usuario.dni == "" && usuario.nombre == "" && usuario.apellidoMaterno == "" && usuario.apellidoPaterno == "" && usuario.correoElectronico == "" && usuario.telefono == "" ){
                // notify("Debe completar los campos " , "error")
                return
            }  
        
            const response = await AuthFetch({
                url: 'https://api.plaminoficial.com/api/User',
                method: esGuardar ? 'POST' : 'PUT',
                body: JSON.stringify({
                    uid: usuario?.uid || usuario?.uidNuevo,
                    dni: usuario?.dni || usuario.numDocumento,
                    nombre:usuario?.nombre ,
                    apellidoPaterno: usuario.apellidoPaterno,
                    apellidoMaterno:usuario.apellidoMaterno ,
                    correoElectronico: usuario.correoElectronico,
                    telefono: usuario.telefono ,
                    fotoUrl: usuario.urlFoto || '' ,
                    rol: []
                })
            });
            if (response.isValid) {
                asignarEntidad(esGuardar? (usuario?.nombre.split(" ")[0][0] + usuario?.apellidoPaterno + usuario?.apellidoMaterno).replace(/\s+/g, '') : usuario.uid, usuario.codEntidad)
                // esGuardar && asignarEntidad(usuario?.nombre.split(" ")[0][0] + usuario?.apellidoPaterno + usuario?.apellidoMaterno, usuario.codEntidad)
                notify(response.content, 'success');
                setUsuario(usuarioDefault);
                closeModal()
                await listarUsuarios();
            }else{
                notify(response.exceptions[0].description,  'error');
            }
    }
//77093217


    const editarValorUsuario = (key, value) => {

        setUsuario(usuario => {
            return {
                ...usuario,
                [key]: value
            }
        }); 
    }

    const verFotoUrlUsuario = (nomCarpeta,nombreFoto) => {
        if (nombreFoto === ''){
            setFotoUrlUsuario(fotoDefault); 
        }else {
            setFotoUrlUsuario(url => urlBasePlamin + `/Minio?nombreCarpetaFoto=${nomCarpeta}&nombreFoto=${nombreFoto}`);
        }
    }

    return {
        usuarios,
        listarUsuarios,
        guardarEditarUsuario,
        usuario, 
        setUsuario,
        editarValorUsuario, 
        obtenerUsuario, 
        isOpenModal, 
        closeModal, 
        cargarDatosPersona,
        fotoUrlUsuario,
        setFotoFile,
        setFotoUrlUsuario,
        eliminarPersona, 
        isOpenModalEliminar, 
        closeModalEliminar, 
        usuarioEliminar,
        cargandoDNI,
        roles,
        asignarUsuario
    }
}