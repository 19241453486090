import React, { useEffect, useState, useRef, useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { ContenedorTabla } from '../../components/utils/ContenedorTabla';
import { useDocumentos } from '../../../hooks/personas/useDocumentos';
// import { usePersonas } from '../../../hooks/personas/usePersonas';
import fotoDefault from '../../../assets/images/usuarioDefault.jpg';
import { notify, soloCelular, soloDNI } from '../../../utils/utils';
import { Entidades } from './Entidades';
import { useEntidades } from '../../../hooks/useEntidades';
import { UserContext } from '../../../context/provider/UserProvider';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import Select2Plamin from '../../components/forms/Select2Plamin';
import { Tooltip } from '../../components/utils/Tooltip';
import { usePobladores } from '../../../hooks/personas/usePobladores';
import { DatePickerABX } from '../../components/pickers/DatePicker';


export const Pobladores = () => {
    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const pobladores = []
    const inputFoto = useRef();
    const { stateUser }  = useContext(UserContext)

    const { 
        personasEmpadronas,
        listarPersonasEmpadronadas,
        guardarEditarPersonaEmpadronada,
        personaEmpadronada, 
        editarValorPersonaEmpadronada, 
        obtenerPersonaEmpadronada, 
        isOpenModal, 
        closeModal, 
        cargarDatosPersonaEmpadronada,
        fotoUrlPersona,
        setFotoFile,
        setFotoUrlPersona,
        eliminarPersonaEmpadronada, 
        isOpenModalEliminar, 
        closeModalEliminar, 
        personaEmpadronadaEliminar,
        cargandoDatos 
    } = usePobladores();
    const { documentos } = useDocumentos();
    const { listarEntidades,entidades } = useEntidades()

    const [ selectedFile, setSelectedFile ] = useState()
    const [ startDate, setStartDate ] = useState(new Date())
    const [ registrarEditar,setRegistrarEditar ] = useState(true)
    
    useEffect(()=>{
        editarValorPersonaEmpadronada("fechaNacimiento", startDate)
    },[startDate])

    useEffect(()=> {
        listarEntidades()
    },[])

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlPersona(fotoDefault)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlPersona(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }

    const funcionSubirImagenMinio = async () => {
        // const nombreArchivo = generarNombreUnico('registroPersona')
        const nombreArchivo = `${personaEmpadronada.nomPersonaEmpadronada.toLowerCase().replace(/\s/g, '')}_${personaEmpadronada.nroDocumento}`
        const promise = await fetch(personaEmpadronada.urlFoto)
        const blob = await promise.blob()
        const file = new File([blob],nombreArchivo,{type: blob.type})

        const urlSubirArchivo = `https://miniowebapi.abexacloud.com/api/Archivo/subirArchivo?NombreCarpeta=tgps&NombreArchivo=%2Ffotos-personas%2F${nombreArchivo}`
		const urlVer =`https://miniowebapi.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=tgps&NombreImagen=fotos-personas%2F${nombreArchivo}`
		const formData = new FormData();
		formData.append("archivo", file)
		const response = await fetch(urlSubirArchivo,{
			method:'POST',
			body: formData
		}).then(res => res.json())
		if(response.isValid){
            notify('Archivo subido correctamente','success')
            editarValorPersonaEmpadronada('urlFoto',urlVer)
		}else{
			notify('Error al enviar el archivo, inténtelo de nuevo','error')
		}
    }

    return (
        <>
            <ContenedorParametros
                titulo="Pobladores"
                tablaId={'tabla-personas'}
            >
                <div>
                    <BotonProcesar onClick={() => listarPersonasEmpadronadas()} ></BotonProcesar>
                </div>

                <div>
                    <BotonNuevo onClick={() => {obtenerPersonaEmpadronada();setRegistrarEditar(true)}}></BotonNuevo>
                </div>
            </ContenedorParametros>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-personas' className="table">
                    <thead>
                        <tr>
                            <th className="w-[30px]">N</th>
                            <th className="text-left">NOMBRE</th>
                            <th>NRO.DOCUMENTO</th>
                            <th>GENERO</th>
                            <th>RELIGION</th>
                            <th>F.CREACIÓN</th>
                            <th className="w-[10px]"></th>
                            <th className="w-[10px]"></th>

                        </tr>
                    </thead>
                    <tbody>
                    {   
                            personasEmpadronas.length > 0 
                            ? personasEmpadronas.map((persona, i) => {
                                return (
                                    <tr key={persona.codPersona}>
                                        <td>{ i + 1}</td>
                                        <td className="text-left">{persona.nomPersonaEmpadronada} {persona.apePatPersonaEmpadronada} {persona.apeMatPersonaEmpadronada}</td>
                                        <td className="text-center">{persona.nroDocumento}</td>
                                        <td className="text-center">{persona.telefonoPersona}</td>
                                        <td className="text-center">{persona.nomGenero}</td>
                                        <td className="text-center">{persona.nomReligion}</td>
                                        <td className="text-center w-[10px]" >
                                            <button onClick={() => {obtenerPersonaEmpadronada(persona.codPersona);setRegistrarEditar(false)}} className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                                <i className="fas fa-edit"></i>
                                            </button>
                                        </td>    
                                        <td className="text-center w-[10px]" >
                                            <button  onClick={()=> personaEmpadronadaEliminar(persona.codPersona)} className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                        }
                    </tbody>
                </table>
            </div>


            <Modal 
                isOpen={isOpenModal} closeModal={closeModal}
                action={() => {
                    if(personaEmpadronada.urlFoto){     // REGISTRO DE LA FOTO EN EL MINIO PARA OBTENER EL LINK
                        funcionSubirImagenMinio()
                    }
                    guardarEditarPersonaEmpadronada()
                }}
                title={`${registrarEditar ? 'Registrar poblador' : 'Editar poblador'}`}
            >
                <div className="flex  items-center flex-wrap justify-center containerScroll lg:overflow-y-auto">
                    <div className="w-[500px] lg:w-auto px-4 containerScroll">
                        <div className="flex flex-col justify-between w-full form-content">                        
                            <div className='lg:flex-col lg:w-full'>
                                <label>Tipo Documento: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codDocumentoTipo} onChange={(e) => {editarValorPersonaEmpadronada("codDocumentoTipo", e.target.value)}} >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            documentos.map(d => {
                                                return <option key={d.codDocumentoTipo} value={d.codDocumentoTipo}>{d.abrevDocumentoTipo}</option>
                                            })
                                        }
                                        <option value={2}>Sin Documento</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Número de Documento: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Número documento)'
                                        autoFocus={true}
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={personaEmpadronada.nroDocumento}
                                        onChange={(e) => {
                                            editarValorPersonaEmpadronada("nroDocumento", e.target.value);
                                        }}
                                        onKeyPress={(e) => soloDNI(e, e.target)} 
                                    />
                                    <i 
                                        data-for='Busqueda' 
                                        data-tip='Buscar persona' 
                                        onClick={() => cargarDatosPersonaEmpadronada(personaEmpadronada.nroDocumento)} 
                                        className={`hover:scale-105 ${cargandoDatos? ' fa fa-spinner animate-spin ' : ' fa fa-search ' }  cursor-pointer  p-1 rounded-md`}
                                        aria-hidden="true"
                                    ></i>
                                    <Tooltip id={'Busqueda'}></Tooltip>
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Nombre: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input  
                                        placeholder='(Nombre persona)'
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={personaEmpadronada.nomPersonaEmpadronada} 
                                        onChange={(e) => editarValorPersonaEmpadronada("nomPersonaEmpadronada", e.target.value)} 
                                    />
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Apellido Paterno: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input
                                        placeholder='(Apellido paterno)'
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={personaEmpadronada.apePatPersonaEmpadronada} 
                                        onChange={(e) => editarValorPersonaEmpadronada("apePatPersonaEmpadronada", e.target.value)} 
                                    />
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Apellido Materno: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Apellido materno)'
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={personaEmpadronada.apeMatPersonaEmpadronada}
                                        onChange={(e) => editarValorPersonaEmpadronada("apeMatPersonaEmpadronada", e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className='lg:flex-col lg:w-full'>
                                <label>Teléfono: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Teléfono)'
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={personaEmpadronada.telefonoPersona}
                                        onChange={(e) => editarValorPersonaEmpadronada("telefonoPersona", e.target.value)} 
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex-col lg:w-full'>
                                <label>Dirección: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Dirección)'
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={personaEmpadronada.direccion}
                                        onChange={(e) => editarValorPersonaEmpadronada("direccion", e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className='lg:flex-col lg:w-full'>
                                <label>Fecha Nacimiento: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <DatePickerABX data-for={'FechaInicio'} date={startDate} setDate={setStartDate}/>
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Genero: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codGenero} onChange={(e) => {editarValorPersonaEmpadronada("codGenero", e.target.value)}} >
                                    <option value={0}> -- Seleccione --</option>
                                        <option value={1}>Masculino</option>
                                        <option value={2}>Femenino</option>
                                    </select>
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Estado Civil: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codEstadoCivil} onChange={(e) => {editarValorPersonaEmpadronada("codEstadoCivil", e.target.value)}} >
                                        <option value={0}>-- Seleccione -- </option>
                                        <option value={0}>Soltero</option>
                                        <option value={1}>Casado</option>
                                        <option value={1}>Viudo</option>
                                        <option value={1}>Divorciado</option>
                                    </select>
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Nivel Educativo: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codNivelEducativo} onChange={(e) => {editarValorPersonaEmpadronada("codNivelEducativo", e.target.value)}} >
                                        <option value={0}>Primaria</option>
                                        <option value={1}>Secundaria</option>
                                        <option value={2}>Superior Técnico</option>
                                        <option value={3}>Superior Universitario</option>
                                    </select>
                                </div>
                            </div>
                            <div className='lg:flex-col lg:w-full'>
                                <label>Idioma Natal: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codIdiomaNatal} onChange={(e) => {editarValorPersonaEmpadronada("codIdiomaNatal", e.target.value)}} >
                                        <option value={0}>Aymara</option>
                                        <option value={1}>Quechua</option>
                                        <option value={2}>Castellano</option>
                                        <option value={3}>Otro</option>
                                    </select>
                                </div>
                            </div>


                            <div className='lg:flex-col lg:w-full'>
                                <label>Religión: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={personaEmpadronada.codReligion} onChange={(e) => {editarValorPersonaEmpadronada("codReligion", e.target.value)}} >
                                        <option value={0}>Catolica</option>
                                    </select>
                                </div>
                            </div>

                            <div className={`lg:flex-col lg:w-full hidden `}>
                                <label>Entidad: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <Select2Plamin
                                        options={
                                            entidades.map(option => (
                                                {
                                                    value: option.codEntidad,
                                                    label: option.nomEntidad
                                                }
                                            ))
                                        }
                                        funcionChange={(valor) => {
                                            editarValorPersonaEmpadronada("codEntidad",valor)
                                        }}
                                        valorDefault={stateUser.codEntidad}
                                    ></Select2Plamin>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="text-center">
                        <img className='rounded-xl w-[250px] h-[250px] mb-1 lg:w-[150px] lg:h-[150px]' src={personaEmpadronada.urlFoto || fotoDefault} alt='' />
                        
                        <input 
                            type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png' 
                            ref={inputFoto} 
                            onChange={(e) => {
                                const file = e.target.files[0]
                                const url = URL.createObjectURL(file)
                                editarValorPersonaEmpadronada('urlFoto',url)
                            }} 
                        />

                        <label htmlFor="file-1">
                            <i className="fa fa-image iborrainputfile text-[#FFFFFF] text-lg"></i> 
                            <span className="iborrainputfile"> Seleccione</span>
                        </label>
                        {/* <div className='text-red-600 text-xs'>*Necesario</div> */}
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersonaEmpadronada(personaEmpadronada.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>   


        </>
    )
}