import React, { useState,useEffect } from 'react'
import Select from 'react-select'

const Select2Plamin = (props) => {

    const [valorSelect,setValorSelect] = useState(props.options.find(elem => elem.value == props.valorDefault))

    const customStyles = {
        input: (provided) => ({
            ...provided,
            cursor:'text',
            color:'white',
            fontSize:'13px',
        }),
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            width:'250px',
            border:'1px solid rgba(39,39,42,255)',
            backgroundColor: 'rgba(39,39,42,255)',
        }),
        placeholder:(provided)=>({
            ...provided,
            color:'#9ca3af',
            fontSize:'12px'
        }),
        singleValue: (provided) => ({
            ...provided,
            lineHeight: '30px', // Ajusta el valor según el alto deseado
            fontSize:'12px',
            color: 'white',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            marginTop:'-1px',
            display: 'none',
            height:'30px'
        }),
        option: (provided,state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgba(30,144,255,255)' : 'rgba(39,39,42,255)',
            color: 'white',
            fontSize:'12px',
            '&:hover': {
                backgroundColor: 'rgba(30,144,255,255)',
            },
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 1000000,
        }),
        menu: (provided) => ({
            // ...provided,
            backgroundColor:'rgba(39,39,42,255)',
            position:'absolute',
            zIndex: 100000,
            width:'100%',
        })
    };

    return (
        <Select
            name="form-dept-select"
            options={props.options || []}
            styles={customStyles}
            className='containerScroll' 
            placeholder="-- Seleccione --"
            value={valorSelect || ''}
            onChange={(e) => {
                setValorSelect(e)
                props.funcionChange(e.value)
            }}
        ></Select>
    )
}

export default Select2Plamin