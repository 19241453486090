import React, { useState, useEffect, useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { useModal } from '../../../hooks/useModal';
import { UserContext } from '../../../context/provider/UserProvider';
import Select2Plamin from '../../components/forms/Select2Plamin';
import { useTiposAlertas } from '../../../hooks/useTiposAlertas';


export const TiposAlertas = () => {

    const {
        tiposAlertas,
        editarValorTipoAlerta,
        listarTipoAlertasPorCategorias,
        listarTipoAlertas,        
    } = useTiposAlertas()


    const [ isOpen, openModal, closeModal ] = useModal()
    const {stateUser} = useContext(UserContext)

    const [isOpenEliminar, openModalEliminar, closeModalEliminar] = useModal()


    const [registrarEditar,setRegistrarEditar] = useState(true)

    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
    return (
        <>
            <ContenedorParametros
                titulo="Tipos de Alertas"
                tablaId='tabla-usuarios'
            >


                <div className={``}>
                    <i className=" fa fa-filter" data-tip={'Categoria '} data-for={'categoria'} aria-hidden="true"/>
                    <select 
                        className={`${classNameInput} lg:w-[100px] w-[120px] ml-2`} 
                    >
                        <option value={1}>SOS</option>
                        <option value={2}>Denuncias</option>
                    </select>
                </div>

                <div>
                    <BotonProcesar onClick={() => listarTipoAlertasPorCategorias(0)} ></BotonProcesar>
                </div>
                <div>
                    <BotonNuevo onClick={() => openModal()}></BotonNuevo>
                </div>
            </ContenedorParametros>
                
            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-usuarios' className="table">
                    <thead>
                        <tr>
                            <th className="w-[40px] text-center">N</th>
                            <th className='text-center'>CATEGORIA</th>
                            <th className='text-center'>NOMBRE</th>
                            <th className='w-2'></th>
                            <th className='w-2'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {  
                            tiposAlertas.length > 0
                            ? tiposAlertas.map((ta,i)=> {
                                return (
                                <tr key={ta.codAlertaTipo} >
                                    <td className='text-center'>{ i + 1 }</td>
                                    <td className="text-center">{ta.nomCategoria}</td>
                                    <td className="text-center">{ta.nomAlertaTipo}</td>
                                    <td className="text-center">
                                        <button  
                                            // onClick={() => {obtenerUsuario(ta.codUsuario)}} 
                                            onClick={() => {openModal()}} 
                                            className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                        >
                                            <i className="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button  

                                            // onClick={() => {editarValorUsuario("codUsuario", usuario.codUsuario); openModalEliminar()}}
                                            onClick={() => { openModalEliminar()}}
                                            className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3"
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                )
                            })
                            : <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                        }
                        
                    </tbody>
                </table>
            </div>

            <Modal 
                isOpen={isOpen} closeModal={closeModal}
                title={`${registrarEditar ? 'Registrar' : 'Editar'}`}
                spinner={{}}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] lg:w-full px-4 lg:px-0">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div className='lg:flex lg:flex-col'>
                                <label>Categoria:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                <select 
                                    className={`${classNameInput} w-[250px] `} 
                                >
                                    <option value={1}>SOS</option>
                                    <option value={2}>Denuncias</option>
                                </select>
                                </div>
                            </div>
                        
                            <div className='lg:flex lg:flex-col'>
                                <label>Nombre:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        autoFocus
                                        placeholder='' 
                                        className={`${classNameInput} caret-input`} 
                                        autoComplete='false' 
                                        // defaultValue={tipoAlerta.nombre} 
                                        onChange={(e) => editarValorTipoAlerta("nombre", e.target.value)} 
                                    />
                                </div>
                            </div>

                            <div className={`${registrarEditar ? 'lg:flex lg:flex-col' : '!hidden'}`}>
                                <label>Descripción:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='' 
                                        className={`${classNameInput} caret-input`} 
                                        autoComplete='false' 
                                        defaultValue={''} 
                                        onChange={(e) => editarValorTipoAlerta("descripcion",e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenEliminar} closeModal={closeModalEliminar}
                title="¿Seguro que desea Eliminar el tipo de alerta?"
                spinner={{}}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex  flex-col justify-center text-center w-full form-content ">
                                <div className='flex text-center  items-center  justify-center'>
                                    <label className='w-full'>Motivo de la elimación:</label>
                                </div>
                                <div className="flex items-center gap-4  justify-center">
                                    <input 
                                        autoFocus
                                        className={`${classNameInput} caret-input`} 
                                        // defaultValue={tipoAlerta.motivoEliminacion} 
                                        onChange={(e) => editarValorTipoAlerta("motivoEliminacion", e.target.value)}/>
                                </div>
                        </div>
                    </div>
                </div>
            </Modal>
            
        </>
    )
}