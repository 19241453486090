import React, { useState, useContext, useEffect } from 'react';
import { Modal } from '../app/components/modal/Modal';
import { useModal } from './useModal';
import { notify } from '../utils/utils';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const urlBaseEntidades = process.env.REACT_APP_PLAMIN_API + '/api/Entities'

export const useEntidades = () => {

    const atributosCPDefault = {
        codEntidad: 0,
        codEntidadDetalle: 0,
        localidadColindanteNorte: "",
        localidadColindanteSur: "",
        localidadColindanteEste: "",
        localidadColindanteOeste: "",
        tieneLocalComunal: false,
        codEntidadDispercion: 0,
        localEscolarInicial: "",
        localEscolarPrimaria: "",
        localEscolarSecundaria: "",
        postaSalud: "",
        puestoSalud: "",
        centroSalud: "",
        codEntidadTransporte: 0,
        cantidadCalleViaUrbana: 0,
        cantidadCaminoViaVecinal: 0,
        cantidadTrochaCarrozable: 0,
        cantidadRutaCaminoHerradura: 0,
        cantidadPlaza: 0,
        cantidadMercado: 0,
        cantidadEstadio: 0,
        cantidadJuzgadoPaz: 0,
        cantidadCementerio: 0,
        cantidadLocalComunal: 0,
        cantidadAtractivoTuristico: 0,
        cantidadColiseo: 0,
        cantidadParque: 0,
        planUrbano: "",
        planoCatastral: "",
        mapaReferencial: "",
        codEntidadAguaPotable: 0,
        cantidadMinutoPorDiaAguaPotable: 0,
        codEntidadDesague: 0,
        localidadesConDesague: "",
        codEntidadConectividad: 0,
        codEntidadElectricidad: 0,
        codEntidadCategoria: 0,
        cantidadComisaria:0


    }

    const entidadDefault = {
        nomEntidad: '',
        codEntidad: 0,
        codEntidadTipo: 0,
        codInstitucion: 0,
        codEntidadPadre: 0,
        nomEntidadPadre: '',
        telefonoFijoUno: '',
        telefonoFijoDos: '',
        telefonoFijoTres: '',
        numeroCelular: '',
        codUsuarioAccion: '',
        uid: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        poligonoJurisdiccion: '',
        parametrosTipoAlertaDetalle: '',
        dispositivoLegalCreacion: '',
        dispositivoLegalAdecuacion: '',
        dispositivoLegal: '',
        correoElectronicoUno: '',
        mcp: '',
        responsable: '',
        ddn: '',
        codDepartamento: 0,
        codProvincia: 0,
        codDistrito: 0,
        cantidadPobladorRegistroINEI: 0,
        cantidadPobladorRegistroManual: 0
    }

    const { stateUser}= useContext(UserContext);
    const [ entidades, setEntidades ] = useState([]);
    const [ instituciones, setInstituciones ] = useState([]);
    const [ tiposEntidades, setTiposEntidades ] = useState([])
    const [ tipoAlertasPorEntidad, setTipoAlertasPorEntidad ] = useState([])
    const [ entidadesBuscador, setEntidadesBuscador ] = useState([]);
    const [ entidad, setEntidad ] = useState(entidadDefault);
    const [ isOpen, openModal, closeModal] = useModal(false);
    const [ atributosCP, setAtributosCP ] = useState(atributosCPDefault);

    useEffect(()=> {
        (async ()=> {
            // await listarEntidades();
            await listarTipoEntidades();
            await listarInstituciones()
            await listarTipoAlertasPorEntidad()
        })()
    },[]);

    const listarTipoEntidades = async () => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/types'
        });

        if(response.isValid){
            setTiposEntidades(response.content);
            setEntidadesBuscador(response.content)
        }else{
            notify(response.content, 'error');
        }
    }

    const listarEntidades = async () => {
        const response = await AuthFetch({
            url:urlBaseEntidades + '/grouped?' + new URLSearchParams({
                codEntidad: stateUser.codEntidad || 2023
            })
        });

        if(response.isValid){
            setEntidades(response.content);
        }else{
            notify(response.content, 'error');
        }
    }

    const listarAtributosCP = async (codEntidadValidar) => {

        const response = await AuthFetch({
            url:urlBaseEntidades + '/populatedCenter?' + new URLSearchParams({
                CodEntidadValidar: codEntidadValidar
            })
        });

        if(response.isValid){
            setAtributosCP(response.content[0]);

        }else{
            notify(response.content, 'error');
        }
    }

    const listarEntidadesPersonalizado = async (codEntidad) => {
        const response = await AuthFetch({
            url:urlBaseEntidades + '/grouped?' + new URLSearchParams({
                codEntidad: codEntidad || 2023
            })
        });


        if(response.isValid){
            setEntidades(response.content);

        }else{
            notify(response.content, 'error');
        }
    }

    const obtenerAtributosCP = async (codEntidad) => {
        const response = await AuthFetch({
            url:urlBaseEntidades + '/detail?' + new URLSearchParams({
                codEntidad: codEntidad
            })
        });

        if(response.isValid){
            setAtributosCP(response.content || {...atributosCPDefault,codEntidad: codEntidad});
        }else{
            notify(response.content, 'error');
        }
    }

    const obtenerEntidad = async (codEntidad) => {
        if (codEntidad){
            const response = await AuthFetch({
                url:urlBaseEntidades + '/codEntidad?' + new URLSearchParams({
                    codEntidad: codEntidad || 0
                })
            });
            if(response.isValid){
                setEntidad({...response.content,responsable:response.content.nomResponsable})
                editarvalorEntidad('codEntidad',codEntidad)
            }else{
                notify(response.content, 'error')
            }
        }else{
            setEntidad(entidadDefault);
        }
        openModal();
    }

    const eliminarEntidad = async (codEntidad,funcionCerrarModal) => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/?' + new URLSearchParams({
                codEntidad: codEntidad || 0
            }),
            method: 'PUT'
        })

        notify(response.content,response.isValid ? 'success' : 'error')
        
        if (response.isValid){
            await listarEntidades()
            funcionCerrarModal()
        }
    }

    //****************************** */
    const guardareditarEntidad = async () => {




        const esGuardar = entidad.codEntidad <=0;
        const response = await AuthFetch({
            url: urlBaseEntidades + '',
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(esGuardar?
                        {
                            nomEntidad:entidad.nomEntidad.toString(),
                            codEntidadTipo:parseInt(entidad.codEntidadTipo),
                        }
                    :
                        {
                            codEntidad:entidad.codEntidad,
                            //dispositivoLegal: entidad.dispositivoLegal
                        }
                    ),
                codInstitucion:parseInt(entidad.codInstitucion),
                codEntidadPadre:parseInt(entidad.codEntidadPadre),

                parametrosTipoAlertaDetalle:entidad.parametrosTipoAlertaDetalle.toString(),
                dispositivoLegalCreacion: entidad.dispositivoLegalCreacion.toString(),

                codUsuarioAccion:parseInt(stateUser.codUsuario),
                telefonoFijoUno:entidad.telefonoFijoUno.toString(),
                telefonoFijoDos:entidad.telefonoFijoDos.toString(),
                telefonoFijoTres:entidad.telefonoFijoTres.toString(),
                numeroCelular:entidad.numeroCelular.toString(),
                direccion:entidad.direccion.toString(),
                correoElectronicoUno:entidad.correoElectronicoUno.toString(),
                mcp: entidad.mcp.toString(),
                poligonoJurisdiccion:entidad.poligonoJurisdiccion.toString(),
                latitud:entidad.latitud,
                longitud:entidad.longitud,
                responsable: entidad.responsable.toString(),
                dispositivoLegalAdecuacion: entidad.dispositivoLegalAdecuacion.toString(),
                ddn: entidad.ddn.toString(),
                cantidadPobladorRegistroManual: parseInt(entidad.cantidadPobladorRegistroManual),
                cantidadPobladorRegistroINEI: parseInt(entidad.cantidadPobladorRegistroINEI),
                codDistrito: entidad.codDistrito || 0,
                codProvincia: entidad.codProvincia || 0,
                codDepartamento: entidad.codDepartamento || 0
            })
        }); 

        notify(response.content,response.isValid ? 'success' : 'error')
        
        if (response.isValid){
            setEntidad(entidadDefault);
            await listarEntidades();
            closeModal();
        }
    }

    const guardarEditarAtributosCP = async () => {

        if(atributosCP.codEntidadCategoria == 0 || atributosCP.codEntidadDispercion == 0 || atributosCP.codEntidadTransporte == 0 || atributosCP.codEntidadAguaPotable == 0 || atributosCP.codEntidadAguaPotable == 0 ||atributosCP.codEntidadAguaPotable == 0){
            notify("Debe seleccionar todos los datos","error")
            return;
        }

        const esGuardar = atributosCP.codEntidadDetalle == 0? true : false;

        const response = await AuthFetch({
            url: urlBaseEntidades + '/detail',
            method: esGuardar? 'POST' : 'PUT',


            body: JSON.stringify({
                ...(esGuardar?
                        {
                            codEntidad: atributosCP.codEntidad,
                        }
                    :
                        {
                            codEntidadDetalle:atributosCP.codEntidadDetalle,
                        }
                    ),
                localidadColindanteNorte:atributosCP.localidadColindanteNorte  ,
                localidadColindanteSur:atributosCP.localidadColindanteSur  ,
                localidadColindanteEste:atributosCP.localidadColindanteEste ,
                localidadColindanteOeste:atributosCP.localidadColindanteOeste  ,
                tieneLocalComunal:atributosCP.tieneLocalComunal  ,
                codEntidadDispercion:atributosCP.codEntidadDispercion  ,
                localEscolarInicial:atributosCP.localEscolarInicial  ,
                localEscolarPrimaria:atributosCP.localEscolarPrimaria  ,
                localEscolarSecundaria:atributosCP.localEscolarSecundaria  ,
                postaSalud:atributosCP.postaSalud  ,
                puestoSalud:atributosCP.puestoSalud  ,
                centroSalud:atributosCP.centroSalud  ,
                codEntidadTransporte:atributosCP.codEntidadTransporte  ,
                cantidadCalleViaUrbana:atributosCP.cantidadCalleViaUrbana  ,
                cantidadCaminoViaVecinal:atributosCP.cantidadCaminoViaVecinal  ,
                cantidadTrochaCarrozable:atributosCP.cantidadTrochaCarrozable,
                cantidadRutaCaminoHerradura:atributosCP.cantidadRutaCaminoHerradura,
                cantidadPlaza:atributosCP.cantidadPlaza,
                cantidadMercado:atributosCP.cantidadMercado,
                cantidadEstadio:atributosCP.cantidadEstadio,
                cantidadJuzgadoPaz:atributosCP.cantidadJuzgadoPaz,
                cantidadCementerio:atributosCP.cantidadCementerio,
                cantidadLocalComunal:atributosCP.cantidadLocalComunal,
                cantidadAtractivoTuristico:atributosCP.cantidadAtractivoTuristico,
                cantidadColiseo:atributosCP.cantidadColiseo,
                cantidadParque:atributosCP.cantidadParque,
                planUrbano:atributosCP.planUrbano,
                planoCatastral:atributosCP.planoCatastral,
                mapaReferencial:atributosCP.mapaReferencial,
                codEntidadAguaPotable:atributosCP.codEntidadAguaPotable,
                cantidadMinutoPorDiaAguaPotable:atributosCP.cantidadMinutoPorDiaAguaPotable,
                cantidadComisaria:atributosCP.cantidadComisaria, 
                codEntidadDesague:atributosCP.codEntidadDesague,
                localidadesConDesague:atributosCP.localidadesConDesague,
                codEntidadConectividad:atributosCP.codEntidadConectividad,
                codEntidadElectricidad:atributosCP.codEntidadElectricidad,
                codEntidadCategoria: atributosCP.codEntidadCategoria,
                
                

            })
        });

        
        if (response.isValid){
            notify(response.content,'success')
            // setEntidad(entidadDefault);
            // await listarEntidades();
            // closeModal();
        }else{
            notify(response.exceptions[0].description,'error')
            // notify(response.content,'error')

        }
    }
    //****************************** */

    const listarInstituciones = async () => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/institutions'
        });

        if(response.isValid){
            setInstituciones(response.content);
        }else{
            notify(response.content, 'error');
        }
    }

    const listarTipoAlertasPorEntidad = async (codEntidad) => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/typeAlerts?' + new URLSearchParams({
                codEntidad: codEntidad || 0
            })
        });

        if(response.isValid){
            setTipoAlertasPorEntidad(response.content);
        }else{
            notify(response.content, 'error');
        }
    }

    const editarvalorEntidad = (key, value) => {

        setEntidad(entidad => {
            return {
                ...entidad,
                [key]:value
            }
        });
    }
    
    const editarValorAtributosCP = (key, value) => {

        setAtributosCP(atributosCP => {
            return {
                ...atributosCP,
                [key]:value
            }
        });
    }

    return {
        entidades,
        listarEntidades,
        guardareditarEntidad,
        entidad,
        setEntidad,
        entidadDefault,
        editarvalorEntidad,
        obtenerEntidad,
        isOpen,
        openModal,
        closeModal,
        setEntidades,
        entidadesBuscador,
        tiposEntidades,
        listarTipoEntidades,

        instituciones,
        tipoAlertasPorEntidad,
        listarEntidadesPersonalizado,
        eliminarEntidad,

        atributosCP,
        setAtributosCP,
        atributosCPDefault,
        editarValorAtributosCP,
        listarAtributosCP,
        guardarEditarAtributosCP,
        obtenerAtributosCP

    };
}
