import {React, useState,useEffect} from 'react';
import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';
import { Modal } from '../modal/Modal';
import { useModal } from '../../../hooks/useModal';
import { notify } from '../../../utils/utils';


export const CambiarClave = (props) => {
    const {cambiarClaveUsuario,isOpen, openModal, closeModal} = useUsuarios()
    const [ojos,setOjos] = useState([{ojo1:false,ojo2:false,ojo3:false}])
    const [claves,setClaves] = useState([])

    useEffect(() => {
        props.abrirModal && openModal()
    },[props.abrirModal])

    const editarValorClaves = (key,valor) => {
        setClaves(elem => {
            return {
                ...elem,
                [key]:valor
            }
        })
    }

    const editarValorOjos = (key,valor) => {
        setOjos(elem => {
            return {
                ...elem,
                [key]:valor
            }
        })
    }

    const hashearClave = async (clave) => {
        const msgUint8 = new TextEncoder().encode(clave);                      
        const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);          
        const hashArray = Array.from(new Uint8Array(hashBuffer));                 
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		
        return hashHex;
    }

    const cambiarClave = async () => {
        if((claves.claveActual != '') && (claves.nuevaClave1 != '') && (claves.nuevaClave2 != '')){
            if(claves.nuevaClave1 == claves.nuevaClave2){
                let claveAnteriorHash = await hashearClave(claves.claveActual)
                let claveNuevaHash = await hashearClave(claves.nuevaClave1)

                await cambiarClaveUsuario(claveAnteriorHash,claveNuevaHash)
            }else{
                notify('Las claves nuevas deben coincidir','error')
            }
        }else{
            notify('Llenar todos los campos','error')
        }
    }

    return (
        <Modal
            isOpen={isOpen} closeModal={closeModal} action={() => cambiarClave()}
            title={'Cambiar Clave'} 
        >
            <div className="flex gap-8 items-center flex-wrap justify-center">
                <div className="w-[300px] px-4">
                    <div>
                        <div>
                            <label>Clave actual:</label><br/>
                            <div className='flex items-center'>
                                <input className='text-black w-full rounded-md p-1 mt-1' type={ojos.ojo1 ? '' : 'password'} onChange={(elem) => editarValorClaves('claveActual',elem.target.value)}/>
                                <i onClick={() => editarValorOjos('ojo1',!ojos.ojo1)} className={`fa ${ojos.ojo1 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>Nueva clave:</label><br/>
                            <div className='flex items-center'>
                                <input className='text-black w-full rounded-md p-1 mt-1' type={ojos.ojo2 ? '' : 'password'} onChange={(elem) => editarValorClaves('nuevaClave1',elem.target.value)}/>
                                <i onClick={() => editarValorOjos('ojo2',!ojos.ojo2)} className={`fa ${ojos.ojo2 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>Confirmar nueva clave:</label><br/>
                            <div className='flex items-center'>
                                <input className='text-black w-full rounded-md p-1 mt-1' type={ojos.ojo3 ? '' : 'password'} onChange={(elem) => editarValorClaves('nuevaClave2',elem.target.value)}/>
                                <i onClick={() => editarValorOjos('ojo3',!ojos.ojo3)} className={`fa ${ojos.ojo3 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}