import { useState, useEffect } from 'react';
import { AuthFetch } from '../../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useTipoUsuario = () => {
    const [ tipoUsuarios, setTipoUsuario ] = useState([]);

    useEffect(() => {
        listarTipoUsuarios();
    }, []);

    const listarTipoUsuarios = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Usuario/Tipos'
        });
        if (response.isValid) {
            setTipoUsuario(response.content);
        } else {
            alert(response.content);
        }
    }
    return [ tipoUsuarios ];
}