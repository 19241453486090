import { useState, useEffect, useContext } from "react";
import { notify } from "../../utils/utils";
import { AuthFetch } from "../../services/api";
import { useModal } from '../.././hooks/useModal';
// import { useModal } from "../useModal";
import { useSpinner } from "../useSpinner";
import { UserContext } from "../../context/provider/UserProvider";
// import { notEqual } from "assert";


const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useUsuarios = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const usuarioDefault = {
        codUsuario:0,
        nomUsuario:'',
        claveUsuario:'',
        codPersona:0,
        imagenUsuario:'',
        codUsuarioTipo:0,
        codUsuarioAccion:stateUser.codUsuario,
        motivoEliminacion: '',
        codComisaria:0,
    }
    const [ usuarios, setUsuarios ] = useState([]);
    const [ usuario, setUsuario ] = useState(usuarioDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpen, openModal, closeModal] = useModal();

    const [agentesCercanos,setAgentesCercanos] = useState([])
    const [agentesTotales,setAgentesTotales] = useState([])
    
    useEffect(() => {
        // listarUsuarios();
        // listarAgentesTotales();
    }, []);

    const editarValorUsuario = (key,value) =>{
        setUsuario(usuario => {
            return {
                ...usuario,
                [key]: value
            }
        });
    }

    const listarUsuarios = async () => {
        mostrarSpinner();
        const response = await AuthFetch({
            url: urlBasePlamin + '/User?codEntidad=' + (stateUser.codUsuario == 1 ? 0 : stateUser.codEntidad),
        });
        if (response.isValid){
            setUsuarios(response.content);
        }else{
            notify(response.content)
        }
        ocultarSpinner();
    }

    const obtenerUsuario = async (codUsuario) => {
        if (codUsuario){
            const response = await AuthFetch({
                url: urlBasePlamin + '/Usuario/' + codUsuario
            });            
            if(response.isValid){
                setUsuario(response.content)
            }else{
                alert(response.content);
            }
        }else{
            setUsuario(usuarioDefault)
        }
        openModal();
    }
    const eliminarUsuario = async () =>{
        const response = await AuthFetch({
            url : urlBasePlamin + '/usuario/eliminarUsuario',
            method : 'PUT',
            body : JSON.stringify({
                    codUsuario: usuario.codUsuario,
                    codUsuarioAccion: stateUser.codUsuario,
                    motivoEliminacion: usuario.motivoEliminacion
            })
        })
        notify(response.content, response.isValid? 'success' : 'error');
        if (response.isValid){
            await listarUsuarios();
            closeModal()
        }
    }

    const cambiarClaveUsuario = async (claveAnterior,claveNueva) => {
        const response = await AuthFetch({
            url : urlBasePlamin + '/usuario/CambiarClave',
            method : 'PUT',
            body : JSON.stringify({
                codUsuarioAccion: stateUser.codUsuario,
                codUsuario: stateUser.codUsuario,
                claveAnterior: claveAnterior,
                claveNueva: claveNueva
            })
        })
        notify(response.content, response.isValid? 'success' : 'error');
        if (response.isValid){
            closeModal()
        }
    }
    
    const guardarEditarUsuario = async () => {
        if (usuario.codPersona === 0 || usuario.codUsuarioTipo === 0 || usuario.nomUsuario === ''  ||  usuario.claveUsuario === ''){
            notify('Debe ingresar los parametros correctamente','error')
            return;
        } 
        
        const esGuardar = usuario.codUsuario <= 0;
        const response = await AuthFetch({
            url: urlBasePlamin + '/Usuario',
            method: esGuardar ? 'POST' : 'PUT', 
            body: JSON.stringify({
                ...(!esGuardar && { codUsuario: usuario.codUsuario }),
                nomUsuario: usuario.nomUsuario,
                claveUsuario: usuario.claveUsuario,
                codPersona: usuario.codPersona,
                imagenUsuario:usuario.imagenUsuario,
                codUsuarioTipo: usuario.codUsuarioTipo,
                codUsuarioAccion: usuario.codUsuarioAccion,//stateUSer.codUsuario,
                codComisaria: usuario.codComisaria
            })
        });
        notify(response.content,(response.isValid ? 'success' : 'error'));
        if (response.isValid){
            setUsuario(usuarioDefault);
            await listarUsuarios();
        }
        closeModal();
    }
    
    const listarAgentesTotales = async (codAlerta) => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/User/agentsTotal?' + new URLSearchParams({
                codEntidad: stateUser.codEntidad,
                codAlerta: codAlerta,
            })
        })

        if(response.isValid){
            setAgentesTotales(response.content)
        }
    }

    const listarAgentesCercanos = async (codAlerta,rango) => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Usuario/MostrarAgentesCercanos?' + new URLSearchParams({
                CodAlerta: codAlerta,
                CoberturaMetros: rango
            }),
        })

        if(response.isValid){
            setAgentesCercanos(response.content)
        }
    }

    return {
            cambiarClaveUsuario,
            usuarios, 
            listarUsuarios, 
            guardarEditarUsuario,
            usuario,
            editarValorUsuario,
            obtenerUsuario, 
            spinner,
            isOpen, 
            closeModal, 
            eliminarUsuario,
            openModal,

            listarAgentesTotales,
            agentesTotales,
            listarAgentesCercanos,
            agentesCercanos

    };
}