import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



const colores = ['Muy Grave', 'Grave', 'Leve','Muy Leve','Medio','Critico']
const valores = [10, 15, 25, 25, 20, 5]

const data = {
    labels: colores,
    datasets: [
        {
            label: 'Colores',
            data: valores,
            backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(54, 162, 235, 0.8)','rgba(250,250,23,0.8)','rgba(100, 2, 235, 0.8)','rgba(30, 30, 30, 0.8)'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)','rgba(250,250,23,1)','rgba(100, 2, 235, 1)','rgba(30, 30, 30, 1)'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(255, 99, 132, 0.4)', 'rgba(75, 192, 192, 0.4)', 'rgba(54, 162, 235, 0.4)','rgba(250,250,23,0.4)','rgba(100, 2, 235, 0.4)','rgba(30, 30, 30, 0.4)'],
            hoverBorderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)','rgba(250,250,23,1)','rgba(100, 2, 235, 1)','rgba(30, 30, 30, 1)'],
            hoverBorderWidth: 2,
        },
    ],
};

const opciones = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
        title: {
            display: true,
            text: 'Distribución de Incidentes del sistema',
            color: 'rgba(255, 255, 255, 1)',
            font: {
                size: 20,
                weight: 'bold',
                family: 'Arial',
            },
            padding: {
                top: 10,
                bottom: 10,
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            labels: {
                color: 'rgba(255, 255, 255, 1)',
                font: {
                    size: 12,
                    weight: 'normal',
                    family: 'Arial',
                },
            },
        },
    // },


        customLabels: {
            beforeDraw: function(chart) {
                const ctx = chart.ctx;
                const chartArea = chart.chartArea;
                const dataset = chart.data.datasets[0];

                if (chartArea) {
                    ctx.save();

                    const dataSum = dataset.data.reduce((a, b) => a + b, 0);
                    const centerX = (chartArea.left + chartArea.right) / 2;
                    const centerY = (chartArea.top + chartArea.bottom) / 2;

                    ctx.font = '14px Arial';
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = 'center';

                    dataset.data.forEach((value, index) => {
                        const percent = Math.round((value / dataSum) * 100);
                        const angle = (Math.PI * 2 * value) / dataSum;
                        const labelX = centerX + Math.cos(angle / 2) * (chartArea.right - chartArea.left) * 0.4;
                        const labelY = centerY + Math.sin(angle / 2) * (chartArea.bottom - chartArea.top) * 0.4;

                        ctx.fillStyle = dataset.backgroundColor[index];
                        ctx.fillText(`${percent}%`, labelX, labelY);
                    });

                    ctx.restore();
                }
            },
        },
        
    },
    
    layout: {
        padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },
    },

    elements: {
        arc: {
            borderWidth: 2,
        },
    },

    animation: {
        animateRotate: true,
        animateScale: true,
        duration: 1000,
        easing: 'easeInOutCirc',
    },
    
    interaction: {
        mode: 'index',
        intersect: true,
    },
    
    responsiveAnimationDuration: 200,
    onResize: function (chart, size) {

    },
}



export default function Pies() {
    
    return <Pie data={data} options={opciones} />
}


// -------------------DOCUMENTACION DATA ---------------
// labels (array de strings): Define las etiquetas para cada segmento del gráfico de tipo "pie".
// datasets (array de objetos): Define los conjuntos de datos del gráfico.
// label (string): El nombre o etiqueta del conjunto de datos.
// data (array de números): Define los valores de cada segmento del gráfico.
// backgroundColor (array de strings): Define el color de fondo de cada segmento del gráfico.
// borderColor (array de strings): Define el color del borde de cada segmento del gráfico.
// borderWidth (número): Define el ancho del borde de los segmentos del gráfico.
// hoverBackgroundColor (array de strings): Define el color de fondo al pasar el cursor sobre cada segmento.
// hoverBorderColor (array de strings): Define el color del borde al pasar el cursor sobre cada segmento.
// hoverBorderWidth (número): Define el ancho del borde al pasar el cursor sobre los segmentos.
// En el objeto options, utilizamos las siguientes propiedades:

// responsive (booleano): Indica si el gráfico debe ser responsivo y ajustarse al tamaño del contenedor.
// maintainAspectRatio (booleano): Indica si se debe mantener el aspecto del gráfico al cambiar de tamaño.
// indexAxis (string): Especifica el eje para el indexado de datos. En este caso, se establece en 'y' para que el eje Y sea el eje principal.
// plugins (objeto): Define las opciones de los complementos utilizados en el gráfico, como el título y la leyenda.
// title (objeto): Opciones de personalización para el título del gráfico.
// legend (objeto): Opciones de personalización para la leyenda.
// layout (objeto): Define el espaciado interno del gráfico.
// elements (objeto): Propiedades personalizadas para los elementos del gráfico, como los arcos.
// animation (objeto): Define opciones de animación para el gráfico.
// interaction (objeto): Define opciones de interacción del usuario con el gráfico.
// responsiveAnimationDuration (número): Define la duración de la animación de redimensionamiento del gráfico.
// onResize (función): Una función de devolución de llamada que se ejecuta cuando el gráfico se redimensiona.

// ------Ejemplo
// const data = {
//     labels: ['Rojo', 'Verde', 'Azul'],
//     datasets: [
//       {
//         label: 'Colores',
//         data: [20, 30, 50],
//         backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(54, 162, 235, 0.8)'],
//         borderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)'],
//         borderWidth: 1,
//         hoverBackgroundColor: ['rgba(255, 99, 132, 0.4)', 'rgba(75, 192, 192, 0.4)', 'rgba(54, 162, 235, 0.4)'],
//         hoverBorderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)'],
//         hoverBorderWidth: 2,
//       },
//     ],
//   };


// ----------------------------------DOCUEMENTACION OPCIONES ----------------------------
// responsive (booleano): Indica si el gráfico debe ser responsivo y ajustarse al tamaño del contenedor.
// maintainAspectRatio (booleano): Indica si se debe mantener el aspecto del gráfico al cambiar de tamaño.
// indexAxis (string): Especifica el eje para el indexado de datos. En este caso, se establece en 'y' para que el eje Y sea el eje principal.
// plugins (objeto): Define las opciones de los complementos utilizados en el gráfico, como el título y la leyenda.
// title (objeto): Opciones de personalización para el título del gráfico.
// legend (objeto): Opciones de personalización para la leyenda.
// layout (objeto): Define el espaciado interno del gráfico.
// elements (objeto): Propiedades personalizadas para los elementos del gráfico, como los arcos.
// animation (objeto): Define opciones de animación para el gráfico.
// interaction (objeto): Define opciones de interacción del usuario con el gráfico.
// responsiveAnimationDuration (número): Define la duración de la animación de redimensionamiento del gráfico.
// onResize (función): Una función de devolución de llamada que se ejecuta cuando el gráfico se redimensiona.


// --------------Ejemplo
// const opciones = {
//     responsive: true,
//     maintainAspectRatio: false,
//     indexAxis: 'y',
//     plugins: {
//       title: {
//         display: true,
//         text: 'Distribución de Colores',
//         color: 'rgba(75, 192, 192, 1)',
//         font: {
//           size: 16,
//           weight: 'bold',
//           family: 'Arial',
//         },
//         padding: {
//           top: 10,
//           bottom: 10,
//         },
//       },
//       legend: {
//         display: true,
//         position: 'bottom',
//         align: 'center',
//         labels: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//             weight: 'normal',
//             family: 'Arial',
//           },
//         },
//       },
//     },
//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     elements: {
//       arc: {
//         borderWidth: 2,
//       },
//     },
//     animation: {
//       animateRotate: true,
//       animateScale: true,
//       duration: 1000,
//       easing: 'easeInOutCirc',
//     },
//     interaction: {
//       mode: 'index',
//       intersect: true,
//     },
//     responsiveAnimationDuration: 200,
//     onResize: function (chart, size) {

//     },
//   };


