import React, { useEffect, useRef, useState } from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { BotonNuevo } from '../../components/buttons/BotonNuevo'
import { BotonProcesar } from '../../components/buttons/BotonProcesar'
import { classNameInput, dateFormatToString, enviarImagenMinio, notify } from '../../../utils/utils'
import { useModal } from '../../../hooks/useModal'
import { Modal } from '../../components/modal/Modal'
import TextField from '@mui/material/TextField';
import { useEntidades } from "../../../hooks/useEntidades";
import { DatePickerABX } from '../../components/pickers/DatePicker'
import { ComponenteCarga } from '../../components/forms/ComponenteCarga'
import { useRegistroCentroPoblado } from '../../../hooks/registros/useRegistroCentroPoblado'
import styled from 'styled-components'
import { GoogleMap } from '../../../utils/googlemaps'
import uploadImage from '../../../assets/images/uploadImage.jpg'
import SwitchTest from '../../components/buttons/SwitchTest'
import BotonSwitch from '../../components/buttons/BotonSwitch'
import { ModalCentrosPoblados } from '../../components/modal/ModalCentrosPoblados'


const googleMap = new GoogleMap()

export const RegistroCentrosPoblados = () => {
	const divMapa = useRef()
	const inputCentroPoblado = useRef()
	const inputAlcalde = useRef()

	const meses = [
		"ENERO",
		"FEBRERO",
		"MARZO",
		"ABRIL",
		"MAYO",
		"JUNIO",
		"JULIO",
		"AGOSTO",
		"SEPTIEMBRE",
		"OCTUBRE",
		"NOVIEMBRE",
		"DICIEMBRE"
	];
	const rangosDietas = [
		{
			nombre: '500 a 1000 Soles',
			valor: '500-1000'
		},
		{
			nombre: '1000 a 1500 Soles',
			valor: '1000-1500'
		},
		{
			nombre: '1500 a más Soles',
			valor: '>1500'
		}
	]
	const rangoTransferencias = [
		{
			nombre: '<0.5] UIT',
			valor: '<=0.5'
		},
		{
			nombre: '[0.5> UIT',
			valor: '>=0.5'
		},
		{
			nombre: '[1.0> UIT',
			valor: '>=1'
		},
	]
	const estiloFinalizado = 'hover:scale-105 hover:bg-gray-600 bg-[#1E8CFE] text-white  border-blue-800 text-xs';
	const estiloPendiente = ' bg-gray-500  text-white  ';
	const estiloBoton = "lg:w-[60px] lg:mx-1 lg:text-xs lg:h-[40px] rounded  my-1 w-full h-[100px] p-2";
	
	const [isOpenModalConfirmacion,openModalConfirmacion,closeModalConfirmacion] = useModal()
	const [isOpenModalTracking,openModalTracking,closeModalTracking] = useModal(false)

	const {
		entidades,
		listarEntidades,
	} = useEntidades()
	const {
		datosGeneralesDefault,
		caracteristicasDefault,
		condicionesDefault,
		equipamentoDefault,

		datosGenerales,
		caracteristicas,
		condiciones,
		equipamento,

		setDatosGenerales,
		setCaracteristicas,
		setCondiciones,
		setEquipamento,

		funcionEditarDatosGenerales,
		funcionEditarCaracteristicas,
		funcionEditarCondiciones,
		funcionEditarEquipamento,

		registrarDatosGenerales,
		editarDatosGenerales,
		registrarCaracteristicas,
		registrarCondiciones,
		registrarEquipamento,

		obtenerDetallesCP,
		detalleCP,
		setDetalleCP,
		registrarJurisdiccion,

		funcionesDietas,
		dietasCP,
		funcionesTransferencias,
		transferenciasCP
	} = useRegistroCentroPoblado()

	const [cantidadDataTotal, setCantidadDataTotal ] = useState(0);
	const [vistaInterior, setVistaInterior ] = useState()
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadFilas, setCantidadFilas] = useState(20);
	const [esRegistro,setEsRegistro] = useState(true)
	const [startDate, setStartDate ] = useState(new Date())
	const [estaCargando,setEstaCargando] = useState(false)
	const [codEntidad,setCodEntidad] = useState(0)
	const [nomEntidad,setNomEntidad] = useState('')
	const [isOpenMapa,setIsOpenMapa] = useState(false)
	const [anioDieta,setAnioDieta] = useState((new Date()).getFullYear())
	const [anioTransferencia,setAnioTransferencia] = useState((new Date()).getFullYear())
	const [mesRango,setMesRango] = useState({})
	const [imgCentroPoblado,setImgCentroPoblado] = useState(uploadImage)
	const [imgAlcalde,setImgAlcalde] = useState(uploadImage)
	const [estaBloqueadoRegistro,setEstaBloqueadoRegistro] = useState(true)
	const [muestraMensaje,setMuestraMensaje] = useState(false)
	const [poligonoJurisdiccion,setPoligonoJurisdiccion] = useState()		// PARA LAS COORDENADAS
	const [poligonoJurisdiccionRegistro,setPoligonoJurisdiccionRegistro] = useState(null)	//	PARA EL POLIGONO CREADO
	const [textoBuscador,setTextoBuscador] = useState('')
	const [contadorRegistros,setContadorRegistros] = useState(0)
	const [copiaDetalleCP,setCopiaDetalleCP] = useState({})
	
	useEffect(() => {
		(async () => {
			await funcionListarEntidades()
		})()
	},[])

	useEffect(() => {
		if(isOpenModalTracking){		// SE EJECUTA CUANDO SE ABRE EL MODAL
			setContadorRegistros(0)
			setEstaBloqueadoRegistro(true)
			googleMap.inicializarMapa(divMapa.current,{ocultaOpciones:true})
			
			// VALIDACION CUANDO EXISTE O NO LAS COORDENADAS DEL POLIGONO
			if(poligonoJurisdiccion){	
				const jsonPintar = poligonoJurisdiccion?.split(',').map(latLng => {
					const [ lat, lng ] = latLng.split(';');
					return { lat: Number(lat), lng: Number(lng) }
				})
				const poligonoCreado = googleMap.crearPoligono({listLatLng: jsonPintar,editable:true})
				setPoligonoJurisdiccionRegistro(poligonoCreado)		// SE AGREGA AL POLIGONO PARA PODER EDITARLO
				googleMap.enfocarPoligono(poligonoCreado)
			}else{
				setPoligonoJurisdiccionRegistro(null)
			}
		}
		else{	// SE EJECUTA CADA VES QUE SE CIERRA EL MODAL
			setIsOpenMapa(false)
			inputCentroPoblado.current && (inputCentroPoblado.current.value = '');
			setImgCentroPoblado(uploadImage);
			inputAlcalde.current && (inputAlcalde.current.value = '');
			setImgAlcalde(uploadImage);
			(contadorRegistros > 0) && funcionListarEntidades();
		}
	},[isOpenModalTracking])

	useEffect(() => {		// EJECUTA ESTAS FUNCIONES CADA VES QUE SE REALIZA UN CAMBIO EN EL MAPA
		setEstaBloqueadoRegistro(true)
		setIsOpenMapa(false)
	},[vistaInterior])

	useEffect(() => {
			setCantidadDataTotal(entidades.length);
			//-------
			const nuevasCabeceras = [
					"NOMBRE ENTIDAD",
					"TIPO ENTIDAD",
					"LATITUD",
					"LONGITUD",
					"TELEFONO 1",
					"TELEFONO 2",
					"TELEFONO 3",
					"CELULAR",
					"RESPONSABLE",
					"DISPOSITIVO LEGAL CREACION",
					"DDN",
					"CANTIDAD EMPADRONADOS",
					"MCP",
					"DEPARTAMENTO",
					"PROVINCIA",
					"DISTRITO",
					"DIRECCION",
					"CORREO ELECTRONICO 1",
					"CORREO ELECTRONICO 2",
					"DISPOSITIVO LEGAL ADECUACION",
					"NUMERO POBLADORES INEI",
					"NUMERO POBLADORES MANUAL",
					"DATOS COMPLETOS",
			];
			const arrNuevo = entidades.map((data) => {
					const {
							codDepartamento,
							codDistrito,
							codEntidad,
							codInstitucion,
							codProvincia,
							poligonoJurisdiccion,
							...restoInfo
					} = data;
					const nuevoJson = Object.fromEntries(
							Object.values(restoInfo).map((data, i) => [
									nuevasCabeceras[i],
									data,
							])
					);
					return nuevoJson;
			});
			const arrImpresion = arrNuevo;
			//-------
	}, [entidades]);

	useEffect(() => {
		if(Object.keys(detalleCP).length != 0){
			setDatosGenerales(Object.assign({},datosGeneralesDefault,detalleCP))
			setCaracteristicas(Object.assign({},caracteristicasDefault,detalleCP))
			setCondiciones(Object.assign({},condicionesDefault,detalleCP))
			setEquipamento(Object.assign({},equipamentoDefault,detalleCP))
			detalleCP.urlImagenAlcalde && setImgAlcalde(detalleCP.urlImagenAlcalde)
			detalleCP.urlImagenCP && setImgCentroPoblado(detalleCP.urlImagenCP)

			setCopiaDetalleCP({...detalleCP})		// COPIA LOS DATOS PARA RETORNARLOS CUANDO CANCELA UN REGISTRO
		}
	},[detalleCP])

	useEffect(() => {
		isOpenModalConfirmacion && setStartDate(new Date())
	},[isOpenModalConfirmacion])

	const funcionListarEntidades = async () => {
		setEstaCargando(true)
		await listarEntidades()
		setEstaCargando(false)
	}
	
	const funcionRegistroEdicionCentroPoblado = async () => {
		const response = await (async () => {
			switch (vistaInterior) {
				case 1:
					const urlImagenCP = inputCentroPoblado.current.files.length != 0 ? await enviarImagenMinio({file:inputCentroPoblado.current.files[0]}) : ''
					const urlImagenAlcalde = inputAlcalde.current.files.length != 0 ? await enviarImagenMinio({file:inputAlcalde.current.files[0]}) : ''
					
					const res = 
					esRegistro ?
					await registrarDatosGenerales({
						urlImagenCP: urlImagenCP,
						urlImagenAlcalde: urlImagenAlcalde
					})
					:
					await editarDatosGenerales({
						codEntidad: codEntidad,
						urlImagenAlcalde: urlImagenAlcalde,
						urlImagenCP: urlImagenCP
					})
					
					if(res.CodResultado == 1 && esRegistro){
						closeModalTracking()
						await funcionListarEntidades()
					}
					return res
				case 2:
					return await registrarCaracteristicas(codEntidad)
				case 3:
					return await registrarCondiciones(codEntidad)
				case 4:
					return await registrarEquipamento(codEntidad)
				case 5:
					break;
				default:
					return {CodResultado: 0, DesResultado: 'No existe esta vista'}
			}
		})()

		if(response.CodResultado == 1){
			setContadorRegistros(contadorRegistros + 1); 	// ADICIONA UN CAMBIO AL CONTADOR CUANDO SE HACE UN REGISTRO CORRECTO
			await obtenerDetallesCP(codEntidad)						// ACTUALIZA LOS VALORES DE TODO EL MODAL CUANDO REALIZA LOS REGISTROS
		}

		notify(response.DesResultado,response.CodResultado == 1 ? 'success' : 'error')
	}

	const funcionRegistroDietaTransferencia = async () => {
		const response = 
		mesRango.tipo == 1 ?		// 1: DIETA, 2: TRANSFERENCIA
		await funcionesDietas.registrarDietaCP({
			codEntidad: codEntidad,
			anio: anioDieta,
			mes: mesRango.mes,
			rango: mesRango.rango,
			fecha: dateFormatToString(startDate)
		})
		:
		await funcionesTransferencias.registrarTransferenciaCP({
			codEntidad: codEntidad,
			anio: anioTransferencia,
			mes: mesRango.mes,
			rango: mesRango.rango,
			fecha: dateFormatToString(startDate)
		})

		notify(response.DesResultado,response.CodResultado == 1 ? 'success' : 'error')
		if(response.CodResultado == 1){
			closeModalConfirmacion()
		}
	}

	const agregarPoligonoEditable = async () => {
		const nuevoPoligono = googleMap.crearPoligono({
			listLatLng: [
				{ lat: -12.039203, lng: -77.066496 },
				{ lat: -12.060808, lng: -77.057635 },
				{ lat: -12.061757, lng: -77.027896 },
			],
			editable: true,
			draggable:true,
			color: 'red'
		})
		setPoligonoJurisdiccionRegistro(nuevoPoligono)
	}

	const registrarPoligono = async () => {
		if (poligonoJurisdiccionRegistro) {
			const path = poligonoJurisdiccionRegistro.getPath();
			const coordinatesArray = [];
			for (let i = 0; i < path.getLength(); i++) {
				const latLng = path.getAt(i);
				coordinatesArray.push(`${latLng.lat()};${latLng.lng()}`);
			}
			const arrCoordenadas = coordinatesArray.join(',');
			const response = await registrarJurisdiccion({
				codEntidad: codEntidad,
				poligonoJurisdiccion: arrCoordenadas
			})
			notify(response.DesResultado, response.CodResultado == 1 ? 'success' : 'error')
			if(response.CodResultado == 1){
				closeModalTracking()
			}
		}
	}

	const ComponenteRegistroFinalizado = () => {
		// Crear un objeto con los datos dinámicos por cada vista
		const configuraciones = {
			1: { bloque: 'bloqueUnoCerrado', datos: datosGenerales, funcion: funcionEditarDatosGenerales },
			2: { bloque: 'bloqueDosCerrado', datos: caracteristicas, funcion: funcionEditarCaracteristicas },
			3: { bloque: 'bloqueTresCerrado', datos: condiciones, funcion: funcionEditarCondiciones },
			4: { bloque: 'bloqueCuatroCerrado', datos: equipamento, funcion: funcionEditarEquipamento },
		};
	
		// Obtener los valores dinámicos de acuerdo al caso
		const { bloque, datos, funcion } = configuraciones[vistaInterior] || {};
	
		if (!bloque || !datos || !funcion) {
			return null; // Manejar caso por defecto si no existe una vista válida
		}
	
		const isOpen = datos[bloque].toString() === 'true';
		const handleSwitchClick = () => funcion(bloque, !isOpen);
	
		return (
			<div
				onMouseEnter={() => setMuestraMensaje(true)}
				onMouseLeave={() => setMuestraMensaje(false)}
				className={`flex text-xs lg:justify-end items-center gap-2 ${estaBloqueadoRegistro ? 'cursor-not-allowed' : ''}`}
			>
				<span className="font-bold">Registro Finalizado</span>
				<BotonSwitch
					disabled={estaBloqueadoRegistro}
					isOpen={isOpen}
					dioClick={handleSwitchClick}
				/>
			</div>
		);
	};

	const ComponenteContenidoRegistro = () => {
		return (
			<>
				{/* DATOS GENERALES */}
				{
					vistaInterior === 1 ?
					<div className='flex flex-col gap-1 w-full h-full'>
						<fieldset className='border border-gray-500 rounded h-fit'>
							<legend className='ml-4 font-bold text-sm text-gray-400'> Centro poblado </legend>
							<div className='flex lg:flex-col gap-10 text-xs py-2 px-4 justify-between items-center'>
								<div className='flex-grow grid grid-cols-3 lg:grid-cols-1 gap-1 items-center'>
									<span>Nombre</span>
									<input disabled={!esRegistro} value={datosGenerales.nomEntidad} onChange={e => {funcionEditarDatosGenerales('nomEntidad',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
									<span>Dispositivo legal de creación</span>
									<input value={datosGenerales.dispositivoLegalCreacion} onChange={e => {funcionEditarDatosGenerales('dispositivoLegalCreacion',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
									<span>Dispositivo legal de adecuación</span>
									<input value={datosGenerales.dispositivoLegalAdecuacion} onChange={e => {funcionEditarDatosGenerales('dispositivoLegalAdecuacion',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
									<span>Código MCP</span>
									<input value={datosGenerales.mcp} onChange={e => {funcionEditarDatosGenerales('mcp',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
									<span>DDN</span>
									<input value={datosGenerales.ddn} onChange={e => {funcionEditarDatosGenerales('ddn',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
									<span>Teléfono fijo</span>
									<input value={datosGenerales.telefonoFijoUno} onChange={e => {funcionEditarDatosGenerales('telefonoFijoUno',e.target.value)}} type="number" min={0} maxLength={9} className={`${classNameInput} col-span-2`} />
									<span>Teléfono celular</span>
									<input value={datosGenerales.numeroCelular} onChange={e => {funcionEditarDatosGenerales('numeroCelular',e.target.value)}} type="number" min={0} maxLength={9} className={`${classNameInput} col-span-2`} />
									<span>Población según INEI</span>
									<input placeholder='(INEI)' value={datosGenerales.cantidadPobladorRegistroINEI} onChange={e => {funcionEditarDatosGenerales('cantidadPobladorRegistroINEI',e.target.value)}} type="number" min={0} className={`${classNameInput} col-span-2`} />
									<span>Población según alcalde (Padrón general)</span>
									<input placeholder='(Muestreo)' value={datosGenerales.cantidadPobladorRegistroManual} onChange={e => {funcionEditarDatosGenerales('cantidadPobladorRegistroManual',e.target.value)}} type="number" min={0} className={`${classNameInput} col-span-2`} />
									<span>Población según empadronamiento</span>
									<input defaultValue={0} disabled={true} type="number" min={0} className={`${classNameInput} col-span-2`} />
									<span>Correo electrónico</span>
									<input value={datosGenerales.correoElectronicoUno} onChange={e => {funcionEditarDatosGenerales('correoElectronicoUno',e.target.value)}} type="text" className={`${classNameInput} col-span-2`} />
								</div>
								<div className='lg:hidden'>
									{/* <img className='h-[200px] rounded-xl shadow-md shadow-white' alt='' src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR55ZU1vM5EcYnOE3w7fgLBStF4WPA3JFW-OS3FdYBXKY27sA8_N8PJ3za5-N--A1_WJWU&usqp=CAU'}/> */}
									<div className='w-[170px] h-[170px] rounded-lg overflow-hidden shadow-2xl relative group flex items-center'>
										<img 
											src={imgCentroPoblado} 
											alt="" 
											className='transition-opacity duration-300 ease-in-out group-hover:opacity-50 object-contain object-center' 
										/>
										<div 
											className='absolute top-0 w-full h-full text-center flex items-center bg-white bg-opacity-80 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 z-10 font-bold text-2xl text-red-600 cursor-pointer'
											onClick={() => inputCentroPoblado.current.click()}
										>
											SUBIR ARCHIVO
										</div>
										<input 
											ref={inputCentroPoblado}
											onChange={e => setImgCentroPoblado(URL.createObjectURL(e.target.files[0]))}
											type='file'
											accept='image/*'
											className='hidden'
										/>
									</div>
								</div>
							</div>
						</fieldset>
						<fieldset className='border border-gray-500 rounded flex-grow'>
							<legend className='ml-4 font-bold text-sm text-gray-400'>Datos del alcalde</legend>
							<div className='flex lg:flex-col gap-10 text-xs py-2 px-4 justify-between items-center'>
								<div className='flex-grow grid grid-cols-3 lg:grid-cols-1 gap-1 items-center'>
									<span>Nombre</span>
									<input value={datosGenerales.nomResponsable} onChange={e => funcionEditarDatosGenerales('nomResponsable',e.target.value)} type="text" className={`${classNameInput} col-span-2`} />
									<span>DNI</span>
									<input value={datosGenerales.docAlcalde} onChange={e => funcionEditarDatosGenerales('docAlcalde',e.target.value)} type="number" min={0} maxLength={8} className={`${classNameInput} col-span-2`} />
									<span>Dirección alcaldía</span>
									<input value={datosGenerales.direccion} onChange={e => funcionEditarDatosGenerales('direccion',e.target.value)} type="text" className={`${classNameInput} col-span-2`} />
									<span>Teléfono alcaldía</span>
									<input type="number" min={0} maxLength={9} className={`${classNameInput} col-span-2`} />
									<span>Correo alcaldía</span>
									<input type="text" className={`${classNameInput} col-span-2`} />
								</div>
								<div className='lg:hidden'>
									{/* <img className='h-[200px] rounded-xl shadow-md shadow-white' src={'https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg'}/> */}
									<div className='w-[170px] h-[170px] rounded-lg overflow-hidden shadow-2xl relative group flex items-center'>
										<img 
											src={imgAlcalde} 
											alt="" 
											className='transition-opacity duration-300 ease-in-out group-hover:opacity-50 object-contain object-center' 
										/>
										<div 
											className='absolute top-0 w-full h-full text-center flex items-center bg-white bg-opacity-80 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 z-10 font-bold text-2xl text-red-600 cursor-pointer'
											onClick={() => inputAlcalde.current.click()}
										>
											SUBIR ARCHIVO
										</div>
										<input 
											ref={inputAlcalde}
											onChange={e => setImgAlcalde(URL.createObjectURL(e.target.files[0]))}
											type='file'
											accept='image/*'
											className='hidden'
										/>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					: <></>
				}
				{/* CARACTERISTICAS */}
				{
					vistaInterior === 2 ?
					<div className='flex flex-col w-full h-full gap-2'>
						<fieldset className='border border-gray-500 rounded-md p-4'>
							<div className='grid grid-cols-3 text-xs lg:flex lg:flex-col lg:items-start gap-1 items-center'>
								<span>Categoría</span>
								<select value={caracteristicas.codEntidadCategoria} onChange={e => funcionEditarCaracteristicas('codEntidadCategoria',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Caserio</option>
									<option value="2">Pueblo</option>
									<option value="3">Villa</option>
								</select>
								<span>Densidad poblacional</span>
								<select value={caracteristicas.codEntidadDispercion} onChange={e => funcionEditarCaracteristicas('codEntidadDispercion',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Muy dispersa</option>
									<option value="2">Regular</option>
									<option value="3">Concentrada</option>
								</select>
								<span>Infraestructura comunal</span>
								<select value={caracteristicas.tieneLocalComunal} onChange={e => funcionEditarCaracteristicas('tieneLocalComunal',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="1">Posee local comunal</option>
									<option value="0">No posee local comunal</option>
								</select>
								<span>Acceso a transporte</span>
								<select value={caracteristicas.codEntidadTransporte} onChange={e => funcionEditarCaracteristicas('codEntidadTransporte',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Servicio diario</option>
									<option value="2">Servicio interdiario</option>
									<option value="3">Servicio restringido</option>
								</select>
								<span>Limites geográficos</span>
								<div className='flex flex-col gap-2'>
									<input value={caracteristicas.localColindanteNorte} onChange={e => funcionEditarCaracteristicas('localColindanteNorte',e.target.value)} type="text" className={classNameInput} placeholder='Colindante norte' />
									<input value={caracteristicas.localColindanteSur} onChange={e => funcionEditarCaracteristicas('localColindanteSur',e.target.value)} type="text" className={classNameInput} placeholder='Colindante sur' />
									<input value={caracteristicas.localColindanteEste} onChange={e => funcionEditarCaracteristicas('localColindanteEste',e.target.value)} type="text" className={classNameInput} placeholder='Colindante este' />
									<input value={caracteristicas.localColindanteOeste} onChange={e => funcionEditarCaracteristicas('localColindanteOeste',e.target.value)} type="text" className={classNameInput} placeholder='Colindante oeste' />
								</div>
							</div>
						</fieldset>
					</div>
					: <></>
				}
				{/* CONDICIONES */}
				{
					vistaInterior === 3 ?
					<div className='flex flex-col w-full h-full gap-1'>
						<fieldset className='flex-grow border border-gray-500 rounded-md p-4'>
							<legend className='ml-4 font-bold text-sm text-gray-400'>Centros educativos</legend>
							<div className='grid grid-cols-6 gap-y-1 gap-x-4 items-center text-sm lg:flex lg:flex-col lg:items-start'>
									<span>Escuelas iniciales</span>
									<input value={condiciones.localEscolarInicial} onChange={e => funcionEditarCondiciones('localEscolarInicial',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
									<span>Escuelas primarias</span>
									<input value={condiciones.localEscolarPrimaria} onChange={e => funcionEditarCondiciones('localEscolarPrimaria',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
									<span>Escuelas secundarias</span>
									<input value={condiciones.localEscolarSecundaria} onChange={e => funcionEditarCondiciones('localEscolarSecundaria',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
									<span>Institutos superiores</span>
									<input value={condiciones.institucionSuperior} onChange={e => funcionEditarCondiciones('institucionSuperior',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
									<span>Otros</span>
									<input value={condiciones.otro} onChange={e => funcionEditarCondiciones('otro',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
							</div>
						</fieldset>
						<fieldset className='flex-grow border border-gray-500 rounded-md p-4'>
							<legend className='ml-4 font-bold text-sm text-gray-400'>Centros de salud</legend>
							<div className='grid grid-cols-6 gap-y-1 gap-x-4 items-center text-sm lg:flex lg:flex-col lg:items-start'>
								<span>Posta de salud</span>
								<input value={condiciones.postaSalud} onChange={e => funcionEditarCondiciones('postaSalud',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
								<span>Puesto de salud</span>
								<input value={condiciones.puestoSalud} onChange={e => funcionEditarCondiciones('puestoSalud',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
								<span>Centro de salud</span>
								<input value={condiciones.centroSalud} onChange={e => funcionEditarCondiciones('centroSalud',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
							</div>
						</fieldset>
						<fieldset className='flex-grow border border-gray-500 rounded-md p-4'>
						<legend className='ml-4 font-bold text-sm text-gray-400'>Vias de transporte</legend>
							<div className='grid grid-cols-6 gap-y-1 gap-x-4 items-center text-sm lg:flex lg:flex-col lg:items-start'>
								<span>Vias urbanas</span>
								<input value={condiciones.cantidadCalleViaUrbana} onChange={e => funcionEditarCondiciones('cantidadCalleViaUrbana',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
								<span>Vias vecinales</span>
								<input value={condiciones.cantidadCalleViaVecinal} onChange={e => funcionEditarCondiciones('cantidadCalleViaVecinal',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
								<span>Trochas carrozables</span>
								<input value={condiciones.cantidadTrochaCarrozable} onChange={e => funcionEditarCondiciones('cantidadTrochaCarrozable',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
								<span>Caminos de herradura</span>
								<input value={condiciones.cantidadRutaCaminoHerradura} onChange={e => funcionEditarCondiciones('cantidadRutaCaminoHerradura',e.target.value)} type="text" min={0} className={`${classNameInput} col-span-2`} />
							</div>
						</fieldset>
						<fieldset className='flex-grow border border-gray-500 rounded-md p-4'>
						<legend className='ml-4 font-bold text-sm text-gray-400'>Servicios básicos</legend>
							<div className='grid grid-cols-6 gap-y-1 gap-x-4 items-center text-sm lg:flex lg:flex-col lg:items-start'>
								<span>Agua potable</span>
								<select value={condiciones.codEntidadAguaPotable} onChange={e => funcionEditarCondiciones('codEntidadAguaPotable',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Domiciliario</option>
									<option value="2">Comunal</option>
								</select>
								<span>Desague</span>
								<select value={condiciones.codEntidadDesague} onChange={e => funcionEditarCondiciones('codEntidadDesague',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Domiciliario</option>
									<option value="2">Sistema convencional</option>
								</select>
								<span>Señal de internet</span>
								<select value={condiciones.codEntidadConectividad} onChange={e => funcionEditarCondiciones('codEntidadConectividad',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Mala</option>
									<option value="2">Regular</option>
									<option value="3">Buena</option>
									<option value="4">Ninguna</option>
								</select>
								<span>Electricidad</span>
								<select value={condiciones.codEntidadElectricidad} onChange={e => funcionEditarCondiciones('codEntidadElectricidad',e.target.value)} className={`${classNameInput} col-span-2`}>
									<option value="0">-- Seleccione --</option>
									<option value="1">Monofásica</option>
									<option value="2">Trifásica</option>
									<option value="3">Continua</option>
									<option value="4">Interrumpida</option>
									<option value="5">Ninguna</option>
								</select>
							</div>
						</fieldset>
					</div>
					: <></>
				}
				{/* EQUIPAMENTO */}
				{
					vistaInterior === 4 ?
					<div className='flex flex-col w-full h-full gap-2'>
						<fieldset className='border border-gray-500 rounded-md p-4'>
							<legend className='ml-4 font-bold text-sm text-gray-400'>Equipamiento Urbano</legend>
							<div className='grid grid-cols-6 lg:flex lg:flex-col lg:items-start gap-y-1 gap-x-4 text-xs items-center'>
								<span>Plazas</span>
								<input value={equipamento.cantidadPlaza} onChange={e => funcionEditarEquipamento('cantidadPlaza',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Mercados</span>
								<input value={equipamento.cantidadMercados} onChange={e => funcionEditarEquipamento('cantidadMercados',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Estadios</span>
								<input value={equipamento.cantidadEstadios} onChange={e => funcionEditarEquipamento('cantidadEstadios',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Juzgados</span>
								<input value={equipamento.cantidadJuzgadoPaz} onChange={e => funcionEditarEquipamento('cantidadJuzgadoPaz',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Cementerios</span>
								<input value={equipamento.cantidadCementerio} onChange={e => funcionEditarEquipamento('cantidadCementerio',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Comisarias</span>
								<input value={equipamento.cantidadComisaria} onChange={e => funcionEditarEquipamento('cantidadComisaria',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Locales Comunales</span>
								<input value={equipamento.cantidadLocalComunal} onChange={e => funcionEditarEquipamento('cantidadLocalComunal',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Atractivos Turísticos</span>
								<input value={equipamento.cantidadAtractivoTuristico} onChange={e => funcionEditarEquipamento('cantidadAtractivoTuristico',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Coliseos</span>
								<input value={equipamento.cantidadColiseo} onChange={e => funcionEditarEquipamento('cantidadColiseo',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
								<span>Parques</span>
								<input value={equipamento.cantidadParque} onChange={e => funcionEditarEquipamento('cantidadParque',e.target.value)} type="number" min={0} className={`${classNameInput} col-span-2`} />
							</div>
						</fieldset>
					</div>
					: <></>
				}
				{/* DIETAS */}
				{
					vistaInterior === 5 ?
					<div className='flex flex-col gap-4 flex-grow h-full w-full overflow-auto py-4'>
						<div className='border border-gray-500 rounded-md w-full h-1/2 relative'>
							<div className='ml-4 font-bold absolute t-5 z-20 -translate-y-1/2 text-sm text-gray-400 bg-[#2f3134]'>Dieta</div>
							<div className='pt-4 px-4 text-xs flex flex-col gap-2 max-w-full h-full overflow-auto'>
								<div className='flex gap-2 items-center'>
									<div className='flex items-center gap-4 lg:gap-1'>
										<span>Año</span>
										<select value={anioDieta} onChange={e => setAnioDieta(e.target.value)} className={`${classNameInput} w-[200px] lg:w-fit`}>
											{
												Array.from({length: 5}).map((_,i) => {
													const valorAnio = (new Date()).getFullYear() - i
													return <option key={i+1} value={valorAnio}>{valorAnio}</option>
												})
											}
										</select>
									</div>
									<div className='flex'>Según ley N°27972(Art. 131)</div>
								</div>
								<div className='flex-grow w-full overflow-auto containerScroll'>
									<table className='table'>
										<thead>
											<tr className='sticky z-50'>
												<th className='lg:!text-xs text-center sticky z-50 left-0' ref={(node) => {
													if (node) {
														node.style.setProperty("position", "sticky", "important");
														node.style.setProperty("z-index", "100", "important");
													}
												}}>RANGO</th>
												{
													meses.map((data,i) => <th key={i+1} className='lg:!text-xs !w-[200px]'>{data}</th>)
												}
											</tr>
										</thead>
										<tbody>
											{
												rangosDietas.map((data,i) => (
													<tr key={i+1}>
														<td className='bg-[#2e5289] whitespace-nowrap sticky left-0 lg:!text-xs'>{data.nombre}</td>
														{
															meses.map((data2,j) => {
																const estaCheckeado = dietasCP?.meses?.some(e => (
																	((e.mesCorrespondiente).toUpperCase() === data2) && (e.detalleMes[0].rango === data.valor)
																))
																const mesRegistrado = dietasCP?.meses?.some(e => (e.mesCorrespondiente).toUpperCase() === data2)
																const fechaRegistrada = 
																estaCheckeado ?
																	dietasCP?.meses?.find(e => (((e.mesCorrespondiente).toUpperCase() === data2) && (e.detalleMes[0].rango === data.valor))).detalleMes[0].fechaDeposito
																: 	''

																return(
																	<td key={j+1} className={`text-center border-r border-gray-500 ${mesRegistrado ? 'cursor-not-allowed' : ''}`}>
																		<div className='flex flex-col items-center'>
																			<input 
																				type="radio" 
																				onChange={e => {
																					if(!mesRegistrado){
																						setMesRango({
																							mes: j+1,
																							rango: data.valor,
																							tipo: 1
																						})
																						openModalConfirmacion()
																					}
																				}} 
																				checked={estaCheckeado}
																				className={`${mesRegistrado ? 'pointer-events-none' : ''}`}
																			/>
																			{
																				estaCheckeado ?
																					<span className='lg:!text-xs'>{fechaRegistrada}</span>
																				: 	<></>
																			}
																		</div>
																	</td>
																)
															})
														}
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className='border border-gray-500 rounded-md w-full h-1/2 relative'>
							<legend className='ml-4 font-bold absolute t-0 -translate-y-1/2 text-sm text-gray-400 bg-[#2f3134]'>Transferencia</legend>
							<div className='pt-4 px-4 text-xs flex flex-col gap-2 max-w-full h-full overflow-auto'>
								<div className='flex gap-2 items-center'>
									<div className='flex items-center gap-4 lg:gap-1'>
										<span>Año</span>
										<select value={anioTransferencia} onChange={e => setAnioTransferencia(e.target.value)} className={`${classNameInput} w-[200px] lg:w-fit`}>
											{
												Array.from({length: 5}).map((_,i) => {
													const valorAnio = (new Date()).getFullYear() - i
													return <option key={i+1} value={valorAnio}>{valorAnio}</option>
												})
											}
										</select>
									</div>
									<div className='flex'>Según ley N°27972(Art. 133)</div>
								</div>
								<div className='flex-grow w-full overflow-auto containerScroll'>
									<table className='table'>
										<thead>
											<tr>
												<th className="text-center sticky left-0" ref={(node) => {
														if (node) {
															node.style.setProperty("position", "sticky", "important");
															node.style.setProperty("z-index", "100", "important");
														}
													}}>RANGO</th>
												{
													meses.map((data,i) => <th key={i+1}>{data}</th>)
												}
											</tr>
										</thead>
										<tbody>
											{
												rangoTransferencias.map((data,i) => (
													<tr key={i+1}>
														<td className='bg-[#2e5289] whitespace-nowrap sticky left-0'>{data.nombre}</td>
														{
															meses.map((data2,j) => {
																const estaCheckeado = transferenciasCP?.meses?.some(e => (
																	((e.mesCorrespondiente).toUpperCase() === data2) && (e.detalleMes[0].rango === data.valor)
																))
																const mesRegistrado = transferenciasCP?.meses?.some(e => (e.mesCorrespondiente).toUpperCase() === data2)
																const fechaRegistrada = 
																estaCheckeado ?
																	transferenciasCP?.meses?.find(e => (((e.mesCorrespondiente).toUpperCase() === data2) && (e.detalleMes[0].rango === data.valor))).detalleMes[0].fechaTransferencia
																: 	''


																return(
																	<td key={j+1} className={`text-center border-r border-gray-500 ${mesRegistrado ? 'cursor-not-allowed' : ''}`}>
																		<div className='flex flex-col items-center'>
																			<input 
																				type="radio" 
																				onChange={e => {
																					if(!mesRegistrado){
																						setMesRango({
																							mes: j+1,
																							rango: data.valor,
																							tipo: 2
																						})
																						openModalConfirmacion()
																					}
																				}} 
																				checked={estaCheckeado}
																				className={`${mesRegistrado ? 'pointer-events-none' : ''}`}
																			/>
																			{
																				estaCheckeado ?
																					<span>{fechaRegistrada}</span>
																				: 	<></>
																			}
																		</div>
																	</td>
																)
															})
														}
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					: <></>
				}
			</>
		)
	}

	return (
		<>
			<ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>
			<div className='w-full h-full flex flex-col gap-2'>
				<ContenedorParametros>
					{/* <select className={`${classNameInput} w-[200px]`}></select> */}
					<BotonNuevo onClick={()=>{
						setDatosGenerales(datosGeneralesDefault)
						setCaracteristicas(caracteristicasDefault)
						setCondiciones(condicionesDefault)
						setEquipamento(equipamentoDefault)

						setVistaInterior(1)
						setEsRegistro(true)
						setNomEntidad('')
						openModalTracking()
					}}></BotonNuevo>
					<BotonProcesar onClick={()=>funcionListarEntidades()}></BotonProcesar>
				</ContenedorParametros>

				{/* COMPONENTE DE ACTUALIZADOS */}
				<div className="flex lg:flex-col items-center justify-between gap-2">
					<div className="flex gap-5 font-semibold items-center justify-between w-full">
						<span className='lg:text-xs'>
							Actualizados: {entidades.reduce((acum,elem) => elem.tieneDatosCompletos ? acum+1 : acum,0)} / {cantidadDataTotal}
						</span>

						<div className='flex gap-2 items-center'>
							<input value={textoBuscador} placeholder='(Busqueda)' onChange={e => setTextoBuscador(e.target.value)} type="text" className={classNameInput} />
							<button><i className='fas fa-search'></i></button>
						</div>
					</div>
				</div>
				
				{/* COMPONENTE TABLA */}
				<div className='overflow-auto flex-grow containerScroll'>
					<table className='tableResponsive'>
						<thead>
							<tr>
								<th className="text-left">Nº</th>
								<th className="text-left">COD.MCP</th>
								<th className="text-left">CENTRO POBLADO</th>
								<th className="text-left">NOMBRE DEL ALCALDE</th>
								<th className="text-left">DISPOSITIVO LEGAL</th>
								<th className="text-left">DDN</th>
								<th className="text-left">TELEFONO</th>
								<th className="text-left">DIRECCION</th>
								<th className="text-left">CORREO</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								entidades.length > 0 ? (
									(
										(
											textoBuscador && 
											(
												entidades.filter(objEntidad => (
													Object.values(objEntidad)
													.map(elemento => elemento.toString().toLowerCase())
													.some(elementoMinuscula => elementoMinuscula.includes(textoBuscador.toLowerCase()))
												)).length < 500
											)
										) 
										?
											entidades.filter(objEntidad => (
												Object.values(objEntidad)
												.map(elemento => elemento.toString().toLowerCase())
												.some(elementoMinuscula => elementoMinuscula.includes(textoBuscador.toLowerCase()))
											))
										:
											entidades
											.slice(cantidadFilas * (paginaActual - 1) , cantidadFilas * paginaActual)
									).map((ent, i) => {
										return (
											<tr key={i + 1} className={`${ent.tieneDatosCompletos && "datosIncompletos"}`}>
												<td data-label="N" className="whitespace-nowrap">{cantidadFilas * (paginaActual -1) + (i + 1)}</td>
												<td data-label="Cod.MCP"><div title={ent.mcp} className='w-fit whitespace-nowrap max-w-[100px] overflow-hidden text-ellipsis'>{ent.mcp}</div></td>
												<td data-label="Nombre C.P."><div title={ent.nomEntidad} className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">{ent.nomEntidad}</div></td>
												<td data-label="Nombre alcalde"><div title={ent.nomResponsable} className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">{ent.nomResponsable}</div></td>
												<td data-label="Dispositivo legal"><div title={ent.dispositivoLegalCreacion} className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">{ent.dispositivoLegalCreacion}</div></td>
												<td data-label="DDN"><div title={ent.ddn} className="w-fit whitespace-nowrap max-w-[50px] overflow-hidden text-ellipsis">{ent.ddn}</div></td>
												<td data-label="Teléfono"><div className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis" title={`${ent.telefonoFijoUno || '-'} ${ent.telefonoFijoDos || '-'} ${ent.telefonoFijoTres || '-'} ${ent.numeroCelular || '-'}`}>{ent.telefonoFijoUno || '-'} {ent.telefonoFijoDos || '-'} {ent.telefonoFijoTres || '-'} {ent.numeroCelular || '-'}</div></td>
												<td data-label="Direccion"><div className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis" title={ent.direccion} >{ent.direccion}</div></td>
												<td data-label="Correo electronico"><div className="w-fit whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis" title={ent.correoElectronicoUno} >{ent.correoElectronicoUno}</div></td>
												<td 
													className="whitespace-nowrap flex gap-1" 
													onClick={e => {
														setPoligonoJurisdiccion(ent.poligonoJurisdiccion)
														if(ent.poligonoJurisdiccion.length == 0){
															notify('El centro poblado no tiene una jurisdicción registrada','info')
														}
														setCodEntidad(ent.codEntidad)
														obtenerDetallesCP(ent.codEntidad)
													}}
												>
													<button
														title="Datos generales"
														className={`${ent.bloqueUnoCerrado.toString() == 'false' ? estiloPendiente : estiloFinalizado } 
															${false && ' opacity-50'}
																opacity-50'
															rounded px-2 text-sm h-[25px] w-[25px]`}
															
														onClick={() => {
															setVistaInterior(1)
															setEsRegistro(false)
															openModalTracking()
															setNomEntidad(ent.nomEntidad)
														}}
													>
														<span >1</span>
													</button>	
													<button
														title="Características"
														className={`${ ent.bloqueDosCerrado.toString() == 'false' ? estiloPendiente : estiloFinalizado } 
															${false && ' opacity-50'}
																opacity-50'
															rounded px-2 text-sm h-[25px] w-[25px]`}
															
															onClick={() => {
																setVistaInterior(2)
																setEsRegistro(false)
																openModalTracking()
																setNomEntidad(ent.nomEntidad)
															}}
													>
														<span >2</span>
													</button>	
													<button
														title="Condiciones"
														className={`${ ent.bloqueTresCerrado.toString() == 'false' ? estiloPendiente : estiloFinalizado } 
															${false && ' opacity-50'}
																opacity-50'
															rounded px-2 text-sm h-[25px] w-[25px]`}
															
															onClick={() => {
																setVistaInterior(3)
																setEsRegistro(false)
																openModalTracking()
																setNomEntidad(ent.nomEntidad)
															}}
													>
														<span >3</span>
													</button>	
													<button
														title="Equipamento"
														className={`${ ent.bloqueCuatroCerrado.toString() == 'false' ? estiloPendiente : estiloFinalizado } 
															${false && ' opacity-50'}
																opacity-50'
															rounded px-2 text-sm h-[25px] w-[25px]`}
															
															onClick={() => {
																setVistaInterior(4)
																setEsRegistro(false)
																openModalTracking()
																setNomEntidad(ent.nomEntidad)
															}}
													>
														<span >4</span>
													</button>	
													<button
														title="Dietas y tranferencias"
														className={`${ ent.registroDeposito.toString() == 'false' ? estiloPendiente : estiloFinalizado } 
															${false && ' opacity-50'}
																opacity-50'
															rounded px-2 text-sm h-[25px] w-[25px]`}
															
														onClick={() => {
															setVistaInterior(5)
															setEsRegistro(false)
															openModalTracking()
															setNomEntidad(ent.nomEntidad)
															funcionesDietas.listarDietasCP({
																codEntidad: ent.codEntidad,
																anio: anioDieta
															})
															funcionesTransferencias.listarTransferenciasCP({
																codEntidad: ent.codEntidad,
																anio: anioTransferencia
															})
														}}
													>
														<span >5</span>
													</button>	
												</td>
											</tr>
										);
									})
								) 
								: (<tr><td colSpan={10} className="text-center">No hay información para mostrar por el momento</td></tr>)
							}
						</tbody>
					</table>
				</div>

				{/* COMPONENTE PARA EL PAGINADOR */}
				{
					textoBuscador && 
					(
						entidades.filter(objEntidad => (
							Object.values(objEntidad)
							.map(elemento => elemento.toString().toLowerCase())
							.some(elementoMinuscula => elementoMinuscula.includes(textoBuscador.toLowerCase()))
						)).length < 500
					) 
					?
						<></>
					: 	
						<div
							className={`flex lg:flex-col items-center justify-end gap-2 px-4`}
						>
							<div>
								<select
										value={cantidadFilas}
										onChange={(e) =>
												setCantidadFilas(e.target.value)
										}
										className={`${classNameInput} w-[100px]`}
								>
										{entidades.length > 10 ? (
												<option value={10}>10</option>
										) : (
												<></>
										)}
										{entidades.length > 20 ? (
												<option value={20}>20</option>
										) : (
												<></>
										)}
										{entidades.length > 40 ? (
												<option value={40}>40</option>
										) : (
												<></>
										)}
										<option value={entidades.length}>Todos</option>
								</select>
							</div>
							<div className='flex items-center gap-2 px-4'>
								<button
										onClick={() =>
												setPaginaActual(
														paginaActual - 1 == 0
																? Math.ceil(
																			entidades.length /
																					cantidadFilas
																	)
																: paginaActual - 1
												)
										}
										className="border rounded h-[30px] px-2"
								>
										<i
												className="fa fa-angle-double-left"
												aria-hidden="true"
										></i>
								</button>
								<input
										onChange={(e) => {
												const inputValue = e.target.value;
												const soloNumeros = Number(
														inputValue.replace(/[^0-9]/g, "")
												);
												setPaginaActual(soloNumeros);
										}}
										value={paginaActual}
										className={`${classNameInput} !w-[60px] text-center`}
								/>
								<span>
										de {Math.ceil(entidades.length / cantidadFilas)}
								</span>
								<button
										onClick={() =>
												setPaginaActual(
														paginaActual + 1 >
																Math.ceil(
																		entidades.length / cantidadFilas
																)
																? 1
																: paginaActual + 1
												)
										}
										className="border rounded h-[30px] px-2"
								>
										<i
												className="fa fa-angle-double-right"
												aria-hidden="true"
										></i>
								</button>
							</div>
						</div>
				}
			</div>

			<ModalCentrosPoblados
				isOpen={isOpenModalTracking}
				title={`${esRegistro ? 'Registrar' : 'Editar'} centro poblado ${nomEntidad ? `[${nomEntidad}]` : ''}`}
				closeModal={()=>closeModalTracking()}
			>
				<div className='flex lg:flex-col w-full h-full overflow-auto containerScroll'>
					<div className='lg:flex lg:h-[60px] bg-[#2B2C2E] py-1 px-2 w-[200px] lg:min-w-full lg:w-full flex-grow-0 flex-shrink-0 overflow-auto containerScroll' >
						<button className={`${estiloBoton} bg-gray-800  ${vistaInterior === 1 && '!bg-blue-700' } ${!esRegistro ? 'hover:bg-[#8dbe55] hover:scale-105' : ''}`}
							onClick={() => {
								setVistaInterior(1);
							}}
						>
							<span className='hidden lg:block'>1</span>
							<span className='lg:hidden'>Datos generales del Centro Poblado</span>
						</button>
						<button className={`${estiloBoton} bg-gray-700 ${vistaInterior === 2 && '!bg-blue-700' } ${esRegistro ? 'cursor-not-allowed' : 'hover:bg-[#8dbe55] hover:scale-105'}`}
							onClick={() => {
								!esRegistro && setVistaInterior(2)
							}}
						>
							<span className='hidden lg:block'>2</span>
							<span className='lg:hidden'>Características del Centro Poblado</span>
						</button>
						<button className={`${estiloBoton} bg-gray-600 ${vistaInterior === 3 && '!bg-blue-700' } ${esRegistro ? 'cursor-not-allowed' : 'hover:bg-[#8dbe55] hover:scale-105'}`}
							onClick={()=>{
								!esRegistro && setVistaInterior(3)
							}}
						>
							<span className='hidden lg:block'>3</span>
							<span className='lg:hidden'>Condiciones del Centro Poblado</span>
							
						</button>
						<button className={`${estiloBoton} bg-gray-500 ${vistaInterior === 4 && '!bg-blue-700' } ${esRegistro ? 'cursor-not-allowed' : 'hover:bg-[#8dbe55] hover:scale-105'}`} 
							onClick={()=> {
								!esRegistro && setVistaInterior(4);
							}}
						>
							<span className='hidden lg:block'>4</span>
							<span className='lg:hidden'>Equipamento del Centro Poblado</span>
							
						</button>
						<button className={`${estiloBoton} bg-gray-400 ${vistaInterior === 5 && '!bg-blue-700' } ${esRegistro ? 'cursor-not-allowed' : 'hover:bg-[#8dbe55] hover:scale-105'}`} 
							onClick={()=> {
								if(!esRegistro){
									setVistaInterior(5)
									funcionesDietas.listarDietasCP({
										codEntidad: codEntidad,
										anio: anioDieta
									})
									funcionesTransferencias.listarTransferenciasCP({
										codEntidad: codEntidad,
										anio: anioTransferencia
									})
								}
							}}
						>
							<span className='hidden lg:block'>5</span>
							<span className='lg:hidden'>Dietas y tranferencias</span>
							
						</button>
					</div>

					<div className='flex-grow h-full flex flex-col gap-2 overflow-hidden containerScroll'>
						{/* LEGALES */}
						<div className='flex w-full justify-between lg:flex-col gap-1'>
							<span className='italic font-bold lg:text-xs'>*Esta información tiene caracter de declaración jurada.</span>
							{ComponenteRegistroFinalizado()}
						</div>
						{/* INPUTS PARA EL REGISTRO */}
						<div className={`relative flex-grow w-full overflow-auto containerScroll ${!isOpenMapa ? 'flex' : 'hidden'}`}>
							<div
								onMouseEnter={() => setMuestraMensaje(true)} 
								onMouseLeave={() => setMuestraMensaje(false)} 
								className={`absolute top-0 bottom-0 w-full h-full z-20 bg-orange-600 opacity-5 cursor-not-allowed ${estaBloqueadoRegistro && !esRegistro ? '' : 'hidden'}`}
							></div>
							<div className='w-full h-full flex overflow-auto containerScroll p-2'>
								{ComponenteContenidoRegistro()}
							</div>
						</div>
						{/* VISTA DE MAPA */}
						<div className={`relative flex-grow flex flex-col w-full h-full ${isOpenMapa ? 'flex' : 'hidden'}`}>
							<div className='w-full bg-gray-600 flex items-center justify-between h-[35px]'>
								{
									poligonoJurisdiccionRegistro ?
									<div></div>
									:
									<button 
										onClick={() => agregarPoligonoEditable()}
										className='px-4 bg-blue-600 hover:bg-blue-800 h-full text-sm'
									>
										<div className='flex gap-2 items-center'>
											<i className="fa fa-plus" aria-hidden="true"></i>
											<span>Registrar coordenadas</span>
										</div>
									</button>
								}
								<button onClick={() => setIsOpenMapa(false)} className='px-4 bg-red-600 hover:scale-105 hover:bg-red-900 h-full'>x</button>
							</div>
							<div className='flex-grow' ref={divMapa}></div>
							{
								poligonoJurisdiccionRegistro ?
									<button onClick={registrarPoligono} className='absolute right-5 bottom-5 bg-orange-600 rounded py-2 px-4 hover:scale-105 hover:bg-orange-700'>Guardar<br/>coordenadas</button>
								: 	<></>
							}
						</div>
						{/* BOTONES INFERIORES */}
						<div className={`h-fit w-full flex justify-between`}>
							{
								esRegistro ?
									<div></div>
								:
									<div className='justify-start gap-4 flex lg:hidden'>
										<button 
											className='w-[150px] py-2 rounded bg-blue-600' 
											onClick={() => {
												setIsOpenMapa(!isOpenMapa)
												poligonoJurisdiccion.length == 0 && notify('El centro poblado no tiene una jurisdicción registrada','info')
											}}
										>
											{
												isOpenMapa ? 'Ocultar mapa' : 'Ver mapa'
											}
										</button>
									</div>
							}
							<div className={`justify-end gap-4 ${isOpenMapa ? 'hidden' : 'flex'} items-center lg:w-full`}>
								{
									estaBloqueadoRegistro && !esRegistro ?
										<>
											<div className='flex lg:flex-col lg:text-xs lg:w-full items-center gap-4 lg:gap-1'>
												<span className={`text-orange-600 lg:text-right italic font-bold ${muestraMensaje ? '' : 'hidden'}`}>Hacer click en el botón "Editar" para modificar la información.</span>
												<div className='flex gap-4 lg:w-full lg:justify-end'>
													<button className='w-[100px] py-2 rounded bg-orange-600' onClick={() => {setEstaBloqueadoRegistro(false)}}>Editar</button>
													<button className='w-[100px] py-2 rounded bg-gray-600' onClick={closeModalTracking}>Cerrar</button>
												</div>
											</div>
										</>
									:
										<>
											{/* LAS DIETAS Y TRANSFERENCIAS NO TIENEN BOTON GUARDAR */}
											{
												vistaInterior != 5 ?
													<button className='w-[100px] py-2 rounded bg-blue-600' onClick={funcionRegistroEdicionCentroPoblado}>Guardar</button>
												:	<></>

											}
											<button 
												className='w-[100px] py-2 rounded bg-gray-600' 
												onClick={() => {
													if(esRegistro){
														closeModalTracking()
													}else{
														setEstaBloqueadoRegistro(true)
														if(vistaInterior != 5){
															setDetalleCP({...copiaDetalleCP})		// RETORNA LOS DATOS ANTERIORES DEL CENTRO POBLADO CUANDO CANCELA EL REGISTRO
														}
													}
												}}
											>
												Cancelar
											</button>
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</ModalCentrosPoblados>
			
			<Modal
				isOpen={isOpenModalConfirmacion}
				closeModal={() => {
					closeModalConfirmacion()
					mesRango.tipo == 1 ? funcionesDietas.listarDietasCP({
						codEntidad: codEntidad,
						anio: anioDieta
					}) : funcionesTransferencias.listarTransferenciasCP({
						codEntidad: codEntidad,
						anio: anioTransferencia
					})
				}}
				title={`Registrar ${mesRango.tipo == 1 ? 'dieta' : 'transferencia'} [${meses[mesRango.mes-1]}]`}
				action={funcionRegistroDietaTransferencia}
			>
				<div className='grid grid-cols-2 items-center gap-4 lg:grid-cols-1'>
					<span>Fecha de recepción:</span>
					<DatePickerABX
						date={startDate}
						setDate={setStartDate}
					></DatePickerABX>
				</div>
			</Modal>


		</>
	)
}
