import React, { useState, useEffect, useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { primeraLetraMayuscula } from '../../../utils/utils';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
// import { useTipoUsuario } from '../../../hooks/usuarios/useTipoUsuario';
// import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';
// import { usePersonas } from '../../../hooks/personas/usePersonas';
import { useModal } from '../../../hooks/useModal';
import { useEntidades } from '../../../hooks/useEntidades';
import { UserContext } from '../../../context/provider/UserProvider';
import Select2Plamin from '../../components/forms/Select2Plamin';
import { useUsuariosIS } from '../../../hooks/personas/useUsuariosIS';
import { MultiSelector } from '../../components/forms/MultiSelector';



export const Usuarios = () => {

    const {
        usuarios,
        listarUsuarios,
        guardarEditarUsuario,
        usuario, 
        editarValorUsuario, 
        obtenerUsuario, 
        isOpenModal, 
        closeModal, 
        cargarDatosPersona,
        fotoUrlUsuario,
        setFotoFile,
        setFotoUrlUsuario,
        eliminarPersona, 
        isOpenModalEliminar, 
        closeModalEliminar, 
        usuarioEliminar,
        cargandoDNI,
        roles,
        asignarUsuario
    } = useUsuariosIS()


    const jsonPersonas = usuarios?.map((data,i) => ({
        // value: data?.uid?.split(",")[0]?.split("=")[1],
        value: data?.uid,
        label: data?.nombres + " " + data?.apellidoPaterno + " " + data?.apellidoMaterno  
    }))

    const {stateUser} = useContext(UserContext)

    const {entidades} = useEntidades()
    // const [isOpenEliminar, openModalEliminar, closeModalEliminar] = useModal()

    // const [ tipoUsuarios ] = useTipoUsuario();

    const [registrarEditar,setRegistrarEditar] = useState(true)

    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
    return (
        <>
            <ContenedorParametros
                titulo="Usuarios"
                tablaId='tabla-usuarios'
            >
                <div>
                    <BotonProcesar onClick={() => listarUsuarios()} ></BotonProcesar>
                </div>
                <div>
                    <BotonNuevo onClick={() => {obtenerUsuario();setRegistrarEditar(true)}}></BotonNuevo>
                </div>
            </ContenedorParametros>
                
            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-usuarios' className="table">
                    <thead>
                        <tr>
                            <th className="w-[40px] text-left">ID</th>
                            <th className='text-left'>UID</th>
                            <th>NOMBRE</th>
                            <th>ROL</th>
                            <th>FECHA CREACIÓN</th>
                            <th className='w-2'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {  
                            usuarios?.length > 0
                            ? usuarios?.map((u,i)=> {
                                return (
                                <tr key={i+1} >
                                    <td>{ i + 1 }</td>
                                    <td className="text-left">{u?.uid?.split(",")[0]?.split('=')[1]}</td>
                                    <td className="text-center">{primeraLetraMayuscula(u?.nombres)} {primeraLetraMayuscula(u?.apellidoPaterno)} {primeraLetraMayuscula(u?.apellidoMaterno)}</td>
                                    <td className="text-center">
                                        {u?.rol?.join(', ')}
                                    </td>
                                    <td className="text-center">{u?.fechaCreacion}</td>
                                    <td className="text-center">
                                        <button  onClick={() => {obtenerUsuario(u?.uid);setRegistrarEditar(false)}} className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    {/* <td>
                                        <button  onClick={() => {editarValorUsuario("codUsuario", usuario.codUsuario); openModalEliminar()}} className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td> */}
                                </tr>
                                )
                            })
                            : <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                        }
                        
                    </tbody>
                </table>
            </div>
            <Modal 
                isOpen={isOpenModal} closeModal={closeModal} action={()=> asignarUsuario()}
                title={`${registrarEditar ? 'Registrar usuario' : 'Editar usuario'}`}
                spinner={{}}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div className='lg:flex lg:flex-col'>
                                <label>Usuario: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <Select2Plamin
                                        options={jsonPersonas}
                                        funcionChange={(valor) => {
                                            editarValorUsuario("uid", valor)
                                        }}
                                        valorDefault={usuario.uid}
                                    ></Select2Plamin>
                                </div>
                            </div>
                            
                            <div className='lg:flex lg:flex-col'>
                                <label>Rol:</label>
                                <div className="flex items-center gap-4 w-[250px] flex-col">
                                    <div className='w-full'>
                                        <MultiSelector
                                            opciones={
                                                roles.map((e) => {
                                                    return {
                                                        texto: e.nomRol.split('-')[1],
                                                        valor: e.nomRol
                                                    }
                                                })
                                            }
                                            value={usuario.rol || []}
                                            setValue={(valoresCambios) => editarValorUsuario('rol',valoresCambios || [])}
                                        ></MultiSelector>
                                    </div>
                                    {/* <select className={classNameInput} defaultValue={usuario.rol[0]} onChange={(e) => editarValorUsuario("rol", e.target.value)} >
                                        <option value={0}>--Seleccione--</option>
                                        {
                                            roles.map(r => {
                                                return <option key={r.nomRol} value={r.nomRol}>{r.nomRol.split("-")[1]}</option>
                                            })
                                        }
                                    </select> */}
                                </div>
                            </div>

                            <div className='lg:flex lg:flex-col'>
                                <label>Nombre Usuario:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input  className={`${classNameInput} caret-input`} autoComplete='false' defaultValue={usuario.uid} onChange={(e) => editarValorUsuario("uid", e.target.value)} disabled />
                                </div>
                            </div>

                            <div className={`${registrarEditar} lg:flex lg:flex-col`}>
                                <label>Clave Usuario:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input  type={'password'} className={`${classNameInput} caret-input`} autoComplete='false' defaultValue={usuario.clave} onChange={(e) => editarValorUsuario("clave",e.target.value)}/>
                                </div>
                            </div>

                            {/* <div className='' >
                                <label>Entidad:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                <select className={classNameInput} defaultValue={usuario.codEntidad} onChange={(e) => editarValorUsuario("codComisaria", e.target.value)} >
                                        <option value={0}>--Seleccione--</option>
                                        {
                                            entidades.map((entidad,i) => {
                                                return <option key={i+1} value={entidad.codEntidad}>{entidad.nomEntidad}</option>
                                            })
                                        }
                                </select>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <Modal
                isOpen={isOpenEliminar} closeModal={closeModalEliminar} action={()=> eliminarUsuario()}
                title="¿Seguro que desea Eliminar el usuario?"
                spinner={spinner}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div>
                                <label>Motivo de la elimación:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input className={`${classNameInput} caret-input`} defaultValue={usuario.motivoEliminacion} onChange={(e) => editarValorUsuario("motivoEliminacion", e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
            
        </>
    )
}