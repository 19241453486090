import { useEffect, useState } from 'react';
import { notify } from '../utils/utils';
import { AuthFetch } from '../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useDetalleEntidades = () => {
    const [ dispersion, setDispersion ] = useState([]);
    const [ transporte, setTransporte ] = useState([]);
    const [ aguaPotable, setAguaPotable ] = useState([]);
    const [ drenaje, setDrenaje ] = useState([]);
    const [ conectividad, setConectividad ] = useState([]);
    const [ electricidad, setElectricidad ] = useState([]);
    const [ categorias, setCategorias ] = useState();



    useEffect(()=>{
        listarDispersion()
        listarTransporte()
        listarAguaPotable()
        listarDrenaje()
        listarConectividad()
        listarElectricidad()
        listarCategorias()
    },[])

    const listarDispersion = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/dispersion'
        });

        if (response.isValid) {
            setDispersion(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const listarTransporte = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/transport'
        });

        if (response.isValid) {
            setTransporte(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const listarAguaPotable = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/drinkingWater'
        });

        if (response.isValid) {
            setAguaPotable(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const listarCategorias = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/categories'
        });

        if (response.isValid) {
            setCategorias(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const listarDrenaje = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/drain'
        });

        if (response.isValid) {
            setDrenaje(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const listarConectividad = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/connectivity'
        });

        if (response.isValid) {
            setConectividad(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const listarElectricidad = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/electricity'
        });

        if (response.isValid) {
            setElectricidad(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    return {
        dispersion,
        transporte,
        aguaPotable,
        drenaje,
        conectividad,
        electricidad,
        categorias
     }
}