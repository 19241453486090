import { useState } from 'react';
import { notify } from '../../utils/utils';
import { AuthFetch } from '../../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api/PoliceStation";

export const useComisarias = () => {
    const [ comisarias, setComisarias ] = useState([]);
    const [ comisariasJurisdiccion, setComisariasJurisdiccion ] = useState([]);
    
    const obtenerComisarias = async () => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin 
        });

        if (respuesta.isValid) {
            setComisarias(d => respuesta.content);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const obtenerComisariasSinJurisdiccion = async (comisariasExtras = []) => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/jurisdiccion'
        });
        
        if (respuesta.isValid) {
            setComisariasJurisdiccion(d => {  
                comisariasExtras = comisariasExtras.map(ce => ({
                    ...ce,
                    latitudComisaria: '',
                    longitudComisaria: '',
                    checked: true
                }))
                
                return [
                    ...comisariasExtras,
                    ...respuesta.content
                ]
            });
        } else {
            notify(respuesta.content, 'error');
        }
    }

    return {
        comisarias,
        obtenerComisarias,
        comisariasJurisdiccion,
        obtenerComisariasSinJurisdiccion
    }
}