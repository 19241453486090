import { useState, useEffect, useContext } from 'react';
import { notify } from '../../utils/utils';
import { useModal } from '../.././hooks/useModal';
import fotoDefault from '../../../src/assets/images/usuarioDefault.jpg';
import { AuthFetch } from '../../services/api';
import { UserContext } from '../../context/provider/UserProvider';

const personaEmpadronadaDefault = {
    codPersonaEmpadronada:0,
    nomPersonaEmpadronada: "",
    apePatPersonaEmpadronada: "",
    apeMatPersonaEmpadronada: "",
    codDocumentoTipo: 0,
    nroDocumento: "",
    fechaNacimiento: "",
    codGenero: 0,
    codEstadoCivil: 0,
    codNivelEducativo: 0,
    codDepartamentoNacimiento: 0,
    codProvinciaNacimiento: 0,
    codDistritoNacimiento: 0,
    motivoMigracion: "",
    codIdiomaNatal: 0,
    codReligion: 0,
    accesoCentroSalud: "",
    accesoCentroEducativo: "",
    codEntidadEmpadronada: 0,
    codUsuarioAccion: 0,
    urlFoto:"",
}

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const usePobladores = () => {
    const [ personasEmpadronas, setPersonaEmpadronadas ] = useState([]);
    const [ personaEmpadronada, setPersonaEmpadronada ] = useState(personaEmpadronadaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();
    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlPersona, setFotoUrlPersona ] = useState(fotoDefault);
    const {stateUser} = useContext(UserContext)
    const [ cargandoDatos, setCargandoDatos ] = useState(false)    
    
    useEffect(() => {
        listarPersonasEmpadronadas();
    }, []);

    useEffect(()=>{
        if (personaEmpadronada.nroDocumento < 8){
            setPersonaEmpadronada(personaEmpadronadaDefault)
        }
    },[personaEmpadronada.nroDocumento])

    const cargarDatosPersonaEmpadronada = async (DNI) => {
        setCargandoDatos(true)
        if (DNI.length === 8 ){
            const response = await AuthFetch({
                url: `${urlBasePlamin}/Person/dataByDNI?dni=${DNI}`
            });
            if (response.isValid) {
                notify('Se encontró información','info')
                setPersonaEmpadronada({
                    codPersonaEmpadronada: 0,
                    nomPersonaEmpadronada: response.content.nombres,
                    apePatPersonaEmpadronada: response.content.apellidoPaterno,
                    apeMatPersonaEmpadronada: response.content.apellidoMaterno,
                    telefonoPersonaEmpadronada: personaEmpadronada.telefonoPersonaEmpadronada,
                    codDocumentoTipo: 1,
                    nroDocumento: response.content.dni,
                })
            } else {
                notify('No se encontró información','info')
                setPersonaEmpadronada(personaEmpadronadaDefault)
            }
        }else{
            setPersonaEmpadronada(personaEmpadronadaDefault)
        }    
        setCargandoDatos(false)   
    }

    const listarPersonasEmpadronadas = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/registeredPersons?CodEntidadEmpadronada=' + (stateUser.codEntidad),
        });
        
        if (response.isValid) {
            setPersonaEmpadronadas(response.content);
        } else {
            notify(response.exceptions[0].description, 'error');
        }
    }

    const obtenerPersonaEmpadronada = async (codPersona) => {
        if (codPersona) {
            const response = await AuthFetch({
                url: urlBasePlamin + '/Entities/registeredPerson/' + codPersona
            });

            if (response.isValid) {
                setPersonaEmpadronada(response.content);
                // verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.exceptions[0].description, 'error');
            }
        } else {
            setPersonaEmpadronada(personaEmpadronadaDefault);
            setFotoUrlPersona(fotoDefault)
        }
        openModal();
    }

    const personaEmpadronadaEliminar = async (codPersonaEmpadronada) =>{
        if (codPersonaEmpadronada) {
            const response = await AuthFetch({
                url: urlBasePlamin + '/Personas/' + codPersonaEmpadronada
            });

            if (response.isValid) {
                setPersonaEmpadronada(response.content);
                verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setPersonaEmpadronada(personaEmpadronadaDefault);
            setFotoUrlPersona(fotoDefault)
        }
        openModalEliminar();

    }

    const eliminarPersonaEmpadronada = async (codPersonaEmpadronada)=>{
        const response = await AuthFetch({
            url: urlBasePlamin + '/Personas/eliminarPersona?' + new URLSearchParams({
                CodPersona: codPersonaEmpadronada
            }),
            method:'PUT'
        })
        if (response.isValid){
            listarPersonasEmpadronadas()
            notify(response.content, 'success');
            closeModalEliminar()
        }else{
            notify(response.exceptions[0].description, 'error');
        }
    }
    
    const guardarEditarPersonaEmpadronada = async () => {
        const esGuardar = personaEmpadronada.codPersonaEmpadronada <= 0;
        const response = await AuthFetch({
            url: urlBasePlamin + '/Entities/registeredPersons',
            method: esGuardar ? 'POST' : 'PUT', 
            body: JSON.stringify({
                ...(!esGuardar && { codUsuario: personaEmpadronada.codPersonaEmpadronada }),
                nomPersonaEmpadronada:personaEmpadronada.nomPersonaEmpadronada,
                apePatPersonaEmpadronada: personaEmpadronada.apePatPersonaEmpadronada,
                apeMatPersonaEmpadronada:personaEmpadronada.apeMatPersonaEmpadronada,
                codDocumentoTipo: personaEmpadronada.codDocumentoTipo,
                nroDocumento:personaEmpadronada.nroDocumento,
                fechaNacimiento: personaEmpadronada.fechaNacimiento,
                codGenero: personaEmpadronada.codGenero,
                codEstadoCivil: personaEmpadronada.codEstadoCivil,
                codNivelEducativo: personaEmpadronada.codNivelEducativo,
                codDepartamentoNacimiento: personaEmpadronada.codDepartamentoNacimiento,
                codProvinciaNacimiento: personaEmpadronada.codProvinciaNacimiento,
                codDistritoNacimiento: personaEmpadronada.codDistritoNacimiento,
                motivoMigracion: personaEmpadronada.motivoMigracion,
                codIdiomaNatal: personaEmpadronada.codIdiomaNatal,
                codReligion: personaEmpadronada.codReligion,
                accesoCentroSalud: personaEmpadronada.accesoCentroSalud,
                accesoCentroEducativo: personaEmpadronada.accesoCentroEducativo,
                codEntidadEmpadronada: stateUser.codEntidad,
                codUsuarioAccion: stateUser.codUsuario,
                urlFoto: personaEmpadronada.urlFoto
            })
        });
        // notify(response.content,(response.isValid ? 'success' : 'error'));
        if (response.isValid){
            notify(response.content, 'success');
        }else{
            notify(response.exceptions[0].description, 'error');
        }
    }

    const editarValorPersonaEmpadronada = (key, value) => {
        setPersonaEmpadronada(personaEmpadronada => {
            return {
                ...personaEmpadronada,
                [key]: value
            }
        });
    }

    const verFotoUrlPersona = (nomCarpeta,nombreFoto) => {
        if (nombreFoto === ''){
            setFotoUrlPersona(fotoDefault); 
        }else {
            setFotoUrlPersona(url => urlBasePlamin + `/Minio?nombreCarpetaFoto=${nomCarpeta}&nombreFoto=${nombreFoto}`);
        }
    }

    return {
            personasEmpadronas,
            listarPersonasEmpadronadas,
            guardarEditarPersonaEmpadronada,
            personaEmpadronada, 
            editarValorPersonaEmpadronada, 
            obtenerPersonaEmpadronada, 
            isOpenModal, 
            closeModal, 
            cargarDatosPersonaEmpadronada,
            fotoUrlPersona,
            setFotoFile,
            setFotoUrlPersona,
            eliminarPersonaEmpadronada, 
            isOpenModalEliminar, 
            closeModalEliminar, 
            personaEmpadronadaEliminar,
            cargandoDatos 
    }
}