import React, { useState, useEffect } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { useTipoUsuario } from '../../../hooks/usuarios/useTipoUsuario';
import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';
// import { usePersonas } from '../../../hooks/personas/usePersonas';
import { useUsuariosIS } from '../../../hooks/personas/useUsuariosIS';
import { useModal } from '../../../hooks/useModal';

export const Documentos = () => {
    const { usuarios, listarUsuarios, guardarEditarUsuario,usuario,editarValorUsuario,obtenerUsuario, spinner, isOpen, closeModal,eliminarUsuario } = useUsuarios();
    const { personas, listarPersonas}= useUsuariosIS()
    const [isOpenEliminar, openModalEliminar, closeModalEliminar] = useModal()

    useEffect (()=>{
    listarPersonas()
    
    },[]) 
    const [ tipoUsuarios ] = useTipoUsuario();

    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
    return (
        <>
            <ContenedorParametros
                titulo="Usuarios"
            >
                <div>
                    <BotonProcesar onClick={() => listarUsuarios()} ></BotonProcesar>
                </div>
                <div>
                    <BotonNuevo onClick={() => obtenerUsuario()}></BotonNuevo>
                </div>
            </ContenedorParametros>
                
            <div className="mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table className="table">
                    <thead>
                        <tr>

                            <th className="w-[30px]">ID</th>
                            <th>NOMBRE</th>
                            <th>TIPO</th>
                            <th>FECHA CREACIÓN</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {  
                            usuarios.length > 0
                            ? usuarios.map((usuario,i)=> {
                                return (
                            <tr key={usuario.codUsuario} >
                                <td>{ i + 1 }</td>
                                <td className="text-center">{usuario.nomUsuario}</td>
                                <td className="text-center">{usuario.nomUsuarioTipo}</td>
                                <td className="text-center">{usuario.fechaCreacion}</td>
                                <td className="text-center">
                                    <button  onClick={() => obtenerUsuario(usuario.codUsuario)} className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </td>
                                <td>
                                    <button  onClick={() => {editarValorUsuario("codUsuario", usuario.codUsuario); openModalEliminar()}} className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>

                                )
                            })
                            : <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                        }
                        
                    </tbody>
                </table>
            </div>
            <Modal 
                isOpen={isOpen} closeModal={closeModal} action={()=> guardarEditarUsuario()}
                title="Registrar nuevo usuario"
                spinner={spinner}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div>
                                <label>Nombre: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select  className={classNameInput}   defaultValue={usuario.codPersona} onChange={(e) => editarValorUsuario("codPersona", e.target.value)} >
                                        <option value={0}>--Seleccione--</option>
                                        {
                                            personas.map(p => {
                                                return <option key={p.codPersona} value={p.codPersona}>{p.nomPersona}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label>Tipo:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={classNameInput} defaultValue={usuario.codUsuarioTipo} onChange={(e) => editarValorUsuario("codUsuarioTipo", e.target.value)} >
                                        <option value={0}>--Seleccione--</option>
                                        {
                                            tipoUsuarios.map(tipoUsuario => {
                                                return <option key={tipoUsuario.codUsuarioTipo} value={tipoUsuario.codUsuarioTipo}>{tipoUsuario.nomUsuarioTipo}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label>Nombre Usuario:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input className={`${classNameInput} caret-input`} autoComplete='false' defaultValue={usuario.nomUsuario} onChange={(e) => editarValorUsuario("nomUsuario", e.target.value)} />
                                </div>
                            </div>
                            <div>
                                <label>Clave Usuario:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input type={'password'} className={`${classNameInput} caret-input`} autoComplete='false' defaultValue={usuario.claveUsuario} onChange={(e) => editarValorUsuario("claveUsuario",e.target.value)}/>
                                </div>
                            </div>
                            {/* <div className=''style={{display:'none'}}  >
                                <label>Entidad:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                <select className={classNameInput}   defaultValue={usuario.codComisaria} onChange={(e) => editarValorUsuario("codComisaria", e.target.value)} >
                                        <option value={0}>--Seleccione--</option>
                                        {
                                            entidades.map(entidad => {
                                                return <option key={entidad.codComisaria} value={entidad.codComisaria}>{entidad.nomComisaria}</option>
                                            })
                                        }
                                </select>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isOpenEliminar} closeModal={closeModalEliminar} action={()=> eliminarUsuario()}
                title="¿Seguro que desea Eliminar el usuario?"
                spinner={spinner}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[450px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">
                            <div>
                                <label>Motivo de la elimación:</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input className={`${classNameInput} caret-input`} defaultValue={usuario.motivoEliminacion} onChange={(e) => editarValorUsuario("motivoEliminacion", e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            
        </>
    )
}