import React, { useEffect, useState } from 'react'

const useIsMobile = () => {
  const [isMobile,setIsMobile] = useState(window.innerWidth <= 768)
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia el estado si es menor o igual a 768px
    };

    window.addEventListener('resize', handleResize); // Escucha cambios en el tamaño
    return () => window.removeEventListener('resize', handleResize); // Cleanup del evento
  }, []);

  return isMobile
}

export default useIsMobile