import React, { useEffect, useState } from 'react';
import Select,{components } from 'react-select';


const SelectorMultiple = (props) => {

  // const [valuesDefault,setValuesDefault] = useState([])
  
  
  const ValueContainer = ({ children, ...props }) => {
    const optionsCount = props.getValue().length;
    return (
      <components.ValueContainer {...props} className='text-sm text-[#9ca3af]'>
        {optionsCount === 0 ? 'Elegir Alertas' : `${optionsCount} seleccionada(s)`}
      </components.ValueContainer>
    );
  };


  const customStyles = {
    input: (provided) => ({
        ...provided,
        // marginTop:'-7px',
        color:'white',
        fontSize:'13px',
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: '5px',
        width:'250px',
        border:'1px solid #27272a',
        // height:  '30px',
        maxHeight: '20px',
        backgroundColor: 'rgba(39,39,42,255)',
    }),
    placeholder:(provided)=>({
        color:'gray',
        marginTop: '-30px'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        cursor:'pointer'
        // marginTop:'-10px'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    option: (provided,state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgba(30,144,255,255)' : 'rgba(39,39,42,255)',
        color: 'white',
        cursor:'pointer',
        fontSize:'12px',
        '&:hover': {
            backgroundColor: 'rgba(30,144,255,255)',
        },
    }),
    menuPortal: (provided) => ({
        ...provided,
        // zIndex: 1000000,
    }),
    menu: () => ({
        backgroundColor:'rgba(39,39,42,255)',
        position:'absolute',
        width: '250px'
    }),
    
  };

  return (
      <Select
        isMulti
        options={props.options || []}
        styles={customStyles}
        className='containerScroll'
        defaultValue={props.valoresXDefecto}    //codigo que genera el error
        onChange={(e) => {
          props.OpcionesSeleccionadas(e)
        }}
        closeMenuOnSelect={false}
        components={{ ValueContainer }}
      ></Select>
  );
};

export default SelectorMultiple;
