import React from 'react';
import { useState } from 'react';
import {Link, NavLink,useHistory } from "react-router-dom";

    



export const Footer = () => {
// const items = useRef();


// const linkSubMenu = li[i].parentNode.querySelectorAll('.li-a-submenu')

const items = document.querySelectorAll('.item__link');
	items.forEach(item => {
		item.addEventListener('click', e => {
			document.querySelector('.item__link--active').classList.remove('item__link--active'); 
			item.classList.add('item__link--active');
		})
	})
    const history = useHistory();

    const verIncidencias= ()=>{
        history.push('/monitoreo-incidentes')
    }
    const verDivisionPolitica = ()=>{
        history.push('/division-politica')
    }
    const verComisarias = ()=>{
        history.push('/registros-catastro-comisarias')
    }



    return (
        <div className="footer">
            <footer>
            </footer>
        </div>
    )
}



