import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import { BotonesEstado } from '../../components/pages/incidentes/BotonesEstado';
import { SeguimientoAlerta } from '../../components/pages/incidentes/SeguimientoAlerta';
import { MapaAlertas } from '../../components/pages/incidentes/MapaAlertas';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SocketContext } from '../../../context/provider/SocketProvider';
import { notify } from '../../../utils/utils';
import { GoogleMap } from '../../../utils/googlemaps';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../.././../hooks/useModal';
import sonidoAlerta  from '../../../assets/sound/sonidoAlerta.mp3';
import { AuthFetch } from '../../../services/api';
import logo from '../../../assets/images/PlaminLogo.png'
import icono from '../../../assets/images/logo192.png'
import iconoPolicia from '../../../assets/images/iconoPolicia.png'

import { Tooltip } from '../../components/utils/Tooltip';
import ReactTooltip from 'react-tooltip';

import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';
import { useSeguimientoAlertas } from '../../../hooks/Incidentes/useSeguimientoAlertas';
import { useCalificacionAlerta } from '../../../hooks/Incidentes/useCalificacionAlerta';
import iconVerAgentes from '../../../assets/images/iconosVistaAgentes/Ubicar Agentes.fw.png'
import iconOcultarAgentes from '../../../assets/images/iconosVistaAgentes/ocultar Agente.fw.png'

import '../../../assets/css/components/googleMapsTest.css'

import gifAlerta from '../../../assets/images/gifalertaNueva.gif'
import { ModalAsignacion } from '../../components/modal/ModalAsignacion';

let marcadorAlerta = null
let marcadorPolicia = null

const google = window.google;
const googleMap = new GoogleMap();
const infowindow = new google.maps.InfoWindow();

const urlPlaminApi = process.env.REACT_APP_PLAMIN_API + '/api';

const incidentesTipo = {
    LISTAR_INCIDENTES: 'LISTAR_INCIDENTES',
    OBTENER_INCIDENTE: 'OBTENER_INCIDENTE',
    CAMBIAR_ESTADO: 'CAMBIAR_ESTADO',
}

const incidentesInicial = {
    incidentes: [],
    incidente: null,
    estadoActual: 9,
    googleMap: new GoogleMap()
}

const incidentesReducer = (state, action) => {
    switch (action.type) {
        case incidentesTipo.LISTAR_INCIDENTES:
            return {
                ...state,
                incidentes: action.payload
            }
        case incidentesTipo.OBTENER_INCIDENTE:
            return {
                ...state,
                incidente: action.payload
            }
        case incidentesTipo.CAMBIAR_ESTADO:
            return {
                ...state,
                estadoActual: action.payload.codEstado
            }
        default:
            throw new Error(`Invalid action type`);
    }
}

const useIncidentes = () => {
    const [ stateIncidentes, dispatchIncidentes ] = useReducer(incidentesReducer, incidentesInicial);
    const {stateUser, signOut} = useContext(UserContext)

    const [positionNuevaIncidencia,setPositionNuevaIncidencia] = useState({})

    marcadorPolicia = null
    
    const listarIncidentes = async (codEstado) => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/alerts?' + new URLSearchParams({
                CodEstado: codEstado || 0,
            })
        });

        if (response.isValid) {
            dispatchIncidentes({ type: incidentesTipo.LISTAR_INCIDENTES, payload: response.content });
        }

        dispatchIncidentes({ type: incidentesTipo.CAMBIAR_ESTADO, payload: { codEstado: codEstado || 0 } });
    }
    
    const ubicarIncidencia = (incidencia,esNuevaIncidencia) => {
        !esNuevaIncidencia && stateIncidentes.googleMap.quitarTodosLosMarcadores()

        marcadorAlerta = stateIncidentes.googleMap.crearMarcador({
            animation: esNuevaIncidencia ? true : false,    //la animacion solo cuando es una incidencia reciente
            visible: false,
        });

        let marcadorTest = stateIncidentes.googleMap.crearMarcadorGif({
            icon: gifAlerta,
            latLng: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud}
        })

        marcadorAlerta.setOptions({
            position: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            visible: true
        })

        if(esNuevaIncidencia){
            setPositionNuevaIncidencia([{
                lat: incidencia.latitud,
                lng: incidencia.longitud
            }])
        }else{
            setPositionNuevaIncidencia([])
        }

        marcadorAlerta = stateIncidentes.googleMap.marcadores[0]

        stateIncidentes.googleMap.map.setOptions({
            center: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            zoom: 15
        })
    }

    return { stateIncidentes, dispatchIncidentes, listarIncidentes, ubicarIncidencia, stateUser , positionNuevaIncidencia};
}

const useFotos = () => {
    const [ fotoUrl, setFotoUrl ] = useState();
    const [ isOpenFoto, openModalFoto, closeModalFoto ] = useModal(false); 

    const verFoto = (nomCarpeta,nombreFoto) => {
        setFotoUrl(url =>`https://minio.api.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=${nomCarpeta}&NombreImagen=${nombreFoto}`);
        openModalFoto();
    }

    return [ fotoUrl, verFoto, isOpenFoto, closeModalFoto ] 
}


export const Incidentes = () => {
    const classNameInput = "containerScroll h-[30px] text-white rounded px-4 w-[250px] lg:w-[200px] bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
    const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";
    
    const { 
        usuarios, 
        listarUsuarios,
        listarAgentesTotales,
        agentesTotales,
        listarAgentesCercanos,
        agentesCercanos,
    } = useUsuarios()
    const { 
            stateIncidentes,
            dispatchIncidentes, 
            listarIncidentes, 
            ubicarIncidencia, 
            stateUser,
            positionNuevaIncidencia
    } = useIncidentes();
    const { 
        seguimientoAlertas, 
        listarSeguimientoAlerta,
        guardarSeguimientoAlerta,
        seguimientoAlerta,
        editarValorSeguimientoAlerta,
        isOpenModalSeguimiento,
        openModalSeguimiento,
        closeModalSeguimiento,
        eliminarSeguimiento,
        finalizarAlerta
    } = useSeguimientoAlertas()

    const { stateSocket } = useContext(SocketContext);
    const [ isOpenAsignacion, openModalAsignacion,closeModalAsignacion ] = useModal(false); 
    const [ fotoUrl, verFoto, isOpenFoto, closeModalFoto ] = useFotos();
    const {calificaciones } = useCalificacionAlerta()

    const audioAlerta = useRef(null);

    const [jurisdiccionMapa,setJurisdiccionMapa] = useState('')
    const [valueRender,setValueRender] = useState(false)
    const [valorSelEstado,setValorSelEstado] = useState(9)
    const [incidenteActual,setIncidenteActual] = useState({})
    const [verAgentes,setVerAgentes] = useState(false)
    const [codTipoAlerta,setCodTipoAlerta] = useState(0)
    const [codEstadoAlerta,setCodEstadoAlerta] = useState(0)
    const [codAlerta,setCodAlerta] = useState(0)
    const [ mostrarUbicacionUsuario, setMostrarUbicacionUsuario ] = useState(false)
    const [ usuarioPosteo, setUsuarioPosteo ] = useState()
    const [mostrarEfectoOndas,setMostrarEfectoOndas] = useState(false)
    const [waveRadius, setWaveRadius] = useState(200);
    const [rotacion, setRotacion] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    const showNotification = async (contentAlert) => {
        const registration = await navigator.serviceWorker.getRegistration()
        if( ! registration ) return console.log("No hay un Service Worker Dash")
    
        registration.showNotification("¡ALERTA!", {
            body: contentAlert.mensajeAlerta || 'Incidente Reportado',
            image: `https://api.plaminoficial.com/api/Minio?nombreCarpetaFoto=${contentAlert.nombreCarpeta}&nombreFoto=${contentAlert.imagenAlerta}`,
            icon: icono,
            data: {
                customData: contentAlert.mensajeAlerta || "Alerta"
            },
        })
    }

    const mostrarUbicacionAgente = async (uidPosteo,centrarUbi)  => {
        if(!uidPosteo){
            return
        }
        stateIncidentes.googleMap.quitarTodosLosTramos()

        const response = await AuthFetch({
            url: urlBasePlamin + '/user/post?'  + new URLSearchParams({
                uid: uidPosteo || usuarioPosteo,
            }),
        })
        const posteo = response.content

        if((posteo?.latitudUltima == 0 && posteo?.longitudUltima == 0)){
            notify('El agente no posee ubicacion registrada','error')
            stateIncidentes.googleMap.quitarTodosLosMarcadoresExcepto(marcadorAlerta)
            stateIncidentes.googleMap.infoWindow && stateIncidentes.googleMap.infoWindow.close()
            setMostrarUbicacionUsuario(false)
            marcadorPolicia = null
            return
        }
        
        stateIncidentes.googleMap.quitarMarcadorEspecifico(marcadorPolicia)
        
        marcadorPolicia = stateIncidentes.googleMap.crearMarcador({
            animation: false,
            visible: true,
            icon: iconoPolicia,
            latLng: {lat: posteo?.latitudUltima , lng: posteo?.longitudUltima},
        });

        stateIncidentes.googleMap.crearRutaEntreMarcadores(marcadorAlerta,marcadorPolicia)

        if(centrarUbi){
            stateIncidentes.googleMap.map.setOptions({
                center: {lat: posteo.latitudUltima , lng: posteo.longitudUltima},
                zoom: 15
            })
        }
    }

    const asignarUsuarioAlerta = async (uidAsignado,codAlerta,tipoAlerta) => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Alerts/assignUser',
            method: 'POST',
            body: JSON.stringify({
                "codAlerta": codAlerta,
                "uidAsignado": uidAsignado
            })
        })
        notify(response.content,response.isValid ? "success" : "error")
        
        const response2 = await AuthFetch({
            url: urlBasePlamin + '/Alerts/sendAgentAlert',
            method: 'POST',
            body: JSON.stringify({
                "uid": uidAsignado,
                "codAlerta": codAlerta,
                "nomAlerta": tipoAlerta
            })
        })
        notify(response2.content,response2.isValid ? "success" : "error")

        if(response2.isValid){
            setUsuarioPosteo(uidAsignado)
            mostrarUbicacionAgente(uidAsignado,true)
            setMostrarUbicacionUsuario(true)
            listarIncidentes(9)
            closeModalAsignacion()
        }
    }

    const mostrarAgentesTotales = async (codEntidad) => {
        stateIncidentes.googleMap.quitarTodosLosTramos()
        stateIncidentes.googleMap.marcadores.map((data) => {
            if(data != marcadorAlerta){
                data.setMap(null)
            }
        })

        const response = await AuthFetch({
            url: urlBasePlamin + '/User/agentsTotal?' + new URLSearchParams({
                codEntidad: codEntidad || 0,
            }),
            method: 'GET',
        })

        response.content.map((data,i) => {
            const marcadorPoliciaV2 = stateIncidentes.googleMap.crearMarcador({
                animation: false,
                visible: true,
                icon: iconoPolicia,
                latLng: {lat: data.latitudUltima , lng: data.longitudUltima},
            });

            //MOSTRAR INFO WINDOW
            const infoWindow = googleMap.crearInfowindow({});
            marcadorPoliciaV2.addListener('click', () => {
                infoWindow.setContent(`
                    <div style="">
                        <div style="padding:5px">Nombre: ${data.nomPersona}</div>
                        <div style="padding:5px">Tipo Usuario: ${data.nomUsuarioTipo}</div>
                        <div style="padding:5px">F. Ult. Ubicacion: ${data.fechaUltimaUbicacion}</div>
                    </div>`
                );
    
                infoWindow.open({
                    anchor: marcadorPoliciaV2,
                    map: googleMap.map
                })
            })
        })
    }

    const toggleRotacion = () => {
      // Incrementa el estado de rotación en 90 grados con cada clic
        setRotacion((rotacion + 90) % 360);
    };

    const toggleZoom = () => {
        if (zoom === 1) {
            setZoom(1.2);
        } else if (zoom === 1.2) {
            setZoom(1.5);
        }else if (zoom ===1.5){
            setZoom(1.8);
        }else if (zoom ===1.8){
            setZoom(2.0);
        }else if (zoom ===2.0){
            setZoom(2.5);
        }else if (zoom ===2.5){
            setZoom(3.0);
        } else {
            setZoom(1);
        }
    };
    
    const handleMouseDown = (e) => {
        if (e.button === 2) {
            setRotacion(0);
            setZoom(1);
            setDragOffset({ x: 0, y: 0 });
        } else {
            setIsDragging(true);
            setDragStart({ x: e.clientX, y: e.clientY });
        }
    };

    const handleDoubleClick = () => {
        setRotacion(0);
        setZoom(1);
        setDragOffset({ x: 0, y: 0 });
    };
    
    const handleMouseMove = (e) => {
        if (isDragging) {
            const x = e.clientX - dragStart.x + dragOffset.x;
            const y = e.clientY - dragStart.y + dragOffset.y;
            setDragOffset({ x, y });
        }
    };
    
    const handleMouseUp = () => {
        setIsDragging(false);
        setDragStart({ x: 0, y: 0 });
    };

    const obtenerJurisdiccionPoligono = async () => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/Entities/jurisdictionByEntity?' + new URLSearchParams({
                codEntidad: stateUser.codEntidad || 0
            })
        });

        const completelistLatLng = response.content[0].poligonoJurisdiccion.split(',').map(latLng => {
            const [ lat, lng ] = latLng.split(';');
            return { lat: Number(lat), lng: Number(lng) };
        })

        const juriTotal = new google.maps.Polygon({
            paths: completelistLatLng,
            visible:false
        });

        setJurisdiccionMapa(juriTotal)

    }

    useEffect(() =>{
        // listarUsuarios()
        setValueRender(true)
        obtenerJurisdiccionPoligono()
    },[])
    
    useEffect(() => {
        if(valueRender){
            ReactTooltip.rebuild()  //para solucionar el tooltip de la tabla de incidentes
        }
    },[stateIncidentes.incidentes])

    useEffect(() => {
        if(valueRender){
            if(!isOpenModalSeguimiento){
                listarIncidentes(valorSelEstado)
            }
        }
    },[isOpenModalSeguimiento])

    useEffect(() => {
        stateIncidentes.googleMap.infoWindow && stateIncidentes.googleMap.infoWindow.close()
        stateIncidentes.googleMap.quitarTodosLosMarcadoresExcepto(marcadorAlerta)
        stateIncidentes.googleMap.quitarTodosLosTramos()
        setMostrarUbicacionUsuario(false)
        
        if(verAgentes){
            mostrarAgentesTotales(stateUser.codEntidad)
        }
    },[verAgentes])

    useEffect(() => {
        const timer = setInterval(() => {
            if (mostrarUbicacionUsuario){
                mostrarUbicacionAgente(usuarioPosteo,false)
            }
        }, 5000)
        if(!mostrarUbicacionUsuario){
            clearInterval(timer)
        }
        return () => {
            clearInterval(timer)
        }
    }, [mostrarUbicacionUsuario])

    useEffect(() => {
        if(isOpenAsignacion){
            
        }
    },[isOpenAsignacion])

    useEffect(() => {
        if(valueRender){
            if(positionNuevaIncidencia.length != 0){
                setMostrarEfectoOndas(true)
            }else{
                setMostrarEfectoOndas(false)
            }
        }
    },[positionNuevaIncidencia])

    useEffect(() => {
        const timer = setInterval(() => {
            setMostrarEfectoOndas(false)
        },5000)
        
        const interval = setInterval(() => {
            if(mostrarEfectoOndas){
                setWaveRadius((prevRadius) => (prevRadius + 50) % 300);
            }
        }, 200);
        
        if(!mostrarEfectoOndas){    //limpia el timer cuando mostrarefectoondas es false
            clearInterval(interval)
            stateIncidentes.googleMap.circulos.map((data,i) => {
                data.setMap(null)
            })
        }
        
        return () => {
            clearInterval(timer)
            clearInterval(interval)
        }
    }, [mostrarEfectoOndas]);

    useEffect(() => {
        if(mostrarEfectoOndas){
            stateIncidentes.googleMap.circulos.map((data,i) => {    //elimina todos los circulos
                data.setMap(null)
            })
    
            stateIncidentes?.googleMap.crearCirculo({
                latLng: {
                    lat:positionNuevaIncidencia[0]?.lat,
                    lng:positionNuevaIncidencia[0]?.lng
                },
                radio: waveRadius,
                color: '#FF0000',
                opacidadBorde: 1,
                anchoBorde: 1,
                opacidadFondo: 0,
                colorFondo: ''
            })
        }
    },[mostrarEfectoOndas,waveRadius])

    useMemo(() => {
        listarIncidentes(9);    //carga por defecto los incidentes de pendiente
    }, [stateUser.actualizoConfiguracion])

    useMemo(() => {
        if (Object.keys(stateSocket.payload).length > 0) {

            const nuevaAlerta = stateSocket.payload.message.bd
            const pointToCheck = new google.maps.LatLng(nuevaAlerta.latitud,nuevaAlerta.longitud);
            
            const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, jurisdiccionMapa);

            if (isInside){
                showNotification(stateSocket.payload.message.bd)
                notify(stateSocket.payload.message.bd.mensajeAlerta || '¡Se envió una alerta!', 'info', { autoClose: true });
                listarIncidentes(9)                
                ubicarIncidencia(stateSocket.payload.message.bd,true)
                audioAlerta.current?.play();
            }
        }
    }, [stateSocket.payload])

    return(
        <>
            <ContenedorParametros titulo="Incidentes" />

            <audio ref={audioAlerta} src={sonidoAlerta} />

            <div className="relative mapa-incidentes h-[50%]" >
                <MapaAlertas googleMap={stateIncidentes.googleMap} />

                <div data-tip={verAgentes ? 'Ocultar agentes' : 'Ver agentes'} data-for={'BotonOjito'} className='mr-[2px] absolute bottom-[180px] hover:bg-gray-100 right-2 bg-[#ffffff] rounded p-1 z-10000000 cursor-pointer'  >
                    <div className='text-center w-[33px]' onClick={() => setVerAgentes(!verAgentes)}> 
                        <img src={verAgentes ? iconVerAgentes : iconOcultarAgentes}/>
                    </div>
                </div>

                <BotonesEstado 
                    listarIncidentes={listarIncidentes} 
                    setValorSelEstado={setValorSelEstado} 
                    estadoActual={stateIncidentes.estadoActual} 
                    funcionCambio={() => {
                        document.querySelectorAll('.botonesIncidencias').forEach((data) => {
                            data.classList.add('hidden')
                        })
                        document.querySelectorAll('.trTabla').forEach((data) => {
                            data.classList.remove('!bg-[#264258]')
                        })
                    }}
                />
            </div> 
                
            <div className="containerScroll tabla-incidentes max-h-[40%] overflow-auto" >
                <table className="table">
                    <thead>
                        <tr>
                            <th className=" w-[15px]">N°</th>
                            <th className=" w-[15px]">CÓDIGO</th>
                            <th className=" w-[150px]">FECHA HORA</th>
                            <th className=" w-[10px]"></th>
                            <th className=" w-[100px]">INCIDENCIA</th>
                            <th className=" W-[40%]">COMENTARIO</th>
                            <th className=" w-[150px] ">USUARIO</th>
                            <th className=" w-[100px] ">DNI</th>
                            <th className=" w-[100px]">CELULAR</th>
                            <th className=" w-[100px] ">ASIGNADO</th>
                            <th className=" w-[15px]">FOTO</th>
                            <th className=" w-[15px]"></th>
                            <th className=" w-[15px]"></th>
                            <th className=" w-[15px]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            stateIncidentes.incidentes.length > 0 ?
                            stateIncidentes.incidentes.map((incidencia, i) => {
                                let color = 'text-white-500  rounded-xl border-white border '
                                if (incidencia.codEstado === 9){///Pendiente
                                    color = 'text-[#fa050d]  rounded-xl border-white border '
                                } else if(incidencia.codEstado === 45){//En Proceso
                                    color = 'text-[#04bf19]  rounded-xl border-white border '
                                }else if (incidencia.codEstado === 7){// Cerrado
                                    color= 'text-[#000000] rounded-xl border-white border '
                                }

                                return (
                                    <tr key={i+1} className='trTabla' onClick={(e) => {
                                        if(!e.target.classList.contains('botonesIncidencias')){
                                            if(e.target.classList.contains('botonFoto')){
                                                e.target.parentElement.parentElement.parentElement.querySelectorAll('tr').forEach((fila,index) => {
                                                    fila.classList.remove('!bg-[#264258]')
                                                })
                                                e.target.parentElement.parentElement.classList.add('!bg-[#264258]')

                                                document.querySelectorAll('.botonesIncidencias').forEach((data) => {
                                                    data.classList.add('hidden')
                                                })

                                                e.target.parentElement.parentElement.querySelectorAll('.hidden').forEach((data2) => {
                                                    data2.classList.remove('hidden')
                                                })
                                            }else{
                                                e.target.parentElement.parentElement.querySelectorAll('tr').forEach((fila,index) => {
                                                    fila.classList.remove('!bg-[#264258]')
                                                })
                                                e.target.parentElement.classList.add('!bg-[#264258]')

                                                document.querySelectorAll('.botonesIncidencias').forEach((data) => {
                                                    data.classList.add('hidden')
                                                })

                                                e.target.parentElement.querySelectorAll('.hidden').forEach((data2) => {
                                                    data2.classList.remove('hidden')
                                                })
                                            }

                                            //***UBICAR LA INCIDENCIA EN EL MAPA */
                                            ubicarIncidencia(incidencia)

                                            stateIncidentes.googleMap.infoWindow && stateIncidentes.googleMap.infoWindow.close()
                                            //***UBICA AL USUARIO ASIGNADO EN EL MAPA CUANDO SE DA CLICK EN LA ALERTA (EN CASO TENGA USUARIO ASIGNADO) */
                                            if(incidencia.codUsuarioAsignado != 0){
                                                setUsuarioPosteo(incidencia.uidAsignado);
                                                mostrarUbicacionAgente(incidencia.uidAsignado,true);
                                                setMostrarUbicacionUsuario(true);
                                            }else{
                                                stateIncidentes.googleMap.quitarTodosLosTramos()
                                                setMostrarUbicacionUsuario(false);
                                            }
                                        }
                                    }}>
                                        <td className="text-center">{++i}</td>
                                        <td className="text-center">{incidencia.codAlerta}</td>
                                        <td className="text-center">{incidencia.fechaHoraAlerta}</td>
                                        <td className="text-center">{stateIncidentes.estadoActual === 0 ? <i className={` fa fa-circle  ${color}  `}></i> : '' }</td>
                                        <td className="text-center">{incidencia.nomAlertaTipo}</td>
                                        <td className="text-center">{incidencia.mensajeAlerta}</td>
                                        <td className="text-center">{incidencia.uid || '' }</td>
                                        <td className="text-center">{incidencia.numeroDocumento}</td>
                                        <td className="text-center">{incidencia.numCelularPrincipal}</td>
                                        <td className="text-center">{incidencia.uidAsignado}</td>
                                        <td className="text-center">
                                            {incidencia.imagenAlerta ? <button className="fa fa-picture-o text-white-600 text-lg botonFoto" onClick={()=> {verFoto(incidencia.nombreCarpetaAlerta,incidencia.imagenAlerta);setIncidenteActual(incidencia)} } ></button> : <i className="fa fa-picture text-black-600 text-lg"></i>}
                                        </td> 
                                        <td className="text-center" > 
                                            <button data-tip='Seguimiento alerta' data-for='Seguimiento' className="fas fa-bars text-blue-500 text-lg botonesIncidencias hidden" onClick={()=> {
                                                editarValorSeguimientoAlerta('codEstadoIntensidadAlerta','')    //vacia el campo cada vez que se abre el modal
                                                editarValorSeguimientoAlerta('chkEstadoAlerta',false)   //para ocultar la calificacion
                                                setCodEstadoAlerta(incidencia.codEstado)
                                                listarSeguimientoAlerta(incidencia.codAlerta)   //lista la tabla de seguimientos de la alerta y abre el modal
                                                setIncidenteActual(incidencia)
                                            }}></button>
                                        </td>
                                        <td >
                                            <a data-tip='Compartir ubicación' data-for='Compartir' className="fas fa-share-alt text-green-600 text-lg botonesIncidencias hidden" target="_blank" href={`https://api.whatsapp.com/send/?text=${incidencia.mensajeAlerta}|${incidencia.nomAlertaTipo}:%0a${incidencia.nomPersona}%0acel: ${incidencia.telefono}%0ahttps://maps.google.com/?q=${incidencia.latitudRegistro},${incidencia.longitudRegistro}`}></a>
                                        </td>
                                        <td >
                                            <button  
                                                data-tip='Asignar agente' 
                                                data-for='Asignar'
                                                className="fas fa-user text-orange-500 text-lg botonesIncidencias hidden"
                                                onClick={ () => {
                                                    listarAgentesTotales(incidencia.codAlerta)
                                                    openModalAsignacion()
                                                    setCodAlerta(incidencia.codAlerta)
                                                    setCodTipoAlerta(incidencia.nomAlertaTipo);
                                                    setIncidenteActual(incidencia)
                                                }}
                                            ></button>
                                        </td>
                                    </tr>
                                )
                            })
                            :<tr ><td colSpan='12' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="Seguimiento"></Tooltip>
                <Tooltip id="Compartir"></Tooltip>
                <Tooltip id="Asignar"></Tooltip>
                <Tooltip id="BotonOjito"></Tooltip>
            </div>

            {/* ------------ MODAL DE FOTO ----------------  */}
            <Modal 
                isOpen={isOpenFoto} 
                closeModal={closeModalFoto}
                action={closeModalFoto} 
                title={`Foto [Alerta: ${incidenteActual.codAlerta}]`}
                textButtons = {{
                    confirm: ''
                }}
                validButton = {{
                    confirm: false,
                    denied: false
                }}        
            >
                    <div className='flex-col h-[500px]'>

                    <div 
                        className={`z-0 rounded w-[450px] border border-gray-700 h-[400px] relative mt-1 containerScroll overflow-y-auto overflow-x-auto flex justify-center items-center `}
                        style={{ width: '400x', height: '535px', textAlign:'center'}}
                    >
                        <img 
                            src={fotoUrl} 
                            className={` rounded h-[100%] `}
                            style={{
                                transform: `rotate(${rotacion}deg) scale(${zoom})`,
                                position: 'relative',
                                top: `${dragOffset.y}px`,
                                left: `${dragOffset.x}px`,
                                transition: 'transform 0.3s',
                                transformOrigin: 'center',
                                overflow: 'hidden' // Añadir overflow: hidden para recortar cualquier parte sobresaliente de la imagen
                            }}
                            alt="sin imagen" 
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onDoubleClick={handleDoubleClick} // Doble clic para restablecer la posición
                            onMouseLeave={handleMouseUp} // Manejar el soltar el botón del mouse si se sale de la imagen
                        />
                    </div>

                    <div className=' text-center mt-1'>

                        <button 
                            className=' right-0 ml-2 bottom-[90px] w-[28px] bg-green-800 hover:bg-green-700 text-xl rounded' 
                            style={{zIndex:'100000'}}
                            onClick={toggleZoom}
                        >
                            <i 
                                data-tip 
                                data-for='toolZoomFoto' 
                                className="fa fa-search-plus" 
                                />

                            <Tooltip
                                id='toolZoomFoto' 
                                >Zoom x{zoom}
                            </Tooltip>
                        </button>

                        <button 
                            className='ml-2 right-0 bottom-[60px] w-[28px] bg-green-800 hover:bg-green-700 text-xl rounded' 
                            style={{zIndex:'100000'}}
                            onClick={toggleRotacion}
                            >
                            <i 
                                data-tip 
                                data-for='toolGirarFoto' 
                                className="fa fa-retweet" 
                                />
                            <Tooltip
                                id='toolGirarFoto' 
                                >Girar
                            </Tooltip>
                        </button>

                        <button 
                            data-tip 
                            data-for='toolRedireccionarFoto' 
                            className=' right-0 bottom-0 ml-2 bg-blue-500 hover:bg-blue-700 text-xl rounded' 
                            style={{zIndex:'100000'}}
                            >
                            <a  
                                className="m-1 fa fa-share" 
                                target="_blank" 
                                href={`${fotoUrl}`} 
                                rel="noopener noreferrer" 
                                >
                            </a>
                            <Tooltip id='toolRedireccionarFoto' >Abrir Foto</Tooltip>
                        </button>

                        </div>
                    </div>
            </Modal>

            {/* ------------ MODAL DE SEGUIMIENTO Y CALIFICACION ----------------  */}
            <Modal 
                    isOpen={isOpenModalSeguimiento}
                    textButtons = {{confirm: 'Confirmar', denied: 'Cerrar'}}
                    validButton = {{ confirm: (seguimientoAlerta.chkEstadoAlerta ? true : false) , denied: true}}
                    action={() => {
                        finalizarAlerta()
                    }}
                    closeModal={closeModalSeguimiento} 
                    title={`Seguimiento [Alerta: ${incidenteActual.codAlerta}]`}
                >
                    <div className="flex items-center flex-wrap justify-center " >
                        <div className="px-4 flex flex-col justify-between w-full form-content overflow-auto">
                                {/* CABECERA DE NUEVO SEGUIMIENTO ALERTA  */}
                                <div className={`lg:flex-col w-[200px] lg:w-full ${codEstadoAlerta == 7 ? '!hidden' : ''}`}>
                                    <div className=' mr-2'>
                                        <label>Comentario: </label>
                                    </div>
                                    <div className="flex  items-center gap-4 w-[300px]">
                                        <textarea 
                                            autoFocus={true} 
                                            className={`h-[60px] containerScroll py-1 text-white rounded mt-2 mr-2 px-4 w-[450px] lg:w-[200px] bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500 caret-input`}
                                            value={seguimientoAlerta.mensajeAlertaAtencion} 
                                            onChange={(e) => {editarValorSeguimientoAlerta("mensajeAlertaAtencion", e.target.value)}} 
                                        />
                                    </div>
                                    <div>
                                        <button 
                                            className='bg-green-700 hover:bg-green-500 py-1 px-2 rounded'
                                            onClick={()=> {guardarSeguimientoAlerta();editarValorSeguimientoAlerta("codAlerta", codAlerta)}}
                                        >
                                        <i className='fa fa-plus'></i>
                                        Agregar
                                        </button>
                                    </div>
                                </div>
                            {/* HISTORIAL DE SEGUIMIENTO DE ALERTA  */}
                            <label>Historial: </label>
                            <div className="containerScroll flex flex-col justify-between w-full form-content overflow-auto	max-h-52 lg:w-[320px]">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='w-[5px] text-left'>N°</th>
                                            <th className='w-[300px] text-left'>COMENTARIO</th>
                                            <th className='w-[50px] text-center'>USUARIO</th>
                                            <th className='w-[50px] text-center'>FECHA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            seguimientoAlertas.length == 0 ? 
                                                <tr><td colSpan={5} className='text-center'>No hay información para esta alerta</td></tr>
                                            :
                                            seguimientoAlertas.map((s,i) => {
                                                return (
                                                    <tr key={i+1}>
                                                        <td>{++i}</td>
                                                        <td className='text-left'>{s.mensajeAlertaAtencion}</td>
                                                        <td className='text-center'>{s.personaRegistro}</td>
                                                        <td className='text-center'>{s.fechaHoraRegistro}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className={`relative mt-[8px] text-right max-w-full ${codEstadoAlerta == 7 ? '!hidden' : ''}`}>
                        <label className='mr-[10px]'>Finalizar Alerta</label><label className="switch top-1">
                            <input className='' type="checkbox" checked={seguimientoAlerta.chkEstadoAlerta} onChange={(e) => editarValorSeguimientoAlerta("chkEstadoAlerta",e.target.checked)} />
                            <div className="slider"></div>
                        </label>
                    </div>

                    <div className='flex items-center flex-wrap justify-center relative mt-[20px]' style={{display: seguimientoAlerta.chkEstadoAlerta ? '' : 'none'}}> 
                        <h2 className='absolute underline top-0 text-xl'>
                            <span ><i className='fas fa-star text-[#F4FF0B]'></i>Califique la Alerta<i className='fas fa-star text-[#F4FF0B]'></i></span>
                        </h2>    

                        <div className='rounded flex gap-8 items-center relative justify-center mt-[30px]'>
                                <div className='m-[5px]'>
                                    <form action="" className='formularioRadio'>
                                        <div className="radio relative lg:max-w-[150px] ">
                                                {
                                                    calificaciones.map((c,i) => {
                                                        return (
                                                            <React.Fragment 
                                                                key={i+1}>
                                                                    <input 
                                                                        type='radio' name="calificacion" 
                                                                        value={c.codEstadoIntensidadAlerta} 
                                                                        defaultChecked={seguimientoAlerta.codCalificacion === c.codEstadoIntensidadAlerta ? true : false} 
                                                                        id={c.nomEstado}
                                                                        onChange={(e) => {
                                                                            editarValorSeguimientoAlerta("codEstadoIntensidadAlerta",e.target.value)}
                                                                        }>
                                                                    </input>
                                                                    <label 
                                                                        className='' 
                                                                        htmlFor={c.nomEstado} 
                                                                    >
                                                                            {(c.nomEstado)}
                                                                    </label>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
            </Modal>

            {/* ------------ MODAL DE ASIGNACION ----------------  */}
            <ModalAsignacion
                isOpen={isOpenAsignacion} 
                closeModal={() => {
                    stateIncidentes.googleMap.infoWindow && stateIncidentes.googleMap.infoWindow.close()
                    stateIncidentes.googleMap.quitarTodosLosMarcadoresExcepto(marcadorAlerta)
                    stateIncidentes.googleMap.quitarTodosLosTramos()
                    closeModalAsignacion()
                }}
                title={`Asignar agente [Alerta: ${incidenteActual.codAlerta}]`}
                textButtons = {{
                    confirm: 'Ver agentes cercanos'
                }}
                validButton = {{ confirm: false , denied: false}}
                // clasesModal={'opacity-80 hover:opacity-100 !mr-0'}
            >
                <div className=" containerScroll tabla-incidentes lg:max-w-[320px] max-h-[300px] w-[400px] overflow-auto" >
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-[15px]">N°</th>
                                <th className="w-[100px]">NOMBRE</th>
                                <th className="w-[80px]">DISTANCIA</th>
                                <th className="w-[150px]">FECHA</th>
                                <th className="w-[15px]"></th>
                                <th className="w-[15px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                agentesTotales?.length > 0 ?
                                agentesTotales?.map((agente, i) => {
                                    return (
                                        <tr key={i+1}>
                                            <td className="text-center">{++i}</td>
                                            <td className="text-center">{agente?.uid}</td>
                                            <td className="text-center">{agente?.distanciaAgente || '-'} mtrs</td>
                                            <td className="text-center">{agente?.fechaUltimaUbicacion}</td>
                                            <td className="text-center" data-tip="Asignar agente" data-for="AsignarModal">
                                                <button 
                                                    className="fas fa-check text-gray-500 hover:text-green-300 text-lg" 
                                                    onClick={()=>{
                                                        asignarUsuarioAlerta(agente?.uid.toLowerCase(),codAlerta,codTipoAlerta)
                                                    }}
                                                ></button>    
                                            </td>
                                            <td className="text-center" data-tip="Ver agente en mapa" data-for="VerModal">
                                                <button 
                                                    className="fas fa-map-marker text-gray-500 hover:text-red-300 text-lg" 
                                                    onClick={() => {
                                                        mostrarUbicacionAgente(agente?.uid,true);
                                                    }}
                                                ></button>    
                                            </td>
                                        </tr>
                                    )
                                })
                                :<tr ><td colSpan='12' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                            }
                        </tbody>
                    </table>
                <Tooltip id="AsignarModal"></Tooltip>
                <Tooltip id="VerModal"></Tooltip>
                </div>
            </ModalAsignacion>

            
        </>
    )
}