import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { useRegiones } from '../../../hooks/useRegiones';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'; 
import { useEstados } from '../../../hooks/useEstados';
import { useCalificacionAlerta } from '../../../hooks/Incidentes/useCalificacionAlerta';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { Tooltip } from '../../components/utils/Tooltip';
import { UseEstadisticas } from '../../../hooks/UseEstadisticas';

const catastroTipo = {
    CAMBIAR_VALOR: 'CAMBIAR_VALOR'
}

const estadoInicial = {
    codPais: 1,
    codDepartamento: 0,
    codProvincia: 0,
    codDistrito: 0,
    codEstado: 0,
    codCalificacion: 0,
}

const catastroTerritorialReducer = (state, action) => {
    switch (action.type) {
        case catastroTipo.CAMBIAR_VALOR:
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: value
            }
        default:
            throw new Error('Not implemented');
    }
}

const useCatastroTerritorial = () => {
    const [ state, dispatch ] = useReducer(catastroTerritorialReducer, estadoInicial);
    const [ paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ] = useRegiones();
    const {estadisticas, listarEstadisticas} = UseEstadisticas()

    useMemo(() => {
        obtenerDepartamentos(state.codPais);
    }, [])

    useMemo(() => {     
        obtenerProvincias(Number(state.codDepartamento))
        obtenerDistritos(0)
    }, [state.codDepartamento])

    useMemo(() => {
        obtenerDistritos(Number(state.codProvincia))
    }, [state.codProvincia])

    useEffect(() => {
        listarEstadisticas(state.codDepartamento, state.codProvincia, state.codDistrito, state.codEstado, state.codCalificacion)
    },[state.codDepartamento,state.codDistrito,state.codDistrito,state.codEstado, state.codCalificacion,distritos])

    useEffect(() => {
        if (provincias.length > 0) {
            dispatch({ type: catastroTipo.CAMBIAR_VALOR, payload: { key: 'codProvincia', value: 0 }})
        }
        listarEstadisticas(state.codDepartamento,state.codProvincia,state.codDistrito,state.codEstado,state.codCalificacion)
    }, [provincias])

    useEffect(() => {
        if (distritos.length > 0) {
            dispatch({ type: catastroTipo.CAMBIAR_VALOR, payload: { key: 'codDistrito', value: 0 }})
        }
        listarEstadisticas(state.codDepartamento,state.codProvincia,state.codDistrito,state.codEstado,state.codCalificacion)
    }, [distritos])
    
    return {
        state,
        dispatch,
        departamentos,
        provincias,
        distritos,    
        estadisticas, 
        listarEstadisticas
    }
}

export const Estadisticas = () => {
    const { state, dispatch, departamentos, provincias, distritos,estadisticas, listarEstadisticas } = useCatastroTerritorial();
    const [ estados ] = useEstados();
    const { calificacion } = useCalificacionAlerta();
    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    useEffect(() => {
    }, [])

    return (
        <>
            <ContenedorParametros
                titulo='Estadísticas'
            >   
                <i className="fa fa-map-o" data-tip data-for={'Departamento'} aria-hidden="true"></i>
                <div>
                    <select className={`${classNameInput} `} value={state.codDepartamento} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codDepartamento', value: Number(e.target.value)}})}>
                        <option value="0">-- Todos --</option>
                        {
                            departamentos.map(d => {
                                return <option key={d.codDepartamento} value={d.codDepartamento}>{d.nomDepartamento}</option>
                            })
                        }
                    </select>
                </div>
                
                <i className="fa fa-map" data-tip data-for={'Provincia'} aria-hidden="true"></i>
                <div>
                    <select data-tip data-for={'Provincia'} className={`${classNameInput}`} value={state.codProvincia} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codProvincia', value: Number(e.target.value)}})}>
                        <option value="0">-- Todos --</option>
                        {
                            provincias.map(p => {
                                return <option key={p.codProvincia} value={p.codProvincia}>{p.nomProvincia}</option>
                            })
                        }
                    </select>
                </div>

                <i className="fa fa-map-pin" data-tip data-for={'Distrito'} aria-hidden="true"></i>
                <div>
                    <select className={`${classNameInput}`} value={state.codDistrito} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codDistrito', value: Number(e.target.value)}})}>
                        <option value="0">-- Todos --</option>
                        {
                            distritos.map(d => {
                                return <option key={d.codDistrito} value={d.codDistrito}>{d.nomDistrito}</option>
                            })
                        }
                    </select>
                </div>

                <i className="fa fa-certificate" data-tip data-for={'Estado'} aria-hidden="true"/>
                <div>
                    <select className={`${classNameInput}`} value={state.codEstado}  onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codEstado', value: Number(e.target.value)}})}>
                        <option value="0">-- Todos --</option>
                        {
                            estados.map(e => {
                                return <option key={e.codEstado} value={e.codEstado}>{e.nomEstado}</option>
                            })
                        }
                    </select>
                </div>

                <i className="fa fa-star" data-tip data-for={'Categoria'} aria-hidden="true"></i>
                <div>
                    <select className={`${classNameInput}`} value={state.codCalificacion} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codCalificacion', value: Number(e.target.value)}})}>
                        <option value="0">-- Todos --</option>
                        {
                            calificacion?.map(c => {
                                return <option key={c.codEstadoIntensidadAlerta} value={c.codEstadoIntensidadAlerta}>{c.nomEstado}</option>
                            })
                        }
                    </select>
                </div>

                <BotonProcesar onClick={()=>listarEstadisticas(state.codDepartamento,state.codProvincia,state.codDistrito,state.codEstado,state.codCalificacion)} ></BotonProcesar>       
            </ContenedorParametros>
            
            <Tooltip id={'Departamento'}>Departamento</Tooltip>
            <Tooltip id={'Provincia'}>Provincia</Tooltip>
            <Tooltip id={'Distrito'}>Distrito</Tooltip>
            <Tooltip id={'Estado'}>Estado</Tooltip>
            <Tooltip id={'Categoria'}>Categoria</Tooltip>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="w-[20px]">N°</th>
                            <th className="w-[100px]">CÓDIGO</th>
                            <th className="w-[120px]">TIPO</th>
                            <th className="w-[38%]">MENSAJE</th>
                            <th className="w-[150px]">FECHA/HORA</th>
                            <th className="w-[150px]">COMISARIA</th>
                            <th className="w-[150px]">LATITUD</th>
                            <th className="w-[150px]">LONGITUD</th>
                            <th className="w-[150px]">DEPARTAMENTO</th>
                            <th className="w-[150px]">PROVINCIA</th>
                            <th className="w-[180px]">DISTRITO</th>
                            <th className="w-[180px]">ESTADO</th>
                            <th className="w-[80px]">CATEGORIA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            estadisticas.length > 0 ?
                            estadisticas.map((incidencia, i) => {

                                let color = 'text-white-500  rounded-xl border-white border '
                                if (incidencia.nomEstado === 'PENDIENTE'){///Pendiente
                                    color = `cursor-pointer bg-red-600 px-2 py-[2px] rounded uppercase text-[12px] font-bold `
                                } else if(incidencia.nomEstado === 'EN PROCESO'){//En Proceso
                                    color = `cursor-pointer bg-green-600 px-2 py-[2px] rounded uppercase text-[12px] font-bold `
                                }else if (incidencia.nomEstado === 'CERRADO'){// Cerrado
                                    color = `cursor-pointer bg-gray-700 px-2 py-[2px] rounded uppercase text-[12px] font-bold `
                                }

                                let colorIntensidad = ''
                                if (incidencia.nomEstadoIntensidad === 'MUY GRAVE'){
                                    colorIntensidad = `fa fa-star cursor-pointer text-red-500 px-2 py-[2px] rounded uppercase text-[15px] font-bold `
                                }else if(incidencia.nomEstadoIntensidad ==='GRAVE') {
                                    colorIntensidad = `fa fa-star cursor-pointer text-orange-500 px-2 py-[2px] rounded uppercase text-[15px] font-bold `
                                }else if(incidencia.nomEstadoIntensidad ==='LEVE') {
                                    colorIntensidad = `fa fa-star cursor-pointer text-yellow-500 px-2 py-[2px] rounded uppercase text-[15px] font-bold `
                                }else if(incidencia.nomEstadoIntensidad ==='MUY LEVE') {
                                    colorIntensidad = `fa fa-star cursor-pointer text-green-500 px-2 py-[2px] rounded uppercase text-[15px] font-bold `
                                }else {
                                    colorIntensidad =''
                                }
                                
                                return (
                                    <tr key={i++}>
                                        <td className="text-center">{++i}</td>
                                        <td className="text-center">{incidencia.codAlerta}</td>
                                        <td className="text-center">{incidencia.nomAlertaTipo}</td>
                                        <td className="text-center">{incidencia.mensajeAlerta}</td>
                                        <td className="text-center">{incidencia.fechaHoraAlerta}</td>
                                        <td className="text-center">{incidencia.nomComisaria}</td>
                                        <td className="text-center">{incidencia.latitudRegistro}</td>
                                        <td className="text-center">{incidencia.longitudRegistro}</td>
                                        <td className="text-center">{incidencia.nomDepartamento}</td>
                                        <td className="text-center">{incidencia.nomProvincia}</td>
                                        <td className="text-center">{incidencia.nomDistrito}</td>

                                        <td className="text-center"><span className ={`${color} whitespace-nowrap`}>{incidencia.nomEstado}</span></td>

                                        <td className="text-center">
                                            <span data-tip data-for={`codIntensidad-${incidencia.codAlerta}`} className ={`${colorIntensidad}` }>
                                                <Tooltip id={`codIntensidad-${incidencia.codAlerta}`}>{incidencia.nomEstadoIntensidad}</Tooltip>
                                            </span>
                                        </td>
                                        {/* <td className="text-center" ><span className ={colorIntensidad}>{incidencia.nomEstadoIntensidad}</span></td> */}
                                    </tr>
                                )
                            })
                            :<tr ><td colSpan='14' className='text-center'>No hay informacion para mostrar con los parametros seleccionados</td></tr>
                        }
                    </tbody> 
                </table>
            </div>
        </>
    )
}