import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/provider/UserProvider";
import { Fetch } from "../../../services/api";
// import logoAbeja from '../../../assets/images/icon_beexqr.fw.png'
import { notify } from "../../../utils/utils";
import { SocketContext } from "../../../context/provider/SocketProvider";
import fondoLogo from "../../../assets/images/primeraparteLogo.fw.png";
import iconLogo from "../../../assets/images/segundaparteLogo.fw.png";

const urlBase = process.env.REACT_APP_PLAMIN_API;

export const PantallaCarga = (req, res) => {
    const [aToken, setAToken] = useState(null);
    // const [ token , setToken ] = useState(null)
    const { mqttConnect, mqttDisconnect } = useContext(SocketContext);
    const [respuestaLogeo, setRespuestaLogeo] = useState("");
    const { stateUser, signIn, signOut } = useContext(UserContext);
    const [cerroSesion, setCerroSesion] = useState(0);

    const history = useHistory();

    const intercambioToken = async (req, res) => {
        try {
            const urlParams = new URLSearchParams(req.location.search);
            const code = urlParams.get("code");
            const state = urlParams.get("state");
            const sessionState = urlParams.get("session_state");

            if (state == "logout_success") {
                if (urlParams.get("error_description")) {
                    if (urlParams.get("error") == "access_denied")
                        setCerroSesion(1);
                } else {
                    setCerroSesion(2);
                }
            }

            const jsonData = {
                code: code,
                state: state,
                session_state: sessionState,
            };
            const urlIs = process.env.REACT_APP_WSO2IS_AUTHORIZATION;
            const Redirect_Uri = process.env.REACT_APP_REDIRECT_URI;
            const Client_Id = process.env.REACT_APP_WSO2IS_CLIENT_ID;
            const Client_Secret = process.env.REACT_APP_WSO2IS_CLIENT_SECRET;

            const Scope =
                "plamin-alerts.write plamin-alerts.read openid profile";

            let body = `grant_type=authorization_code&code=${
                jsonData.code
            }&redirect_uri=${Redirect_Uri}&client_id=${Client_Id}&client_secret=${Client_Secret}&scope=${encodeURIComponent(
                Scope
            )}`;

            const response = await Fetch({
                url: urlIs + "/token",
                method: "POST",
                mode: "no-cors",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            setAToken(response);
            // setToken(response)
            if (response?.access_token) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error en intercambioToken:", error);
            return false;
        }
    };

    useEffect(() => {
        intercambioToken(req, res);
    }, []);

    const obtenerDatosSesion = async () => {
        try {
            if (aToken.access_token) {
                const response = await fetch(
                    urlBase + "/api/User/getDataSession",
                    {
                        method: "POST",
                        headers: {
                            Authorization: "Bearer " + aToken?.access_token,
                            "Content-Type": "application/json",
                        },
                    }
                ).then((res) => res.json());

                if (response.isValid) {
                    localStorage.setItem("uid", response.content.uid);
                    localStorage.setItem(
                        "codEntidad",
                        response.content.codEntidad
                    );

                    if (aToken.id_token) {
                        response.content.id_token = aToken.id_token;
                    }

                    signIn({ ...response.content });

                    mqttConnect();

                    history.push("/principal");
                } else {
                    setRespuestaLogeo(response.exceptions[0].description);
                }
            }
        } catch (error) {
            // notify('Usuario o contraseña incorrectos', 'error')

            console.error("Error en obtenerDatosSesion:", error);
            window.location.href = '/'
            window.localStorage.clear()
            // history.push("/login")	// CUANDO VENCE LA SESION PARA QUE REDIRIJA AL LOGIN
        }
    };

    useEffect(() => {
        if (cerroSesion == 2) {
            notify("Su sesión se cerro correctamente", "success");
            history.push("/login");
            mqttDisconnect();
            signOut();
        } else if (cerroSesion == 1) {
            notify("Canceló el cerrado de sesión", "error");
            history.push("/principal");
        }
    }, [cerroSesion]);

    useEffect(() => {
        if(aToken){
            obtenerDatosSesion();
        }
    }, [aToken]);

    return (
        <>
            <div className="fixed top-0 left-0 w-full h-full bg-black flex justify-center items-center z-[10000]">  {/* z-index superior para que se superponga a todo lo demas */}
                <div className="text-white text-center">
                    <div className="flex flex-col items-center px-5 mb-10 relative">
                        <img
                            className="w-auto animate-spin "
                            src={fondoLogo}
                            alt=""
                        />

                        <div className="absolute animate-pulse inset-0 flex justify-center items-center">
                            <img
                                className="w-auto h-auto max-w-full max-h-full"
                                src={iconLogo}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className=" text-2xl">
                        {respuestaLogeo || "Cargando..."}{" "}
                    </div>
                    <button
                        className="text-white border px-3 py-2  mt-2 rounded-lg"
                        onClick={() => {
                            history.push("/login");
                            localStorage.clear();
                        }}
                    >
                        Regresar a Login
                    </button>
                </div>
            </div>
        </>
    );
};
