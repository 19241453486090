import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '.././hooks/useModal';
import fotoDefault from '../../src/assets/images/usuarioDefault.jpg';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const bienesServiciosDefault = {
    uid:'',
    codRecurso:0,
    codEntidad: 0,
    fechaRegistro:new Date(),
    codRecursoTipo: 1, 
    descripcion:'',
    personaDeposito:'',
    valorizado:0,
    Observacion:'',
    urlImagen:'',
}

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useGestionBienesServicios = () => {

    const [ bienesServicios, setBienesServicios ] = useState()
    const [ bienServicio, setBienServicio ] = useState()
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();
    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlPago, setFotoUrlPago ] = useState(fotoDefault);

    const {stateUser} = useContext(UserContext)

    
    useEffect(() => {
        listarBienesServicios();
    }, []);


    const listarBienesServicios = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Resources?CodEntidad=' + (stateUser.codEntidad),
        });
        console.log("RESPONSE  listarBienesServicios -> ", response.content)
        if (response.isValid) {
            setBienesServicios(response.content);
        } else {
            
            notify(response.exceptions[0].description, 'error');
        }
    }

    // const obtenerBienServicio= async (codBienServicio) => {
    //     if (codBienServicio) {
    //         const response = await AuthFetch({
    //             url: urlBasePlamin + '/Resources/' + codBienServicio
    //         });

    //         if (response.isValid) {
    //             setBienServicio(response.content);
    //             // verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
    //         } else {
    //             notify(response.exceptions[0].description, 'error');
    //         }
    //     } else {
    //         setBienServicio(bienesServiciosDefault);
    //         setFotoUrlPago(fotoDefault)
    //     }
    //     openModal();
    // }

    const obtenerBienServicio= async (bienServicio) => {
        console.log("obtenerBienServicio -> ", bienServicio)
        
        if (bienServicio) {
            setBienServicio(bienServicio);
            setFotoUrlPago(bienServicio.urlImagen)
        } else {
            setBienServicio(bienesServiciosDefault);
            setFotoUrlPago(fotoDefault)
        }
        openModal();
    }

    const bienServicioEliminar = async (bienServicio) =>{
        console.log("bienServicioEliminar -->" ,bienServicio )
        if (bienServicio) {
                setBienServicio(bienServicio);
                verFotoUrlPago(bienServicio.urlImagen)
        } else {
            setBienServicio(bienesServiciosDefault);
            setFotoUrlPago(fotoDefault)
        }
        openModalEliminar();
    }

    const eliminarBienServicio = async (codBienServicio)=>{
        const response = await AuthFetch({
            url: urlBasePlamin + '/Resources',
            method:'DELETE',
            body: JSON.stringify({
                codRecurso: codBienServicio,
                comentario: bienServicio.comentario
            })
        })
        if (response.isValid){
            listarBienesServicios()            
            notify(response.content, 'success');
            closeModalEliminar()
        }else{
            notify(response.exceptions[0].description, 'error');
        }
    }
    
    const guardarEditarBienServicio = async (urlImagen) => {
        const esGuardar = bienServicio.codRecurso <= 0;
        urlImagen && (bienesServicios.urlImagen = urlImagen)
        const response = await AuthFetch({
            url: urlBasePlamin + '/Resources',
            method: esGuardar ? 'POST' : 'PUT', 
            body: JSON.stringify({
                ...(!esGuardar && { codRecurso: bienServicio.codRecurso }),
                fechaRegistro: bienServicio.fechaRegistro,
                codRecursoTipo: bienServicio.codRecursoTipo,
                descripcion: bienServicio.descripcion,
                personaDeposito: bienServicio.personaDeposito,
                valorizado: bienServicio.valorizado,
                observacion: bienServicio.observacion,
                codEntidad:stateUser.codEntidad,
                urlImagen: bienServicio.urlImagen
            })
        });
        // notify(response.content,(response.isValid ? 'success' : 'error'));
        if (response.isValid){
            notify(response.content, 'success');
            closeModal()
            listarBienesServicios()
            setBienServicio(bienesServiciosDefault)
        }else{
            notify(response.exceptions[0].description, 'error');
        }
    }

    const editarValorBienServicio = (key, value) => {
        console.log("editarBienServicio ->" ,key, value)
        setBienServicio(bienServicio => {
            return {
                ...bienServicio,
                [key]: value
            }
        });
    }

    const verFotoUrlPago = (nomCarpeta,nombreFoto) => {
        if (nombreFoto === ''){
            setFotoUrlPago(fotoDefault); 
        }else {
            setFotoUrlPago(url => urlBasePlamin + `/Minio?nombreCarpetaFoto=${nomCarpeta}&nombreFoto=${nombreFoto}`);
        }
    }

    return {
            bienesServicios,
            listarBienesServicios,
            guardarEditarBienServicio,
            bienServicio, 
            editarValorBienServicio, 
            obtenerBienServicio, 
            isOpenModal, 
            closeModal, 
            fotoUrlPago,
            setFotoFile,
            setFotoUrlPago,
            eliminarBienServicio, 
            isOpenModalEliminar, 
            closeModalEliminar, 
            bienServicioEliminar,
    }
}