import React ,{useEffect,useMemo,useReducer,useState} from 'react'
import { AuthFetch } from '../services/api';
import { notify } from '../utils/utils';
const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

// const tipoPermisos = {
//     ESTABLECER_DATOS: 'ESTABLECER_DATOS',
// }

function usePermisos() {
    const [listaPermisos,setListaPermisos] = useState({})

    const obtenerPermisosPorUsuarioTipo = async () => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Usuario/permisosPorUsuarioTipo'
        });

        if (respuesta.isValid) {
            setListaPermisos({
                permisos: respuesta.content.permisosPorUsuarioTipos,
                usuarioTipos: respuesta.content.usuarioTipos
            })
        }
    }

    const cambiarEstadoPermiso = async (e, codPermiso, codUsuarioTipo) => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Accesos/habilitarPermisos',
            method: 'POST',
            body: JSON.stringify({
                codPermiso: codPermiso,
                codUsuarioTipo: codUsuarioTipo,
                habilitar: e.target.checked
            })
        });

        notify(respuesta.content, respuesta.isValid ? 'success' : 'error');

        if(respuesta.isValid){
            obtenerPermisosPorUsuarioTipo()
        }
    }

    useEffect(() => {
        obtenerPermisosPorUsuarioTipo();
    }, []);

    return {
        listaPermisos,
        cambiarEstadoPermiso
    }
}

export default usePermisos