import React, { useEffect, useState, useRef, useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { useGestionBienesServicios } from '../../../hooks/useGestionBienesServicios';
import { notify, soloCelular, soloDNI } from '../../../utils/utils';
import { useEntidades } from '../../../hooks/useEntidades';
import { UserContext } from '../../../context/provider/UserProvider';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import { enviarImagenMinio } from '../../../utils/utils';

export const GestionBienesServicios = () => {
    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const fotoDefault = 'https://cdn-icons-png.flaticon.com/512/526/526659.png'
    const inputFoto = useRef();
    const { stateUser }  = useContext(UserContext)

    const { 
        bienesServicios,
        listarBienesServicios,
        guardarEditarBienServicio,
        bienServicio, 
        editarValorBienServicio, 
        obtenerBienServicio, 
        isOpenModal, 
        closeModal, 
        fotoUrlPago,
        setFotoFile,
        setFotoUrlPago,
        eliminarBienServicio, 
        isOpenModalEliminar, 
        closeModalEliminar, 
        bienServicioEliminar,
    } = useGestionBienesServicios();

    const { listarEntidades,entidades } = useEntidades()

    const [ selectedFile, setSelectedFile ] = useState()
    const [ startDate, setStartDate ] = useState(new Date())
    const [ registrarEditar,setRegistrarEditar ] = useState(true)
    const [urlRegistroPago,setUrlRegistroPago] = useState(fotoDefault)

    useEffect(()=>{
        editarValorBienServicio("fechaRegistro", startDate)
    },[startDate])

    useEffect(()=> {
        listarEntidades()
    },[])

    useEffect(() => {
        console.log("selectedFile")
        if (!selectedFile) {
            setFotoUrlPago(fotoDefault)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlPago(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        console.log("ONSELECTFILE")
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }


    const modificarImagen = (e) => {
        const archivoImagen = e.target.files[0]
        const urlArchivoImagen = URL.createObjectURL(archivoImagen)
        editarValorBienServicio('urlImagen',urlArchivoImagen)
    }

    // const funcionSubirImagenMinio = async () => {
    //     // const nombreArchivo = generarNombreUnico('registroPersona')
    //     const timestamp = Date.now();   
    //     console.log("timestamp-> ",timestamp)
    //     const nombreArchivo = `${timestamp}`
    //     // const nombreArchivo = `${bienServicio.codDocumentoTipo.toLowerCase().replace(/\s/g, '')}_${bienServicio.valorizado}`
    //     const promise = await fetch(bienServicio.urlImagen)
    //     const blob = await promise.blob()
    //     const file = new File([blob],nombreArchivo,{type: blob.type})

    //     const urlSubirArchivo = `https://miniowebapi.abexacloud.com/api/Archivo/subirArchivo?NombreCarpeta=tgps&NombreArchivo=%2Ffotos-personas%2F${nombreArchivo}`
	// 	const urlVer =`https://miniowebapi.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=tgps&NombreImagen=fotos-personas%2F${nombreArchivo}`
	// 	const formData = new FormData();
	// 	formData.append("archivo", file)
	// 	const response = await fetch(urlSubirArchivo,{
	// 		method:'POST',
	// 		body: formData
	// 	}).then(res => res.json())
	// 	if(response.isValid){
    //         notify('Archivo subido correctamente','success')
    //         editarValorBienServicio('urlImagen',urlVer)
	// 	}else{
	// 		notify('Error al enviar el archivo, inténtelo de nuevo','error')
	// 	}
    // }



    const funcionRegistrarEditar = async () => {
        const timestamp = Date.now();   
        const importoFoto = inputFoto.current.files.length != 0
        const urlVer = importoFoto ? 
                            await enviarImagenMinio({
                                nombreCarpeta: "plamin-gestion-recursos",
                                nombreArchivo: `%2Ffotos-personas%2Frepuesto-${timestamp}`,
                                file: inputFoto.current.files[0],
                            }) 
                        :
                             ''
        
        const jsonData = await guardarEditarBienServicio(urlVer)
        notify(jsonData.DesResultado,jsonData.CodResultado == 1 ? 'success' : 'error')

        if(jsonData.CodResultado == 1){
            closeModal()
            await listarBienesServicios()
        }
    }

    
    return (
        <>
            <ContenedorParametros
                titulo="Gestión de Bienes y servicios"
                tablaId={'tabla-bienes-servicios'}
            >
                <div>
                    <BotonProcesar onClick={() => listarBienesServicios()} ></BotonProcesar>
                </div>

                <div>
                    <BotonNuevo onClick={() => {obtenerBienServicio();setRegistrarEditar(true)}}></BotonNuevo>
                </div>
            </ContenedorParametros>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-bienes-servicios' className="table">
                    <thead>
                        <tr>
                            <th className="w-[30px]">N</th>
                            <th className="text-left">FECHA</th>
                            <th className="text-left">DESCRIPCIÓN</th>
                            <th className="text-left">TIPO</th>
                            <th className="text-left">VALORIZADO</th>
                            <th className="text-left">DEPOSITANTE</th> 
                            <th className="text-left">ESPECIFICACIONES</th>
                            <th className="w-[10px]"></th>
                            <th className="w-[10px]"></th>

                        </tr>
                    </thead>
                    <tbody>
                    {   
                            bienesServicios?.length > 0 
                            ? bienesServicios?.map((bs, i) => {
                                return (
                                    <tr key={i++}>
                                        <td>{ i + 1}</td>
                                        <td className="text-left">{bs?.fechaRegistro}</td>
                                        <td className="text-left">{bs?.descripcion}</td>
                                        <td className="text-left">{bs?.nomRecursoTipo}</td>
                                        <td className="text-left">{bs?.valorizado}</td>
                                        <td className="text-left">{bs?.personaDeposito}</td>
                                        <td className="text-left">{bs?.observacion}</td>
                                        <td className="text-left w-[10px]" >
                                            <button  onClick={()=>obtenerBienServicio(bs)} className="bg-blue-500 hover:bg-blue-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-file"></i>
                                            </button>
                                            
                                        </td>
                                        <td className="text-left w-[10px]" >
                                            <button  onClick={()=>bienServicioEliminar(bs)} className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            
                                        </td>
                                    </tr>

                                )
                            })
                            : <tr><td colSpan="9" className="text-left">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                        }
                    </tbody>
                </table>
            </div>


            <Modal 
                isOpen={isOpenModal} closeModal={closeModal}
                action={funcionRegistrarEditar}
                title={`${bienServicio?.codRecurso ? 'Detalle' : 'Registrar'}`}
                textButtons={{ confirm: 'Guardar', denied: bienServicio?.codRecurso? 'Cerrar': 'Cancelar' }}
                validButton = {{confirm:  !bienServicio?.codRecurso  , denied: true}}


            >
                <div className="flex  items-center flex-wrap justify-center containerScroll lg:overflow-y-auto">
                    <div className="w-[420px] lg:w-auto px-4 containerScroll">
                        <div className="flex flex-col justify-between w-full form-content">                        
                           
                            <div className='lg:flex-col lg:w-full'>
                                <label>Fecha: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <DatePickerABX data-for={'FechaInicio'} date={startDate} setDate={setStartDate}/>
                                </div>
                            </div>


                            <div className='lg:flex-col lg:w-full'>
                                <label>Tipo: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={bienServicio?.codRecursoTipo} onChange={(e) => {editarValorBienServicio("codRecursoTipo", e.target.value)}} >
                                        <option value={1}>Bien</option>
                                        <option value={2}>Servicio</option>
                                    </select>
                                </div>
                            </div>
                           
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Descripción: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input  
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={bienServicio?.descripcion} 
                                        onChange={(e) => editarValorBienServicio("descripcion", e.target.value)} 
                                    />
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Depositante: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={bienServicio?.personaDeposito} 
                                        onChange={(e) => editarValorBienServicio("personaDeposito", e.target.value)} 
                                    />
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Valorizado: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={bienServicio?.valorizado} 
                                        onChange={(e) => editarValorBienServicio("valorizado", e.target.value)} 
                                    />
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Especificaciones: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={bienServicio?.observacion} 
                                        onChange={(e) => editarValorBienServicio("observacion", e.target.value)} 
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="text-center">
                        <div>
                            <img className='rounded-xl w-[200px] h-[200px] lg:w-[150px] lg:h-[150px]' 
                                    src={bienServicio?.urlImagen || urlRegistroPago} alt='' />
                            <input ref={inputFoto} 
                                onChange={e => {
                                    modificarImagen(e)
                                }} 
                                type="file" 
                                name="file-1" 
                                id="file-1" 
                                className="inputfile inputfile-1" 
                                accept='.jpg,.png' 
                            />
                            {!bienServicio?.codRecurso &&
                                <>
                                    <label htmlFor="file-1">
                                        <i className="fa fa-image iborrainputfile" style={{color: '#FFFFFF', fontSize:'18px'}}></i> 
                                        <span className="iborrainputfile"> Seleccione</span>
                                    </label>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarBienServicio(bienServicio?.codRecurso)}
                title= "Anular Registro"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex flex-col gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de anular el registro? </label>
                        </div>
                        <div className="flex items-center gap-4 w-[250px]">
                            <textarea
                                className={`${classNameInput} h-[100px] caret-input`}
                                placeholder='Ingrese un comentario de anulación...'
                                defaultValue={bienServicio?.comentario} 
                                onChange={(e) => editarValorBienServicio("comentario", e.target.value)} 
                            />
                        </div>
                    </div>
                    
                
            </Modal>   


        </>
    )
}