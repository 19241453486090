import { useState, useEffect } from 'react';
import { AuthFetch } from '../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useEstados = () => {
    const [ estados, setEstados ] = useState([]);

    useEffect(() => {
        listarEstados();
    }, []);

    const listarEstados = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Alerts/status'
        });

        if (response.isValid) {
            setEstados(response.content);
        } else {
            alert(response.content);
        }
    }
    return [ estados ];
}