import { useState,useEffect} from 'react';
import { AuthFetch } from '../services/api';
import { notify } from '../utils/utils';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";
export const UseEstadisticas = () => {
    const [estadisticas, setEstadisticas ] = useState([]);

    useEffect(() => {
        listarEstadisticas(0,0,0,0,0);
    }, []);


    const listarEstadisticas = async (codDepartamento,codProvincia,codDistrito,codEstado,codEstadoIntensidadAlerta) => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/alertas/listarAlertasEstadisticas?' + new URLSearchParams({
                CodDepartamento: codDepartamento || 0,
                CodProvincia: codProvincia || 0,
                CodDistrito: codDistrito || 0,
                CodEstado: codEstado || 0,
                CodEstadoIntensidadAlerta: codEstadoIntensidadAlerta || 0,
            })
        });
        
        if (response.isValid) {
            setEstadisticas(response.content);
        } else {
            notify('response.content','error')
        }
    }
    return {  estadisticas, listarEstadisticas }
}