import React, { useState, useEffect, useRef } from "react";
import { BotonProcesar } from "../../components/buttons/BotonProcesar";
import { BotonNuevo } from "../../components/buttons/BotonNuevo";
import { Modal } from "../../components/modal/Modal";
import { useModal } from "../../../hooks/useModal";
import { classNameInput, getRandomColor, notify } from "../../../utils/utils";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";

import "../../../assets/css/views/maps.css";

import { GoogleMap } from "../../../utils/googlemaps";
import BotonSwitch from "../../components/buttons/BotonSwitch";
import ReactTooltip from "react-tooltip";
import useIsMobile from "../../../hooks/useIsMobile";
import { useRutasAcceso } from "../../../hooks/registros/useRutasAcceso";
import { ComponenteCarga } from "../../components/forms/ComponenteCarga";

const google = window.google;
const googleMap = new GoogleMap();
const googleMapModalRegistro = new GoogleMap();
const googleMapModalTotalizado = new GoogleMap();

const claseBotonCambio = 'flex items-center justify-center py-2 w-1/3 border h-full hover:scale-105'
const filasIniciales = 10

export const RutasAcceso = () => {
    const divMapaRegistroRef = useRef();
    const divMapaTotalizadoRef = useRef()
    const tablaMarcadoresRef = useRef()
    const inputMarcadorInicialRef = useRef(null)
    const inputMarcadorFinalRef = useRef(null)
    const isDrawingRuteRef = useRef(null)
    const eventoTrazadoRutaRef = useRef(null)
    const eventoSeleccionarMarcadorInicialRef = useRef(null)
    const eventoSeleccionarMarcadorFinalRef = useRef(null)
    const marcadorPuntoInicialRef = useRef(null)
    const marcadorPuntoFinalRef = useRef(null)
    const divDetalleRutaTotalizadaRef = useRef(null)
    const marcadoresRutaRef = useRef(null)
    
    const isMobile = useIsMobile()

    const [isOpenModalRegistro, openModalRegistro, closeModalRegistro] = useModal();
    const [isOpenModalTotalizado, openModalTotalizado, closeModalTotalizado] = useModal();

    const hookRutasAcceso = useRutasAcceso()
    
    const [coordandasCentroPoblado,setCoordenadasCentroPoblado] = useState(null)
    const [marcadorCentroPoblado,setMarcadorCentroPoblado] = useState(null)
    const [isDrawingRute,setIsDrawingRute] = useState(null)
    const [marcadoresRuta,setMarcadoresRuta] = useState([])
    const [marcadorPuntoInicial,setMarcadorPuntoInicial] = useState(null)
    const [seleccionandoPuntoInicial,setSeleccionandoPuntoInicial] = useState(false)
    const [marcadorPuntoFinal,setMarcadorPuntoFinal] = useState(null)
    const [seleccionandoPuntoFinal,setSeleccinandoPuntoFinal] = useState(false)
    const [estaCargando,setEstaCargando] = useState(false)
    const [codigoEntidad,setCodigoEntidad] = useState(0)
    const [nombreCentroPoblado,setNombreCentroPoblado] = useState('')
    const [verInfoRuta, setVerInfoRuta] = useState(false)
    const [paginaActual , setPaginaActual] = useState(1)
    const [cantidadFilasPorPagina,setCantidadFilasPorPagina] = useState(filasIniciales)
    const [cantidadPaginas,setCantidadPaginas] = useState(1)
    const [textoBuscador,setTextoBuscador] = useState('')

    const funcionesModalRegistro = {
        crearMarcadorCentroPoblado: function(coordenadasEntidad){
            const marcadorCreado = 
            googleMapModalRegistro.crearMarcador({    // CREA EL MARCADOR DEL CENTRO POBLADO
                latLng: coordenadasEntidad,
                icon: {
                    url: '/icon-location-green-home.png',
                    scaledSize: new google.maps.Size(30, 40), // Tamaño del marcador
                },
                // animation: true
            })
            setMarcadorCentroPoblado(marcadorCreado)
            googleMapModalRegistro.enfocarMarcador(marcadorCreado)
        },
        limpiarInformacion: () => {
            // LIMPIEZA DEL TRAZADO DE RUTA
            setMarcadoresRuta([])
            googleMapModalRegistro.quitarTodosLosMarcadoresExcepto(marcadorCentroPoblado)
            googleMapModalRegistro.quitarTodosLasPolilineas()

            // LIMPIEZA DEL GENERADO DE RUTA SUGERIDA
            setMarcadorPuntoInicial(null)
            setMarcadorPuntoFinal(null)
            setSeleccionandoPuntoInicial(false)
            setSeleccinandoPuntoFinal(false)
            googleMapModalRegistro.quitarTodosLosDirectionsDisplay()        // QUITA LAS RUTAS SUGERIDAS
            
            // LIMPIEZA DE LOS INPUTS DEL REGISTRO
            hookRutasAcceso.setRutaAccesoRegistro(hookRutasAcceso.rutaAccesoRegistroDefault)
        },
        inicializarEventoAutocomplete: (inputReferencia,marcadorReferencia,setMarcador) => {
            googleMapModalRegistro.agregarAutocomplete(inputReferencia.current, (place) => {
                const nombreLugar = place.name
                hookRutasAcceso.actualizarValorRutaAccesoRegistro(inputReferencia == inputMarcadorInicialRef ? 'puntoOrigen' : 'puntoDestino',nombreLugar)
                
                if(!isDrawingRuteRef.current) {    // SOLO GENERA MARCADOR CUANDO NO ESTA DIBUJANDO LA RUTA
                    marcadorReferencia.current && googleMapModalRegistro.quitarMarcador(marcadorReferencia.current)   // EN CASO EXISTA MARCADOR YA CREADO SE ELIMINA
                    const ubicacionElegida = {lat: place.geometry.location.lat(),lng: place.geometry.location.lng()}
                    const marcador = googleMapModalRegistro.crearMarcador({latLng: ubicacionElegida})
                    setMarcador(marcador)
                }
            })
        }
    }

    const funcionesTrazadoRuta = {
        generarMarcadorRecursivo: (soloMarcadoresRuta) => {
            !isMobile && tablaMarcadoresRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });   // ENFOCA LA TABLA EN EL ULTIMO REGISTRO (SOLO SI NO ES VISTA MOBILE)
            googleMapModalRegistro.quitarTodosLasPolilineas()   // QUITA TODOS LOS MARCADORES PARA NUEVA POLILINEA
            if (soloMarcadoresRuta.length != 0){     // GENERA POLILINEA
                googleMapModalRegistro.crearPolilinea({
                    listLatLng: soloMarcadoresRuta.map(data => ({lat: Number(data.lat) , lng: Number(data.lng)})),
                    strokeWeight: 3,
                    color: 'red'
                })
                const marcadoresFormateados = soloMarcadoresRuta.map(data => ( {lat: Number(data.lat) , lng: Number(data.lng)} ))
                const tiempoTotal = funcionesTrazadoRuta.calcularTiempoViajeAproximado(marcadoresFormateados)
                const distanciaTotal = funcionesTrazadoRuta.calcularDistanciaRecorrido(marcadoresFormateados)
                hookRutasAcceso.actualizarValorRutaAccesoRegistro('tiempoViajeMinutos',tiempoTotal)
                hookRutasAcceso.actualizarValorRutaAccesoRegistro('distancia',distanciaTotal)
            }
        },
        crearMarcador: (event) => {
            const marcador = googleMapModalRegistro.crearMarcador({
                latLng: event.latLng,
                icon: {
                    url: '/marcador-alfiler.png',
                    scaledSize: new google.maps.Size(40, 40), // Tamaño del marcador
                }
            })
            const objMarcadorNuevo = {
                lat: marcador.position.lat(),
                lng: marcador.position.lng(),
                ref: marcador
            }
            const nuevoArray = [...marcadoresRutaRef.current,objMarcadorNuevo]
            setMarcadoresRuta(nuevoArray)
            funcionesTrazadoRuta.generarMarcadorRecursivo(nuevoArray)
        },
        eliminarMarcador: (marcadorRef) => {
            googleMapModalRegistro.quitarMarcador(marcadorRef);
            const nuevoArray = [...marcadoresRutaRef.current].filter(elem => elem.ref != marcadorRef)
            setMarcadoresRuta(nuevoArray)
            funcionesTrazadoRuta.generarMarcadorRecursivo(nuevoArray)
        },
        calcularDistanciaRecorrido: (path) => { // formato path [{lat: ___ , lng: ___}]
            const totalDistance = path.reduce((acc, point, index) => {
                if (index === 0) return acc; // Salta el primer punto

                const previousPoint = path[index - 1];
                const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                    new window.google.maps.LatLng(previousPoint.lat, previousPoint.lng),
                    new window.google.maps.LatLng(point.lat, point.lng)
                );

                return acc + distance; // Suma la distancia al acumulador
            }, 0);
            
            return (totalDistance / 1000).toFixed(2); // Retorna la distancia total en kilómetros
        },
        calcularTiempoViajeAproximado: (path) => {
            const velocidadPromedio = 60        // (Km/h)
            const distanciaTotal = funcionesTrazadoRuta.calcularDistanciaRecorrido(path)
            const tiempoHoras = distanciaTotal / velocidadPromedio  // EN HORAS
            return parseInt(tiempoHoras * 60)   // RETORNA EL TIEMPO EN MINUTOS
        }
    }

    const funcionesModalTotalizado = {
        crearMarcadorCentroPoblado: function(coordenadasEntidad){       // FORMATO: {lat: __ , lng: __}
            const marcadorCentroPoblado = 
            googleMapModalTotalizado.crearMarcador({    // CREA EL MARCADOR DEL CENTRO POBLADO
                latLng: coordenadasEntidad,
                icon: {
                    url: '/icon-location-green-home.png',
                    scaledSize: new google.maps.Size(30, 40), // Tamaño del marcador
                },
                animation: true
            })
            googleMapModalTotalizado.enfocarMarcador(marcadorCentroPoblado)
        },
        crearPolilineaRuta: function(puntosPolilinea,codigoRuta){
            const polilinea = googleMapModalTotalizado.crearPolilineaConDireccion({
                listLatLng: puntosPolilinea,
                strokeOpacity: 1,
                strokeWeight: 5,
                color: getRandomColor()
            })
            polilinea.addListener('click', function(event) {
                isMobile && divDetalleRutaTotalizadaRef.current?.scrollIntoView({behavior: 'smooth',block: 'start',})
                hookRutasAcceso.obtenerDetalleRutaAcceso(codigoRuta)    // LLENA LAS INFORMACIONES DEL DETALLE DE LOS INPUTS
                setVerInfoRuta(true)      // MUESTRA LA VISTA DE LOS DETALLES DE LA RUTA
                googleMapModalTotalizado.polilineas.forEach((polilineaIterada) => {
                    if(polilineaIterada == polilinea){  // PARA VER LA POLILINEA DONDE SE DIO CLICK
                        polilineaIterada.setOptions({strokeOpacity: 1})
                    }else {
                        polilineaIterada.setOptions({strokeOpacity: 0.5})
                    }
                })
            })
        }
    }

    useEffect(() => {
        (async () => {
            await listarTabla()
        })()
    }, []);

    useEffect(() => {
        const cantidadFilas = hookRutasAcceso.centrosPoblados.length
        setCantidadPaginas(( (cantidadFilas > 0) && (cantidadFilas > cantidadFilasPorPagina) ) ? Math.round(cantidadFilas / cantidadFilasPorPagina) : 1)  // CALCULA LA CANTIDAD DE PAGINAS QUE DEBE TENER CON LA CONFIGURACION DADA
        setPaginaActual(1)      // INICIALIZA LA PAGINA DONDE SE ENCUENTRA
    },[hookRutasAcceso.centrosPoblados , cantidadFilasPorPagina])

    useEffect(() => {   // PARA MANTENER ACTUALIZADA LA REFERENCIA DEL PUNTO INICIAL CON EL ESTADO
        marcadorPuntoInicialRef.current = marcadorPuntoInicial
    },[marcadorPuntoInicial])

    useEffect(() => {   // PARA MANTENER ACTUALIZADA LA REFERENCIA DEL PUNTO FINAL CON EL ESTADO
        marcadorPuntoFinalRef.current = marcadorPuntoFinal
    },[marcadorPuntoFinal])

    useEffect(() => {   // PARA MANTENER ACTUALIZADA LA REFERENCIA DE LOS MARCADORES DE LA RUTA TRAZADA
        marcadoresRutaRef.current = marcadoresRuta
    },[marcadoresRuta])

    useEffect(() => {   // ALTERNA ENTRE LOS BOTONES DE TRAZAR Y GENERAR
        isDrawingRuteRef.current = isDrawingRute    // MANTIENE EL VALOR DEL REF INMUTABLE ACTUALIZADO PARA LOS EVENTOS
        if(googleMapModalRegistro.map && isDrawingRute !== null){
            funcionesModalRegistro.limpiarInformacion()
            marcadorCentroPoblado && googleMapModalRegistro.enfocarMarcador(marcadorCentroPoblado)
            if(isDrawingRute){
                eventoTrazadoRutaRef.current = googleMapModalRegistro.map.addListener('click',funcionesTrazadoRuta.crearMarcador)    // EVENTO PARA GUARDAR LOS MARCADORES OBTENER LOS PUNTOS
            }else{
                eventoTrazadoRutaRef.current?.remove()
            }
        }
    },[isDrawingRute])

    useEffect(() => {   // REALIZA LA RUTA SUGERIDA CON EL PUNTO INICIAL Y EL PUNTO FINAL
        if (marcadorPuntoInicial && marcadorPuntoFinal) {
            (async () => {
                googleMapModalRegistro.quitarTodosLosDirectionsDisplay();    // QUITA TODAS LAS RUTAS GENERADAS
                const directionsDisplay =
                await googleMapModalRegistro.agregarRutaDring(
                    {
                        lat: marcadorPuntoInicial.position.lat(),
                        lng: marcadorPuntoInicial.position.lng(),
                    },
                    {
                        lat: marcadorPuntoFinal.position.lat(),
                        lng: marcadorPuntoFinal.position.lng(),
                    },
                    googleMapModalRegistro.map
                )
                const ruta = directionsDisplay.directions.routes[0]
                const tiempoRuta = parseInt(ruta.legs[0].duration.value / 60)    // TIEMPO EN MINUTOS
                const distanciaRuta = (ruta.legs[0].distance.value / 1000).toFixed(2)   // DISTANCIA DE LA RUTA A KILOMETROS
                hookRutasAcceso.actualizarValorRutaAccesoRegistro('tiempoViajeMinutos',tiempoRuta)
                hookRutasAcceso.actualizarValorRutaAccesoRegistro('distancia',distanciaRuta)

                directionsDisplay.addListener('directions_changed', function(){ // LISTENER PARA EL CAMBIO DE LA RUTA
                    const nuevaRuta = directionsDisplay.directions.routes[0];
                    const tiempoNuevaRuta = parseInt(nuevaRuta.legs[0].duration.value / 60)    // TIEMPO NUEVA RUTA EN MINUTOS
                    const distanciaNuevaRuta = (nuevaRuta.legs[0].distance.value / 1000).toFixed(2)     // NUEVA DISTANCIA DE LA RUTA A KILOMETROS
                    hookRutasAcceso.actualizarValorRutaAccesoRegistro('tiempoViajeMinutos',tiempoNuevaRuta)
                    hookRutasAcceso.actualizarValorRutaAccesoRegistro('distancia',distanciaNuevaRuta)
                })
            })()
        }
    },[marcadorPuntoInicial,marcadorPuntoFinal])

    useEffect(() => {   // MODAL DE REGISTRO
        if (isOpenModalRegistro) {  // CUANDO ABRE EL MODAL DE REGISTRO
            googleMapModalRegistro.inicializarMapa(divMapaRegistroRef.current, {zoom: 12,ocultarMarcadores: false,cursor:"pointer"});
            setIsDrawingRute(true);

            (coordandasCentroPoblado.lat && coordandasCentroPoblado.lng) && funcionesModalRegistro.crearMarcadorCentroPoblado(coordandasCentroPoblado);
            funcionesModalRegistro.inicializarEventoAutocomplete(inputMarcadorInicialRef,marcadorPuntoInicialRef,setMarcadorPuntoInicial);
            funcionesModalRegistro.inicializarEventoAutocomplete(inputMarcadorFinalRef,marcadorPuntoFinalRef,setMarcadorPuntoFinal);
        }else{  // CUANDO CIERRA EL MODAL DE REGISTRO
            setIsDrawingRute(null)  // NO LO VUELVE NI TRUE NI FALSE
            funcionesModalRegistro.limpiarInformacion()
        }
    },[isOpenModalRegistro]);

    useEffect(() => {   // MODAL DE RUTAS TOTALES
        if(isOpenModalTotalizado){  // CUANDO ABRE EL MODAL DEL TOTALIZADO
            if(divMapaTotalizadoRef.current){
                (async () => {
                    setVerInfoRuta(false); // OCULTA LA VISTA DE LA INFORMACION DE LA RUTA AL ABRIR EL MODAL
                    googleMapModalTotalizado.inicializarMapa(divMapaTotalizadoRef.current, {zoom: 12,ocultarMarcadores: false,cursor:"pointer"});
                    const informacionEntidad = await hookRutasAcceso.listarRutasAccesoPorCentroPoblado(codigoEntidad);
                    // PARA UBICAR EL CENTRO POBLADO
                    const entidad = informacionEntidad.detalladoEntidad;
                    (entidad.latitud && entidad.longitud) && funcionesModalTotalizado.crearMarcadorCentroPoblado({lat: Number(entidad.latitud || 0) , lng: Number(entidad.longitud || 0)});
                    // PARA UBICAR LAS RUTAS CREADAS
                    const rutasAcceso = informacionEntidad.rutasAcceso;
                    rutasAcceso.forEach((data,i) => {
                        const puntosPolilinea   = data.puntosLineaMapa.split('*').map(elem => ({lat: Number(elem.split(',')[0]) , lng: Number(elem.split(',')[1])}))
                        const codigoRuta        = data.codEntidadRutaAcceso

                        funcionesModalTotalizado.crearPolilineaRuta(puntosPolilinea,codigoRuta)
                    })
                })()
            }
        }else{      // CUANDO CIERRA EL MODAL DEL TOTALIZADO

        }
    },[isOpenModalTotalizado])
    
    useEffect(() => {   // ALTERNA ENTRE LOS SELECCIONADORES DE MARCADOR INICIAL
        if(googleMapModalRegistro.map){
            if(seleccionandoPuntoInicial){
                eventoSeleccionarMarcadorInicialRef.current = googleMapModalRegistro.map.addListener('click',async (event) => {
                    marcadorPuntoInicial && googleMapModalRegistro.quitarMarcador(marcadorPuntoInicial)
                    const marcador = googleMapModalRegistro.crearMarcador({latLng: event.latLng});

                    const nombreLugar = await googleMapModalRegistro.obtenerLugarMarcador(marcador)
                    hookRutasAcceso.actualizarValorRutaAccesoRegistro('puntoOrigen',nombreLugar)
                    
                    setMarcadorPuntoInicial(marcador)   // LLENA EL MARCADOR EN EL ESTADO
                    setSeleccionandoPuntoInicial(false) // QUITA EL EFECTO DE SELECCION DEL PRIMER BOTON DE SELECCION
                    eventoSeleccionarMarcadorInicialRef.current.remove()   // ELIMINA EL EVENTO PARA QUE NO SELECCIONE OTRO MARCADOR
                })
            }else{
                eventoSeleccionarMarcadorInicialRef.current?.remove()
            }
        }
    },[seleccionandoPuntoInicial])

    useEffect(() => {   // ALTERNA ENTRE LOS SELECCIONADORES DE MARCADOR INICIAL
        if(googleMapModalRegistro.map){
            if(seleccionandoPuntoFinal){
                eventoSeleccionarMarcadorFinalRef.current = googleMapModalRegistro.map.addListener('click',async (event) => {
                    marcadorPuntoFinal && googleMapModalRegistro.quitarMarcador(marcadorPuntoFinal)
                    const marcador = googleMapModalRegistro.crearMarcador({latLng: event.latLng});

                    const nombreLugar = await googleMapModalRegistro.obtenerLugarMarcador(marcador)
                    hookRutasAcceso.actualizarValorRutaAccesoRegistro('puntoDestino',nombreLugar)

                    setMarcadorPuntoFinal(marcador)   // LLENA EL MARCADOR EN EL ESTADO
                    setSeleccinandoPuntoFinal(false) // QUITA EL EFECTO DE SELECCION DEL PRIMER BOTON DE SELECCION
                    eventoSeleccionarMarcadorFinalRef.current.remove()   // ELIMINA EL EVENTO PARA QUE NO SELECCIONE OTRO MARCADOR
                })
            }else{
                eventoSeleccionarMarcadorFinalRef.current?.remove()
            }
        }
    },[seleccionandoPuntoFinal])

    const listarTabla = async () => {
        setEstaCargando(true)
        await hookRutasAcceso.listarCentrosPoblados()
        setEstaCargando(false)
    }
    
    const registrarRutaAcceso = async () => {
        const obtenerPath = () => {
            if(isDrawingRute){
                return marcadoresRuta.map(data => `${data.lat},${data.lng}`).join('*') 
            }else{
                const directionDisplayRutaObtenida = googleMapModalRegistro.directionsDisplays[0]
                if(directionDisplayRutaObtenida){
                    const rutaObtenida = directionDisplayRutaObtenida.directions.routes[0]
                    return rutaObtenida.overview_path.map(data => `${data.lat()},${data.lng()}`).join('*')
                }else{
                    return ''
                }
            }
        }

        const path = obtenerPath()

        if(!path){
            notify('Trazar o generar una ruta de acceso','info')
            return
        }
        
        const response = await hookRutasAcceso.registrarRutaAcceso({
            puntosLineaMapa:        path,
            codigoEntidadRegistro:  codigoEntidad
        })

        notify(response.DesResultado , response.CodResultado == 1 ? 'success' : 'error')

        if(response.CodResultado == 1){
            await listarTabla()
            closeModalRegistro()
        }
    }

    const FilasRetorno = (data,i) => {
        return(
            <tr key={i+1}>
                <td data-label="Nº">{
                    textoBuscador
                    ?   (i + 1)                                                     // PARA CUANDO SE USE EL BUSCADOR
                    :   (i + 1) + (cantidadFilasPorPagina * (paginaActual - 1))     // CUANDO SE USA EL BUSCADOR Y SE TIENE QUE MOSTRAR LOS INDICES CON FORME A LAS PAGINAS
                }</td>
                <td data-label="CENTRO POBLADO">{data.nomEntidad}</td>
                <td data-label="DISTRITO">{data.nomDistrito}</td>
                <td data-label="PROVINCIA">{data.nomProvincia}</td>
                <td data-label="CANTIDAD RUTAS">{data.cantidadRutas}</td>
                <td data-label="TIEMPO PROMEDIO">{data.tiempoPromedio} hrs.</td>
                <td data-label="ESTADO">{data.nomEstado}</td>
                <td className="text-center ">
                    <div className="flex gap-2" onClick={() => setNombreCentroPoblado(data.nomEntidad)}>
                        <button
                            data-tip="Agregar ruta"
                            onClick={() => {
                                openModalRegistro();
                                setCodigoEntidad(data.codEntidad)
                                setCoordenadasCentroPoblado({
                                    lat: Number(data.latitud    || 0),
                                    lng: Number(data.longitud   || 0)
                                })
                            }}
                            className="bg-green-500 hover:bg-green-600 text-xs px-[5px] py-1 rounded w-[25px] h-[25px]"
                        >
                            <i className="fas fa-plus text-white"></i>
                        </button>
                        <ReactTooltip/>
                        <button 
                            data-tip="Ver rutas" 
                            onClick={() => {
                                setCodigoEntidad(data.codEntidad)
                                openModalTotalizado()
                            }} 
                            className="bg-blue-500 hover:bg-blue-600 text-xs px-[5px] py-1 rounded w-[25px] h-[25px]"
                        >
                            <i className="fa fa-map text-white"></i>
                        </button>
                        <ReactTooltip/>
                    </div>
                </td>
            </tr>
        )
    }

    return (
        <>
            <ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>

            <ContenedorParametros 
                titulo={"Rutas de Acceso"}
            ></ContenedorParametros>

            <div className="flex-grow flex flex-col gap-4 overflow-auto">
                <div className={`w-full h-full overflow-auto containerScroll flex flex-col`}>
                    {/* CONTENEDOR BUSCADOR */}
                    <div className="h-[40px] flex items-center justify-end py-2">
                        <div className="flex gap-4 items-center">
                            <i className="fas fa-search"></i>
                            <input 
                                value={textoBuscador}
                                onChange={e => setTextoBuscador(e.target.value)}
                                placeholder="(Busqueda)" 
                                className={classNameInput} 
                                type="text" 
                            />
                        </div>
                    </div>
                    {/* CONTENEDOR TABLA */}
                    <div className="flex-grow overflow-auto containerScroll">
                        <table className="tableResponsive lg:text-xs">
                            <thead>
                                <tr>
                                    <th className="w-[30px] text-left">Nº</th>
                                    <th className="text-left">NOM.CENTRO POBLADO</th>
                                    <th className="text-left">DISTRITO</th>
                                    <th className="text-left">PROVINCIA</th>
                                    <th className="text-left">CANTIDAD RUTAS</th>
                                    <th className="text-left">TIEMPO PROMEDIO</th>
                                    <th className="text-left">ESTADO</th>
                                    <th className="w-[60px]"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    hookRutasAcceso.centrosPoblados.length != 0 
                                    ?   hookRutasAcceso.centrosPoblados
                                        .filter(data => (data.nomEntidad).toLowerCase().includes(textoBuscador.toLowerCase())).length < 50
                                        && textoBuscador
                                        ?   hookRutasAcceso.centrosPoblados     // FILAS CON EL FILTRO APLICADO
                                            .filter(data => (data.nomEntidad).toLowerCase().includes(textoBuscador.toLowerCase()))
                                            .map((data,i) => FilasRetorno(data,i))
                                        :   hookRutasAcceso.centrosPoblados     // FILAS CON LA PAGINACIÓN
                                            .slice(
                                                (cantidadFilasPorPagina * (paginaActual - 1)),                              // INICIO DE PAGINA
                                                (cantidadFilasPorPagina * (paginaActual - 1)) + cantidadFilasPorPagina      // FIN DE PAGINA
                                            )
                                            .map((data,i) => FilasRetorno(data,i))
                                    :   <tr><td className="text-center" colSpan={8}>No hay información para mostrar...</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* CONTENEDOR PAGINADOR */}
                    <div className={`h-[40px] flex items-center py-2 justify-end ${textoBuscador && 'hidden'}`}>
                        <div className="flex gap-4 items-center lg:text-xs">
                            <select 
                                value={cantidadFilasPorPagina} 
                                onChange={e => setCantidadFilasPorPagina(Number(e.target.value))} 
                                className={`${classNameInput} w-[100px] lg:w-auto`}
                            >
                                <option value={filasIniciales}>{filasIniciales}</option>
                                <option value={filasIniciales * 2}>{filasIniciales * 2}</option>
                                <option value={filasIniciales * 3}>{filasIniciales * 3}</option>
                                <option value="TODOS">TODOS</option>
                            </select>
                            <button 
                                className="w-[30px] h-[30px] rounded border hover:bg-gray-500" 
                                title="Anterior página" 
                                onClick={e => setPaginaActual(Number( ((paginaActual - 1) < 1) ? cantidadPaginas : (paginaActual - 1) ))}
                            >
                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                            </button>
                            <div className="flex gap-2 whitespace-nowrap items-center">
                                <input 
                                    value={paginaActual} 
                                    onChange={e => {
                                        setPaginaActual(Number(
                                            e.target.value > cantidadPaginas
                                                ?   1
                                                :   e.target.value < 1
                                                    ?   cantidadPaginas
                                                    :   e.target.value
                                        ));
                                    }} 
                                    type="number" 
                                    className={`${classNameInput} w-[80px]`} 
                                />
                                de {cantidadPaginas}
                            </div>
                            <button 
                                className="w-[30px] h-[30px] rounded border hover:bg-gray-500" 
                                title="Siguiente página" 
                                onClick={e => setPaginaActual(Number( ((paginaActual + 1) > cantidadPaginas) ? 1 : (paginaActual + 1) ))}
                            >
                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL REGISTRO RUTA */}
            <Modal
                title={`Nueva ruta de acceso [${nombreCentroPoblado}]`}
                isOpen={isOpenModalRegistro}
                closeModal={() => {
                    closeModalRegistro();
                }}
                action={() => {
                    registrarRutaAcceso()
                }}
            >
                <div className="w-[80vw] lg:w-full h-[600px] lg:h-auto flex lg:flex-col gap-5">
                    <div className="w-1/3 lg:w-full flex flex-col gap-4 text-sm">
                        <div className="flex items-center w-full justify-center">
                            <button 
                                onClick={() => setIsDrawingRute(true)} 
                                className={`${isDrawingRute ? 'bg-white text-black' : 'bg-[#2f3134]'} ${claseBotonCambio} rounded-l-md`}
                            >Trazar ruta</button>
                            <button 
                                onClick={() => setIsDrawingRute(false)} 
                                className={`${!isDrawingRute ? 'bg-white text-black' : 'bg-[#2f3134]'} ${claseBotonCambio} rounded-r-md`}
                            >Generar ruta</button>
                        </div>
                        <div className="grid grid-cols-[auto_1fr] lg:grid-cols-1 gap-2 lg:gap-1">
                            <label className="text-[#2a6ba8] font-bold">Punto origen</label>
                            <div className="w-full flex gap-2 items-center">
                                <div className="flex-grow relative">
                                    <input 
                                    ref={inputMarcadorInicialRef} 
                                    value={hookRutasAcceso.rutaAccesoRegistro.puntoOrigen}
                                    onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('puntoOrigen',e.target.value)}
                                    disabled={!isDrawingRute && seleccionandoPuntoFinal ? true : false} 
                                    type="text" 
                                    className={classNameInput} 
                                    />
                                    {
                                        !isDrawingRute && seleccionandoPuntoInicial ? 
                                            <div 
                                                className={`w-full absolute left-0 top-0 text-center bg-red-600`}
                                            >
                                                Seleccionar en el mapa
                                            </div> 
                                        : <></>
                                    }
                                </div>
                                {
                                    !isDrawingRute ? 
                                        <>
                                            <button 
                                                data-tip="Click para seleccionar en el mapa"
                                                onClick={() => {
                                                    if(!seleccionandoPuntoFinal){
                                                        setSeleccionandoPuntoInicial(!seleccionandoPuntoInicial)
                                                    }else{
                                                        notify('Terminar de elegir el punto final en el mapa','info')
                                                    }
                                                }}
                                                className={`${seleccionandoPuntoInicial ? 'bg-blue-600' : 'hover:bg-blue-300'} border rounded px-2`}
                                            >
                                                <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                            </button>
                                            <ReactTooltip/>
                                        </>
                                    :   <></>
                                }
                            </div>

                            <label className="text-[#2a6ba8] font-bold">Punto destino</label>
                            <div className="w-full flex gap-2 items-center">
                                <div className="flex-grow relative">
                                    <input 
                                        ref={inputMarcadorFinalRef} 
                                        value={hookRutasAcceso.rutaAccesoRegistro.puntoDestino}
                                        onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('puntoDestino',e.target.value)}
                                        disabled={!isDrawingRute && seleccionandoPuntoInicial ? true : false} 
                                        type="text" 
                                        className={classNameInput} 
                                    />
                                    {
                                        !isDrawingRute && seleccionandoPuntoFinal ? 
                                            <div 
                                                className={`w-full absolute left-0 top-0 text-center bg-red-600`}
                                            >
                                                Seleccionar en el mapa
                                            </div> 
                                        : <></>
                                    }
                                </div>
                                {
                                    !isDrawingRute ? 
                                        <>
                                            <button 
                                                data-tip="Click para seleccionar en el mapa"
                                                onClick={() => {
                                                        if(!seleccionandoPuntoInicial){
                                                            setSeleccinandoPuntoFinal(!seleccionandoPuntoFinal)
                                                        }else{
                                                            notify('Terminar de elegir el punto inicial en el mapa','info')
                                                        }
                                                }}
                                                className={`${seleccionandoPuntoFinal ? 'bg-blue-600' : 'hover:bg-blue-300'} border rounded px-2`}
                                            >
                                                <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                            </button>
                                            <ReactTooltip/>
                                        </>
                                    :   <></>
                                }
                            </div>

                            <label className="text-[#2a6ba8] font-bold">Tipo de transporte</label>
                            <input 
                                value={hookRutasAcceso.rutaAccesoRegistro.tipoTransporte} 
                                onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('tipoTransporte',e.target.value)} 
                                type="text" 
                                className={classNameInput} 
                            />

                            <label className="text-[#2a6ba8] font-bold">Temporada</label>
                            <input 
                                value={hookRutasAcceso.rutaAccesoRegistro.temporada}
                                onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('temporada',e.target.value)}
                                type="text" 
                                className={classNameInput} 
                            />

                            <label className="text-[#2a6ba8] font-bold">Distancia <span className="text-xs text-gray-400">(Km.)</span>: </label>
                            <input
                                value={hookRutasAcceso.rutaAccesoRegistro.distancia}
                                onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('distancia',e.target.value)}
                                className={`${classNameInput}`}
                                disabled
                            />

                            <label className="text-[#2a6ba8] font-bold">Tiempo de viaje <span className="text-xs text-gray-400">(min.)</span>: </label>
                            <input
                                value={hookRutasAcceso.rutaAccesoRegistro.tiempoViajeMinutos}
                                onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('tiempoViajeMinutos',e.target.value)}
                                className={`${classNameInput}`}
                                disabled
                            />

                            <label className="text-[#2a6ba8] font-bold">Comentario</label>
                            <textarea
                                value={hookRutasAcceso.rutaAccesoRegistro.comentario}
                                onChange={e => hookRutasAcceso.actualizarValorRutaAccesoRegistro('comentario',e.target.value)}
                                className={`${classNameInput} h-[50px]`}
                            ></textarea>
                        </div>
                        <div className={`flex-grow flex flex-col gap-2 overflow-auto ${isDrawingRute ? '' : 'hidden'}`}>
                            <div className="text-[#2a6ba8] font-bold">Puntos de ruta trazada:</div>
                            <div className="flex-grow lg:max-h-[100px] overflow-auto containerScroll">
                                <table ref={tablaMarcadoresRef} className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Nº</th>
                                            <th className="text-left">COORDENADAS</th>
                                            <th className="text-left"></th>
                                            <th className="text-left w-[50px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            marcadoresRuta.length != 0 ?
                                                marcadoresRuta.map((data,i) => {
                                                    return(
                                                        <tr key={i+1}>
                                                            <td>{i+1}</td>
                                                            <td><div className="lg:overflow-hidden lg:whitespace-nowrap lg:text-ellipsis lg:max-w-[100px]">{data.lat} ; {data.lng}</div></td>
                                                            <td className="w-[30px]">
                                                                <button 
                                                                    data-tip="Ver en el mapa"
                                                                    className="w-[25px] h-[25px] rounded bg-blue-600" 
                                                                    onClick={() => googleMapModalRegistro.enfocarMarcador(data.ref)}
                                                                >
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                                <ReactTooltip/>
                                                            </td>
                                                            <td className="w-[30px]">
                                                                <button 
                                                                    data-tip="Eliminar marcador"
                                                                    className="w-[25px] h-[25px] rounded bg-red-600" 
                                                                    onClick={() => funcionesTrazadoRuta.eliminarMarcador(data.ref)}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                                <ReactTooltip/>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            :   <tr><td colSpan={4} className="text-center">No hay marcadores para mostrar...</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="relative w-2/3 lg:w-full lg:h-[300px] flex flex-col justify-center">
                        <div className={` absolute left-1/2 bottom-0 whitespace-nowrap lg:whitespace-normal w-full text-center -translate-x-1/2  z-10 text-black font-bold text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]`}>
                            {isDrawingRute ? 'Hacer click en los puntos de paso de la ruta' : 'Ubicar los puntos de inicio y fin para generar la ruta'}
                        </div>
                        <div
                            className="w-full h-full cursor-pointer"
                            ref={divMapaRegistroRef}
                        ></div>
                    </div>
                </div>
            </Modal>

            {/* MODAL RUTAS GENERALES */}
            <Modal
                title={`Rutas de acceso [${nombreCentroPoblado}]`}
                isOpen={isOpenModalTotalizado}
                closeModal={closeModalTotalizado}
                validButton={{}}
            >
                <div className="w-[50vw] lg:w-full h-[600px] lg:h-[50vh] flex lg:flex-col flex- gap-4">
                    <div className="lg:w-full h-full relative flex-shrink-0 flex-grow">
                        <div ref={divMapaTotalizadoRef} className="w-full h-full"></div>
                        <div className={`absolute left-1/2 bottom-0 whitespace-nowrap lg:whitespace-normal w-full text-center -translate-x-1/2  z-10 text-black font-bold text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]`}>
                            Hacer click en la ruta para ver su información
                        </div>
                    </div>
                    <div ref={divDetalleRutaTotalizadaRef} className={`w-1/3 lg:w-full h-full flex-col gap-2 ${verInfoRuta ? 'flex' : 'hidden'}`}>
                        <span className="text-sm text-[#2a6ba8] font-bold">Punto origen:</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.puntoOrigen} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Punto destino:</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.puntoDestino} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Tipo de transporte:</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.tipoTransporte} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Temporada:</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.temporada} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Distancia (Km.):</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.distancia} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Tiempo de viaje (min.):</span>
                        <input disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.tiempoViajeMinutos} type="text" className={classNameInput} />
                        <span className="text-sm text-[#2a6ba8] font-bold">Comentario:</span>
                        <div>
                            <textarea disabled defaultValue={hookRutasAcceso.detalleRutaAcceso.comentario} className={`${classNameInput} h-[100px]`}></textarea>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
