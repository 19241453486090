import React, { useState, useEffect, useRef, useMemo } from "react";
import { GoogleMap } from "../../../utils/googlemaps";
import { useEntidades } from "../../../hooks/useEntidades";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";
import iconoCP from "../../../assets/images/IcoUbicacionCP.png";
import { useRegiones } from "../../../hooks/useRegiones";
import { ComponenteCarga } from "../../components/forms/ComponenteCarga";

const mapInstance = new GoogleMap();

export const CatastroCP = () => {
    const classNameInput =
        "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500";

    const mapDiv = useRef(null);
    const [googleMap, setGoogleMap] = useState(null);

    const { entidades, listarEntidadesPersonalizado } = useEntidades();
    const [paises,obtenerPaises,departamentos,obtenerDepartamentos,provincias,obtenerProvincias,distritos,obtenerDistritos,] = useRegiones();
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState(0);
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(0);
    const [distritoSeleccionado, setDistritoSeleccionado] = useState(0);
    const [mostrarMarcadores, setMostrarMarcadores] = useState(true); // Estado para controlar la visibilidad de los marcadores
    const [estaCargando,setEstaCargando] = useState(false)

    useEffect(() => {
        mapInstance.inicializarMapa(mapDiv.current, { zoom: 6 });
        setGoogleMap(mapInstance);

        const funcionReady = async () => {
            setEstaCargando(true)
            await listarEntidadesPersonalizado(2023);
            await obtenerPaises();
            await obtenerDepartamentos();
            setEstaCargando(false)
        }
        funcionReady()
    }, []);

    useEffect(() => {
        if (googleMap && googleMap.marcadores) {
            googleMap.marcadores.forEach((marcador) => {
                marcador.setVisible(mostrarMarcadores);
            });
        }
    }, [mostrarMarcadores, googleMap]);

    useEffect(() => {
        obtenerProvincias(departamentoSeleccionado);
        setProvinciaSeleccionada(0);
    }, [departamentoSeleccionado]);

    useEffect(() => {
        obtenerDistritos(provinciaSeleccionada);
        setDistritoSeleccionado(0);
    }, [provinciaSeleccionada]);

    useEffect(() => {
        entidades.length != 0 && filtrarYMostrarEntidades();
    }, [
        departamentoSeleccionado,
        provinciaSeleccionada,
        distritoSeleccionado,
        entidades,
    ]);

    useEffect(() => {
        if (departamentoSeleccionado !== "0") {
            const datosDepartamento = departamentos.find(
                (d) => d.codDepartamento.toString() === departamentoSeleccionado
            );
            if (
                datosDepartamento &&
                datosDepartamento.puntosPoligonoDepartamento
            ) {
                dibujarPoligono(
                    datosDepartamento?.puntosPoligonoDepartamento,
                    "#193DFF"
                ); // Negro
            }
        } else {
            dibujarPoligono(paises[0]?.puntosPoligonoPais, "#193DFF"); // Negro
        }
    }, [departamentoSeleccionado, departamentos]);

    useEffect(() => {
        if (provinciaSeleccionada !== "0") {
            const datosProvincia = provincias.find(
                (d) => d.codProvincia.toString() === provinciaSeleccionada
            );
            if (datosProvincia && datosProvincia.puntosPoligonoProvincia) {
                dibujarPoligono(
                    datosProvincia?.puntosPoligonoProvincia,
                    "#193DFF"
                ); // Negro
            }
        } else {
            const datosDepartamento = departamentos.find(
                (d) => d.codDepartamento.toString() === departamentoSeleccionado
            );
            if (
                datosDepartamento &&
                datosDepartamento.puntosPoligonoDepartamento
            ) {
                dibujarPoligono(
                    datosDepartamento?.puntosPoligonoDepartamento,
                    "#193DFF"
                ); // Negro
            }
        }
    }, [provinciaSeleccionada, provincias]);

    useEffect(() => {
        if (distritoSeleccionado !== "0") {
            const datosDistrito = distritos.find(
                (d) => d.codDistrito.toString() === distritoSeleccionado
            );
            if (datosDistrito && datosDistrito.puntosPoligonoDistrito) {
                dibujarPoligono(
                    datosDistrito?.puntosPoligonoDistrito,
                    "#193DFF"
                ); // Negro
            }
        } else {
            const datosProvincia = provincias.find(
                (d) => d.codProvincia.toString() === provinciaSeleccionada
            );
            if (datosProvincia && datosProvincia.puntosPoligonoProvincia) {
                dibujarPoligono(
                    datosProvincia?.puntosPoligonoProvincia,
                    "#193DFF"
                ); // Negro
            }
        }
    }, [distritoSeleccionado, distritos]);

    const generarColorAleatorio = () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    };

    const filtrarYMostrarEntidades = () => {            // ESTA DIBUJANDO TODOS LOS POLIGONOS CON CADA INTERACCION POR EL USEEFFECT
        if (googleMap) {
            googleMap.quitarTodosLosMarcadores();
            googleMap.quitarTodosLosPoligonos();
        }else{
            console.error("googleMap no está inicializado.");
            return;
        }

        const entidadesFiltradas = entidades.filter((entidad) =>
            (departamentoSeleccionado == 0  || entidad.codDepartamento == departamentoSeleccionado) &&
            (provinciaSeleccionada == 0     || entidad.codProvincia == provinciaSeleccionada)       &&
            (distritoSeleccionado == 0      || entidad.codDistrito == distritoSeleccionado)
        );

        // console.log("entidadesFiltradas -> ",entidadesFiltradas)
        console.log("cantidad de entidades -> ",entidadesFiltradas.length)

        const infoWindow = googleMap.crearInfowindow({});
        entidadesFiltradas.forEach((entidad) => {
            const marcador = googleMap.crearMarcador({
                latLng: { lat: entidad.latitud, lng: entidad.longitud },
                icon: iconoCP,
            });
            marcador.addListener("click", () => {
                infoWindow.setContent(
                    `
                    <div style="font-size: 14px; line-height: 1.5;">
                    <h2 style="margin-bottom: 5px; font-size: 20px; text-decoration: bold; text-align: center;">${
                        entidad.nomEntidad
                    }</h2>
                        <p><strong>MCP:</strong> ${entidad.mcp}</p> 
                        <p><strong>DDN:</strong> ${entidad.ddn}</p>
                        <p><strong>Dispositivo Legal de Creación:</strong> ${
                            entidad.dispositivoLegalCreacion || "No disponible"
                        }</p>
                        <p><strong>Alcalde:</strong> ${
                            entidad.nomResponsable
                        }</p>
                        <p><strong>Telefonos:</strong> ${
                            entidad.numeroCelular
                        } ${entidad.telefonoFijoUno} ${
                        entidad.telefonoFijoDos
                    } ${entidad.telefonoFijoTres}</p>
                        <p><strong>Ubicación:</strong> ${
                            entidad.nomDepartamento
                        }, ${entidad.nomProvincia}, ${entidad.nomDistrito}</p>
                        <p><strong>Población:</strong> INEI: ${
                            entidad.cantidadPobladorRegistroINEI
                        } - REGISTRADA: ${
                        entidad.cantidadPobladorRegistroManual
                    } - EMPADRONADA: ${entidad.cantidadRealEmpadronados}   </p>
                    </div>
                    `
                );
                infoWindow.open({
                    anchor: marcador,
                    map: googleMap.map,
                });
            });

            if (entidad.poligonoJurisdiccion) {
                const puntosPoligono = entidad.poligonoJurisdiccion
                    .split(",")
                    .map((coord) => {
                        const [lat, lng] = coord.split(";").map(Number);
                        return { lat, lng };
                    });

                const poligonoEntidad = googleMap.crearPoligono({
                    id: entidad.codEntidad,
                    listLatLng: puntosPoligono,
                    color: generarColorAleatorio(),
                });
            }
        });
    };

    const dibujarPoligono = (puntos, color) => {
        googleMap.quitarTodosLosPoligonos()

        if (googleMap && puntos) {
            const puntosPoligono = puntos.split(",").map((coord) => {
                const [lat, lng] = coord.split(";").map(Number);
                return { lat, lng };
            });

            const poligono = googleMap.crearPoligono({
                listLatLng: puntosPoligono,
                color: color,
            });

            return poligono;
        }
    };

    return (
        <>
            <ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>
            <div className="w-full h-full flex flex-col">
                <ContenedorParametros titulo="Centros Poblados">
                    <div>
                        <select
                            className={`${classNameInput}`}
                            value={departamentoSeleccionado}
                            onChange={(e) =>
                                setDepartamentoSeleccionado(e.target.value)
                            }
                        >
                            <option value="0">-- Todos --</option>
                            {departamentos.map((d) => {
                                return (
                                    <option
                                        key={d.codDepartamento}
                                        value={d.codDepartamento}
                                    >
                                        {d.nomDepartamento}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <select
                            className={`${classNameInput}`}
                            value={provinciaSeleccionada}
                            onChange={(e) =>
                                setProvinciaSeleccionada(e.target.value)
                            }
                        >
                            <option value="0">-- Todos --</option>
                            {provincias.map((p) => {
                                return (
                                    <option
                                        key={p.codProvincia}
                                        value={p.codProvincia}
                                    >
                                        {p.nomProvincia}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <select
                            className={`${classNameInput}`}
                            value={distritoSeleccionado}
                            onChange={(e) =>
                                setDistritoSeleccionado(e.target.value)
                            }
                        >
                            <option value="0">-- Todos --</option>
                            {distritos.map((d) => {
                                return (
                                    <option
                                        key={d.codDistrito}
                                        value={d.codDistrito}
                                    >
                                        {d.nomDistrito}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </ContenedorParametros>

                <div className="mapa-incidentes flex-1">
                    <button
                        onClick={() => {
                            setMostrarMarcadores(!mostrarMarcadores);

                            if (googleMap && googleMap.marcadores) {
                                googleMap.marcadores.forEach((marcador) => {
                                    marcador.setVisible(!mostrarMarcadores);
                                });
                            }
                        }}
                        className={` ${
                            !mostrarMarcadores
                                ? " bg-red-800 hover:bg-red-500 "
                                : " bg-green-800 hover:bg-green-500 "
                        } px-3 p-2 rounded absolute bottom-[150px] right-2 z-50`}
                    >
                        <i
                            className="text-3xl fa fa-map-marker"
                            aria-hidden="true"
                        ></i>
                        {!mostrarMarcadores && (
                            <i
                                className="absolute top-5 right-3 text-xl text-gray-400 fa fa-ban"
                                aria-hidden="true"
                            ></i>
                        )}
                    </button>

                    {/* <div className="absolute w-fit p-10 z-50 bg-gray-600 top-0 right-0">ENTIDADES</div> */}
                    
                    <div className="w-full h-full" ref={mapDiv}></div>
                </div>
            </div>
        </>
    );
};
