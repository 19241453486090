import React, { useState, useEffect, useRef,useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../.././../hooks/useModal';
import { notify, soloCelular } from '../../../utils/utils'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { AuthFetch } from '../../../services/api';
import { useEntidades } from '../../../hooks/useEntidades';

import '../../../assets/css/views/maps.css'

import { GoogleMap } from '../../../utils/googlemaps';
import { useRegiones } from '../../../hooks/useRegiones';
import SwitchTest from '../../components/buttons/SwitchTest';
import iconoEntidad from '../../../assets/images/iconoControles.png'
import SelectorMultiple from '../../components/forms/SelectorMultiple';
import Select2Plamin from '../../components/forms/Select2Plamin';
import { UserContext } from '../../../context/provider/UserProvider';

const google = window.google;

const infowindow = new google.maps.InfoWindow();

const googleMap = new GoogleMap();
const googleMapModal = new GoogleMap();

let drawingManager1
let ultimoMarcador = null
let ultimoPoligono = null


export const Entidades = () => {
    let poligonoEntidadPadre = ''
    const classNameInput = "caret-input text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
    const jurisdiccionPoliticaDefault = {
        departamento:0,
        provincia:0,
        distrito:0
    }
    
    const {stateUser} = useContext(UserContext)
    const mapDiv = useRef();
    const mapDivModal = useRef()

    const {
        entidades,
        listarEntidades,
        guardareditarEntidad, 
        entidad, 
        editarvalorEntidad, 
        obtenerEntidad, 
        isOpen, 
        openModal,
        closeModal,
        setEntidades,
        entidadesBuscador,
        tiposEntidades,
        instituciones,
        tipoAlertasPorEntidad,
        eliminarEntidad,
    } = useEntidades();
    const [ paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ] = useRegiones();
    const [isOpenModalEliminar,openModalEliminar,closeModalEliminar] = useModal()

    const [valueRender,setValueRender] = useState(false)    //para evitar que las funciones se ejecuten con el primer renderizado
    const [tipoVista,setTipoVista] = useState(false)
    const [numeroTelefonos,setNumeroTelefonos] = useState(1)
    const [valorDireccion,setValorDireccion] = useState('')
    const [valorJurisdiccion,setValorJurisdiccion] = useState('')
    const [entidadEliminar,setEntidadEliminar] = useState('')
    const [tipoJurisdiccion,setTipoJurisdiccion] = useState(0)
    const [jurisPolitica,setJurisPolitica] = useState(jurisdiccionPoliticaDefault)

    useEffect(() => {
        (async () => {
            await listarEntidades();
        })()

        obtenerDepartamentos()
        googleMap.inicializarMapa(mapDiv.current, { zoom: 12 });
        crearDrawingManager()
        setValueRender(true)
    },[])

    useEffect(() => {
        if(valueRender){
            obtenerProvincias(jurisPolitica.departamento)
        }
    },[jurisPolitica.departamento])

    useEffect(() => {
        if(valueRender){
            obtenerDistritos(jurisPolitica.provincia)
        }
    },[jurisPolitica.provincia])

    useEffect(() => {
        if(valueRender){
            editarvalorEntidad("latitud",valorDireccion.split('|')[0])
            editarvalorEntidad("longitud",valorDireccion.split('|')[1])
        }
    },[valorDireccion])

    useEffect(() => {
        if(valueRender){
            editarvalorEntidad("poligonoJurisdiccion",valorJurisdiccion)
        }
    },[valorJurisdiccion])

    useEffect(() => {
        if(valueRender){
            let jsonPintar
    
            if(jurisPolitica.departamento == 0){
                googleMapModal.quitarTodosLosPoligonos()
            }else{
                if(jurisPolitica.provincia == 0){
                    //solo dibuja departamento
                    const infoPintar = departamentos.find(elem => elem.codDepartamento == jurisPolitica.departamento)
                    setValorJurisdiccion(infoPintar.puntosPoligonoDepartamento)
                    jsonPintar = devolverJson(infoPintar.puntosPoligonoDepartamento)

                }else{
                    if(jurisPolitica.distrito == 0){
                        //solo dibuja provincia
                        const infoPintar = provincias.find(elem => elem.codProvincia == jurisPolitica.provincia)
                        setValorJurisdiccion(infoPintar.puntosPoligonoProvincia)
                        jsonPintar = devolverJson(infoPintar.puntosPoligonoProvincia)
                    }else{
                        //solo dibuja distrito
                        const infoPintar = distritos.find(elem => elem.codDistrito == jurisPolitica.distrito)
                        setValorJurisdiccion(infoPintar.puntosPoligonoDistrito)
                        jsonPintar = devolverJson(infoPintar.puntosPoligonoDistrito)
                    }
                }

                googleMapModal.quitarTodosLosPoligonos()

                googleMapModal.crearPoligono({
                    "listLatLng": jsonPintar,
                })
    
                // para centrar el mapa en los limites del poligono
                const bounds = new window.google.maps.LatLngBounds();
                jsonPintar?.forEach(coord => {
                    bounds.extend(coord);
                });
                googleMapModal.map.fitBounds(bounds);
            }
        }
    },[jurisPolitica])

    useEffect(() => {
        if(tipoVista){
            mostrarEntidadesMapa()
        }
    },[tipoVista])

    useEffect(() => {
        if(valueRender){
            if(isOpen){     //para cargar el mapa cuando se abre un modal de editado
                googleMapModal.inicializarMapa(mapDivModal.current, { zoom: 12 })
                drawingManager1.setMap(googleMapModal.map)
                drawingManager1.setDrawingMode(null)
                if(entidad.latitud && entidad.longitud){
                    let marker = googleMapModal.crearMarcador({
                        latLng:{
                            lat:entidad.latitud,
                            lng:entidad.longitud
                        },
                    })
                    googleMapModal.map.panTo(marker.getPosition());
                }
                if(entidad.poligonoJurisdiccion.length != 0){
                    //aca dibujar el poligono con la jurisdiccion en caso tenga
                    let jsonTest = devolverJson(entidad.poligonoJurisdiccion)
                    googleMapModal.crearPoligono({
                        listLatLng: jsonTest
                    })
                }


                valoresXDefectoJson()
            }
        }
    },[isOpen])


    const mostrarEntidadesMapa = () => {
        googleMap.quitarTodosLosMarcadores()
        googleMap.quitarTodosLosPoligonos()

        let validadorEntidades = false
        if(entidades.length <= 5){
            validadorEntidades = true
        }

        entidades.map((data,i) => {
            if(data.latitud && data.longitud){
                const marcadorTest = {
                    lat: data.latitud,
                    lng: data.longitud
                }
                const marcadorEntidad = googleMap.crearMarcador({
                    latLng: marcadorTest,
                    // icon: iconoEntidad
                })
                const infoWindow = googleMap.crearInfowindow({});

                const generarInfoWindow = () => {
                    infoWindow.setContent(`<div>ENTIDAD: ${data.nomEntidad}</div>`);
                    infoWindow.open({
                        anchor: marcadorEntidad,
                        map: googleMap.map,
                        isOpen: true
                    })
                }

                if(validadorEntidades){
                    generarInfoWindow()
                }

                marcadorEntidad.addListener('click', () => {
                    generarInfoWindow()
                },false)
            }

            const colorRandom = () => {
                const letras = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                  color += letras[Math.floor(Math.random() * 16)];
                }
                return color;
            }

            if(data.poligonoJurisdiccion){ //valida que exista
                const jsonTest = devolverJson(data.poligonoJurisdiccion)

                googleMap.crearPoligono({
                    listLatLng: jsonTest,
                    color: i==0 ? 'red' : colorRandom()
                })

                if(i == 0){
                    googleMap.updateCenterAndZoom(jsonTest)
                }

            }
        })
    }

    const valoresXDefectoJson = () => {
        const jsonTest = []

        //para cambiar el valor de las alertas por defecto por las traidas por base de datos
        editarvalorEntidad('parametrosTipoAlertaDetalle',entidad.codAlertaTipoConcatenado)
        
        entidad.codAlertaTipoConcatenado?.split(',').map((data,i) => {
            if(tipoAlertasPorEntidad.some(elem => elem.codAlertaTipo == data)){
                jsonTest.push(tipoAlertasPorEntidad.find(elem => elem.codAlertaTipo == data))
            }
        })
        return jsonTest
    }

    const devolverJson = (cadena) => {
        let json = []
        cadena.split(',').map((data,i) => {
            let temp = {
                lat: Number(data.split(';')[0]),
                lng: Number(data.split(';')[1])
            }
            json.push(temp)
        })
        return json
    }

    const editarvalorJurisPolitica = (key, value) => {
        setJurisPolitica(juris => {
            return {
                ...juris,
                [key]:value
            }
        });
    } 

    const crearDrawingManager = () => {
        drawingManager1 = new window.google.maps.drawing.DrawingManager({
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                drawingModes: [
                    google.maps.drawing.OverlayType.MARKER,   // Habilita dibujar marcadores
                    google.maps.drawing.OverlayType.POLYGON,  // Habilita dibujar polígonos
                ],
            },
            polygonOptions: {
                fillColor: '#42a7f1', 
                strokeColor: '#3454ff',
            },
        })
    }

    const validarJuridiccion = (valorSelect) => {
        setValorJurisdiccion('')
        poligonoEntidadPadre && googleMapModal.quitarTodosLosPoligonosExcepto(poligonoEntidadPadre)

        switch (Number(valorSelect)) {
            case 0: //nada
                drawingManager1.setDrawingMode(null)
                break;
            case 1: //libre
                if(valorJurisdiccion.length == 0){
                    drawingManager1.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);

                    google.maps.event.addListener(drawingManager1, 'overlaycomplete', function(event) {
                        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                            ultimoPoligono = event.overlay
                            const vertices = event.overlay.getPath().getArray()
                            const verticesConcatenados = [
                                ...vertices.map((data) => `${data.lat()};${data.lng()}`),
                                `${vertices[0].lat()};${vertices[0].lng()}`
                            ]
                            setValorJurisdiccion(verticesConcatenados.join(','))
                            
                            // let concatVertices = ''
                            // vertices.map((data) => {
                            //     concatVertices += `${data.lat()};${data.lng()},`
                            // })
                            // concatVertices = concatVertices.slice(0,concatVertices.length-1)
                            // setValorJurisdiccion(concatVertices)

                            drawingManager1.setDrawingMode(null);
                        }
                    })
                }
                break;
            case 2: //division politica
                drawingManager1.setDrawingMode(null)
                setJurisPolitica(jurisdiccionPoliticaDefault)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className='w-full h-full flex flex-col'>
                <ContenedorParametros
                    titulo={'Entidades'}
                    tablaId={'tabla-entidades'}
                >
                    {/* <div className='flex items-center text-xl'>
                        <i className="fa fa-building mr-3" aria-hidden="true"></i>

                        <select className={classNameInput}>
                            <option value={0}>-- Todos --</option>
                        </select>
                    </div> */}

                    <div className='flex gap-4 items-center justify-center'>
                        <BotonNuevo children={'Nuevo'} onClick = {async () => {
                            await obtenerEntidad()
                            setNumeroTelefonos(1)

                            drawingManager1.setMap(googleMapModal.map)
                            drawingManager1.setDrawingMode(null)
                        }}>
                        </BotonNuevo>

                        <BotonProcesar onClick={() => listarEntidades()}></BotonProcesar>
                        
                        <div className='flex gap-2 items-center justify-center'>
                            <div className='lg:text-xs'>Tipo de vista:</div>
                            <SwitchTest value={tipoVista} action={() => {
                                setTipoVista(!tipoVista)
                            }}></SwitchTest>
                        </div>
                    </div>
                </ContenedorParametros>

                <div className='flex-1 overflow-auto'>
                    <div className={`w-full h-full ${tipoVista ? '' : 'hidden'}`}>
                        <div className={'w-full h-full'} ref={mapDiv}></div>
                    </div>
                    <div className={`${tipoVista ? 'hidden' : ''} pt-4 w-full h-full`}>
                        <div className={`containerScroll w-full h-full overflow-auto`}>
                            <table id='tabla-entidades' className="table border-collapse">
                                <thead>
                                    <tr>
                                        <th className="w-[30px] text-left">Nº</th>
                                        <th className='text-left'>NOMBRE</th>
                                        <th>TIPO ENTIDAD</th>
                                        <th>INSTITUCIÓN</th>
                                        <th>TELÉFONO 1</th>
                                        <th>TELÉFONO 2</th>
                                        <th>TELÉFONO 3</th>
                                        <th>CELULAR</th>
                                        <th className='w-2'></th>
                                        <th className='w-2'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        entidades.length > 0 ?
                                            entidades.map((ent,i) => {
                                                return (
                                                    <tr key={i++}>
                                                        <td className='text-left'>{i+1}</td>
                                                        <td className='text-left'>{ent.nomEntidad}</td>
                                                        <td className='text-center'>{ent.nomEntidadTipo}</td>
                                                        <td className='text-center'>{ent.institucion}</td>
                                                        <td className='text-center'>{ent.telefonoFijoDos || '-'}</td>
                                                        <td className='text-center'>{ent.telefonoFijoDos || '-'}</td>
                                                        <td className='text-center'>{ent.telefonoFijoTres || '-'}</td>
                                                        <td className='text-center'>{ent.numeroCelular || '-'}</td>
                                                        <td className='text-center'>
                                                            <button onClick={async () => {
                                                                await obtenerEntidad(ent.codEntidad)
                                                                setNumeroTelefonos(3)
                                                            }} className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                        <td className='text-center'>
                                                            <button onClick={async () => {
                                                                setEntidadEliminar(ent.codEntidad)
                                                                openModalEliminar()
                                                            }} className="bg-red-500 hover:bg-red-600 text-[14px] px-[5px] py-1 rounded">
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                        <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal 
                isOpen={isOpen} 
                closeModal={() => {
                    closeModal()
                    setTipoJurisdiccion(0)
                    setJurisPolitica(jurisdiccionPoliticaDefault)

                    drawingManager1.setMap(null)    //elimina el drawing mode

                    //para eliminar el marcador y poligono dibujados por el drawing mode
                    ultimoMarcador?.setMap(null)
                    ultimoMarcador = null
                    ultimoPoligono?.setMap(null)
                    ultimoPoligono = null

                    setValorDireccion('')
                    setValorJurisdiccion('')
                }} 
                action={() => {
                    if(((valorDireccion.length != 0) || (((entidad.latitud.toString()).length != 0) && ((entidad.longitud.toString()).length != 0))) && ((valorJurisdiccion.length != 0) || (entidad.poligonoJurisdiccion.length != 0))){
                        guardareditarEntidad()
                    }else{
                        notify("Es obligatorio la ubicacion y la jurisdiccion de la entidad","error")
                    }
                    
                }}
                title={`${entidad.codEntidad ? `Editar entidad [${entidad.nomEntidad}]` : 'Registrar entidad'}`}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[1200px] lg:w-full flex lg:flex-col">
                        <div className="w-1/3 lg:w-full lg:text-xs flex flex-col justify-center form-content">
                            <div className='lg:flex lg:flex-col'>
                                <label>Nombre</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Nombre)'
                                        className={`${classNameInput}`}
                                        defaultValue={entidad.nomEntidad}
                                        onChange={(e) => editarvalorEntidad("nomEntidad",e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-col'>
                                <label>Institución</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className='containerScroll h-[30px] text-white rounded px-4 w-[250px] bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500' 
                                        defaultValue={entidad.codInstitucion}
                                        onChange={(e)=>editarvalorEntidad("codInstitucion", e.target.value)}
                                        // disabled={entidad.codInstitucion != 0 ? true : false}
                                    >
                                        <option value="0">-- Seleccione --</option>
                                        {
                                            instituciones.map((entidad,i)=> {
                                                return (
                                                    <option key= {i+1} value={entidad.codInstitucion}>{entidad.nomInstitucion}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-col'>
                                <label>Tipo</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className='containerScroll h-[30px] text-white rounded px-4 w-[250px]  bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500' 
                                        defaultValue={entidad.codEntidadTipo}
                                        onChange={(e)=>editarvalorEntidad("codEntidadTipo", e.target.value)}
                                    >
                                        <option value="0">-- Seleccione --</option>
                                        {
                                            tiposEntidades.map((entidad,i)=> {
                                                return (
                                                    <option key= {i+1} value={entidad.codEntidadTipo}>{entidad.nomEntidadTipo}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-col'>
                                <label>Entidad Dependiente</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    {
                                        entidad.codEntidad ?
                                            <input 
                                                type='text' 
                                                defaultValue={entidad.nomEntidadPadre} 
                                                className={`${classNameInput} cursor-not-allowed`}
                                                disabled
                                            />
                                        :
                                            <Select2Plamin
                                                options={entidades.map((data) => ({
                                                    value: data.codEntidad,
                                                    label: data.nomEntidad
                                                }))}
                                                funcionChange={(codigoEntidad) => {
                                                    
                                                    (async () => {
                                                        editarvalorEntidad("codEntidadPadre", codigoEntidad)
                                                        const response = await AuthFetch({
                                                            url: process.env.REACT_APP_PLAMIN_API + '/api/Entidades/obtenerJurisdiccionPorEntidad?' +  new URLSearchParams({
                                                                codEntidad: codigoEntidad || 0
                                                            })
                                                        })
                                                        const concatenadoLatLng = response.content[0].poligonoJurisdiccion
        
                                                        googleMapModal.quitarTodosLosPoligonos()
                                                        if(concatenadoLatLng){
                                                            const completelistLatLng = concatenadoLatLng.split(',').map(latLng => {
                                                                const [ lat, lng ] = latLng.split(';');
                                                                return { lat: Number(lat), lng: Number(lng) };
                                                            })
        
                                                            poligonoEntidadPadre = googleMapModal.crearPoligono({
                                                                listLatLng: completelistLatLng,
                                                                opacidad: 0.3,
                                                                opacidadFondo: 0.1
                                                            })
        
                                                            googleMapModal.updateCenterAndZoom(completelistLatLng)
                                                        }
                                                    })()
                                                }}
                                                valorDefault={entidad.codEntidadPadre}
                                            ></Select2Plamin>
                                    }
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-col'>
                                <label>Teléfono 1</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Teléfono 1)'
                                        className={`${classNameInput}`} 
                                        defaultValue={entidad.telefonoFijoUno}
                                        onChange={(e) => editarvalorEntidad("telefonoFijoUno",e.target.value)}
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                    <i  className={`hover:scale-110 cursor-pointer fa fa-plus-square text-xl ${numeroTelefonos==1 ? '' : '!hidden'}`} 
                                        aria-hidden="true"
                                        onClick={() => {setNumeroTelefonos(numeroTelefonos+1)}}
                                    ></i>
                                </div>
                            </div>
                            <div className={numeroTelefonos > 1 ? 'lg:flex lg:flex-col' : '!hidden'}>
                                <label>Teléfono 2</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Teléfono 2)'
                                        className={`${classNameInput}`} 
                                        defaultValue={entidad.telefonoFijoDos}
                                        onChange={(e) => editarvalorEntidad("telefonoFijoDos",e.target.value)}
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                    <i  className={`hover:scale-110 cursor-pointer fa fa-plus-square text-xl ${numeroTelefonos == 2 ? '' : '!hidden'}`} 
                                        aria-hidden="true"
                                        onClick={() => {setNumeroTelefonos(numeroTelefonos+1)}}
                                    ></i>
                                </div>
                            </div>
                            <div className={numeroTelefonos > 2 ? 'lg:flex lg:flex-col' : '!hidden'}>
                                <label>Teléfono 3</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Teléfono 3)'
                                        className={`${classNameInput}`} 
                                        defaultValue={entidad.telefonoFijoTres}
                                        onChange={(e) => editarvalorEntidad("telefonoFijoTres",e.target.value)}
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-col'>
                                <label>Celular</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Número celular)'
                                        className={`${classNameInput}`} 
                                        defaultValue={entidad.numeroCelular}
                                        onChange={(e) => editarvalorEntidad("numeroCelular",e.target.value)}
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                </div>
                            </div>

                            <div className='lg:flex lg:flex-col'>
                                <label className=''>Alertas</label>
                                <div className='flex items-center gap-4 w-[250px]'>
                                    <SelectorMultiple 
                                        options={tipoAlertasPorEntidad.map((data) => ({
                                            value: data.codAlertaTipo,
                                            label: data.nomAlertaTipo
                                        }))} 
                                        valoresXDefecto={valoresXDefectoJson}
                                        OpcionesSeleccionadas={(arr) => {
                                            let temp = ''
                                            if(arr.length != 0){
                                                arr.map((data,i) =>  {
                                                    temp += `${data.value},`
                                                })
                                                temp = temp.slice(0,temp.length - 1)
                                            }
                                            editarvalorEntidad('parametrosTipoAlertaDetalle',temp)
                                        }}
                                    ></SelectorMultiple>
                                </div>
                            </div>
                            <div className={`${((entidad.poligonoJurisdiccion.length != 0) && (entidad.codEntidad)) ? '!hidden' : 'lg:flex lg:flex-col'}`}>
                                <label>Tipo Georeferencia</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select defaultValue={tipoJurisdiccion}
                                            onChange={(e) => {
                                                setTipoJurisdiccion(e.target.value);
                                                validarJuridiccion(e.target.value)
                                            }} 
                                            className={`${classNameInput} ${valorJurisdiccion.length == 0 ? '' : 'cursor-not-allowed'}`}
                                            disabled={valorJurisdiccion.length == 0 ? false : true}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        <option value={1}>Personalizada</option>
                                        <option value={2}>Politica</option>
                                    </select>
                                </div>
                            </div>

                            <div className={`${tipoJurisdiccion == 2 ? 'lg:flex lg:flex-col' : '!hidden'}`}>
                                <label>Departamento</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={classNameInput}
                                            value={jurisPolitica.departamento}
                                            onChange={(e) => {
                                                editarvalorJurisPolitica('departamento',e.target.value)
                                                // if(e.target.value == 0){
                                                    editarvalorJurisPolitica('provincia',0)
                                                    editarvalorJurisPolitica('distrito',0)
                                                // }
                                            }}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            departamentos.map((dep,i) => {
                                                return (
                                                    <option key={i++} value={dep.codDepartamento}>{dep.nomDepartamento}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className={`${tipoJurisdiccion == 2 ? 'lg:flex lg:flex-col' : '!hidden'}`}>
                                <label>Provincia</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={classNameInput}
                                            value={jurisPolitica.provincia}
                                            onChange={e => {
                                                editarvalorJurisPolitica('provincia',e.target.value)
                                                // if(e.target.value == 0){
                                                    editarvalorJurisPolitica('distrito',0)
                                                // }
                                            }}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            jurisPolitica.departamento == 0 ? 
                                            '' 
                                            :
                                            provincias.map((pro,i) => {
                                                return (
                                                    <option key={i++} value={pro.codProvincia}>{pro.nomProvincia}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className={`${tipoJurisdiccion == 2 ? 'lg:flex lg:flex-col' : '!hidden'}`}>
                                <label>Distrito</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={classNameInput}
                                            value={jurisPolitica.distrito}
                                            onChange={e => editarvalorJurisPolitica('distrito',e.target.value)}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            jurisPolitica.provincia == 0 || jurisPolitica.departamento == 0 ?
                                            ''
                                            :
                                            distritos.map((dist,i) => {
                                                return (
                                                    <option key={i++} value={dist.codDistrito}>{dist.nomDistrito}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            
                            <div className={`${entidad.codEntidad > 0 ? '!hidden' : 'lg:flex lg:flex-col'}`}>
                                <label>Dirección</label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder='(Ej: Av. Avenida 1234)'
                                        className={`${classNameInput}`} 
                                        defaultValue={entidad.direccion}
                                        onChange={(e) => editarvalorEntidad("direccion",e.target.value)}
                                    />
                                    <i  className="hover:scale-110 fa fa-map-marker border border-white py-1 px-2 rounded-md cursor-pointer" 
                                        title='Elegir ubicación en el mapa' 
                                        aria-hidden="true"
                                        onClick={() => {
                                            if(valorDireccion.length == 0){

                                                //marcador con drawingManager
                                                drawingManager1.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
                                                google.maps.event.addListener(drawingManager1, 'overlaycomplete', function(event) {
                                                    if(event.type === google.maps.drawing.OverlayType.MARKER){
                                                        ultimoMarcador = event.overlay
                                                        let marker = event.overlay.getPosition()
                                                        setValorDireccion(`${marker.lat()}|${marker.lng()}`)
                                                        drawingManager1.setDrawingMode(null);
                                                    }
                                                })
                                                
                                                //marcador con crearMarcador
                                                /*
                                                googleMapModal.crearMarcador({
                                                    draggable: true,
                                                })
                                                */
                                            }
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className='w-2/3 lg:w-full lg:h-[200px] flex flex-col justify-center pl-5 lg:pl-0'>
                            <div className='w-full h-full' ref={mapDivModal}></div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} 
                closeModal={closeModalEliminar} 
                action={() => {
                    eliminarEntidad(entidadEliminar,closeModalEliminar)
                }}
                title= "Eliminar entidad"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Entidad? </label>
                        </div>
                    </div>
                    
                
            </Modal>   

            
        </>
    )
}