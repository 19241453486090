import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    useLayoutEffect,
} from "react";
import { BotonProcesar } from "../../components/buttons/BotonProcesar";
import { BotonNuevo } from "../../components/buttons/BotonNuevo";
import { Modal } from "../../components/modal/Modal";
import { useModal } from "../.././../hooks/useModal";
import { notify, soloCelular, soloNumero } from "../../../utils/utils";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";
import { AuthFetch } from "../../../services/api";
// import { use

// } from '../../../hooks/useEntidades';

import "../../../assets/css/views/maps.css";

import { GoogleMap } from "../../../utils/googlemaps";
import { useRegiones } from "../../../hooks/useRegiones";
import SwitchTest from "../../components/buttons/SwitchTest";
import iconoEntidad from "../../../assets/images/iconoControles.png";
import SelectorMultiple from "../../components/forms/SelectorMultiple";
import Select2Plamin from "../../components/forms/Select2Plamin";
import { UserContext } from "../../../context/provider/UserProvider";
import { useEntidades } from "../../../hooks/useEntidades";
import { useDetalleEntidades } from "../../../hooks/useDetalleEntidades";
import BotonExcelTest from "../../components/buttons/BotonExcelTest";
import { BtnExcelData } from "../../components/buttons/BtnExcelData";
import { ComponenteCarga } from "../../components/forms/ComponenteCarga";

const google = window.google;
const infowindow = new google.maps.InfoWindow();
const googleMap = new GoogleMap();
const googleMapModal = new GoogleMap();

let drawingManager1;
let ultimoMarcador = null;
let ultimoPoligono = null;

export const CentrosPoblados = () => {
    const [paginaActual, setPaginaActual] = useState(1);
    const [textoBuscador, setTextoBuscador] = useState("");

    const jurisdiccionPoliticaDefault = {
        departamento: 0,
        provincia: 0,
        distrito: 0,
    };
    const classNameInput =
        "caret-input text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500 caret-white";
    let poligonoEntidadPadre = "";

    const { stateUser } = useContext(UserContext);

    const mapDiv = useRef();
    const mapDivModal = useRef();

    const [arrParaExcel, setArrParaExcel] = useState([]);
    const [cantidadFilas, setCantidadFilas] = useState(20);
    const [valorCheckCentroEducativo, setValorCheckCentroEducativo] =
        useState(false);
    const [valorCheckCentroSalud, setValorCheckCentroSalud] = useState(false);
    const [valorCheckComisaria, setValorCheckComisaria] = useState(false);
    const [divEntidad, setDivEntidad] = useState(1);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [verLimites, setVerLimites] = useState(false);
    const [verEducacion, setVerEducacion] = useState(false);
    const [verSalud, setVerSalud] = useState(false);
    const [verTransporte, setVerTransporte] = useState(false);
    const [verVialidad, setVerVialidad] = useState(false);
    const [verEquipamiento, setVerEquipamiento] = useState(false);
    const [verOrdenamiento, setVerOrdenamiento] = useState(false);
    const [valueRender, setValueRender] = useState(false); //para evitar que las funciones se ejecuten con el primer renderizado
    const [tipoVista, setTipoVista] = useState(false);
    const [numeroTelefonos, setNumeroTelefonos] = useState(1);
    const [valorDireccion, setValorDireccion] = useState("");
    const [valorJurisdiccion, setValorJurisdiccion] = useState("");
    const [isOpenModalEliminar, openModalEliminar, closeModalEliminar] =
        useModal();
    const [entidadEliminar, setEntidadEliminar] = useState("");
    const [tipoJurisdiccion, setTipoJurisdiccion] = useState(0);
    const [jurisPolitica, setJurisPolitica] = useState(
        jurisdiccionPoliticaDefault
    );
    const [cantidadDataTotal, setCantidadDataTotal] = useState(0);
    const [cantidadCompletos, setCantidadCompletos] = useState(0);
    const [estaCargando, setEstaCargando] = useState(false);

    //************PARA LA DIRECCION Y LA JURISDICCION DEL CENTRO POBLADO**************************** */
    const infoZonasGeograficasDefault = {
        marcadorModalRegistro: "",
        poligonoModalRegistro: "",
    };
    const [infoZonasGeograficas, setInfoZonasGeograficas] = useState(
        infoZonasGeograficasDefault
    );
    //***************************************************** */

    const [tieneLocalComunal, setTieneLocalComunal] = useState(false);
    const [isOpenModalDetalle, openModalDetalle, closeModalDetalle] =
        useModal();

    const {
        dispersion,
        transporte,
        aguaPotable,
        drenaje,
        conectividad,
        electricidad,
        categorias,
    } = useDetalleEntidades();

    const {
        entidades,
        listarEntidades,
        guardareditarEntidad,
        entidad,
        setEntidad,
        entidadDefault,
        editarvalorEntidad,
        obtenerEntidad,
        isOpen,
        openModal,
        closeModal,
        setEntidades,
        entidadesBuscador,
        tiposEntidades,
        listarTipoEntidades,

        instituciones,
        tipoAlertasPorEntidad,
        listarEntidadesPersonalizado,
        eliminarEntidad,

        atributosCP,
        setAtributosCP,
        atributosCPDefault,
        editarValorAtributosCP,
        listarAtributosCP,
        guardarEditarAtributosCP,
        obtenerAtributosCP,
    } = useEntidades();

    const [
        paises,
        obtenerPaises,
        departamentos,
        obtenerDepartamentos,
        provincias,
        obtenerProvincias,
        distritos,
        obtenerDistritos,
    ] = useRegiones();

    useEffect(() => {
        const funcionReady = async () => {
            await funcionListarTabla();
            await obtenerDepartamentos();
            googleMap.inicializarMapa(mapDiv.current, { zoom: 12 });
            crearDrawingManager();
            setValueRender(true);
            setMapLoaded(true);
        };

        funcionReady();
    }, []);

    useEffect(() => {
        if (valueRender) {
            obtenerProvincias(jurisPolitica.departamento);
        }
    }, [jurisPolitica.departamento]);

    useEffect(() => {
        if (valueRender) {
            obtenerDistritos(jurisPolitica.provincia);
        }
    }, [jurisPolitica.provincia]);

    useEffect(() => {
        if (valueRender) {
            editarvalorEntidad("latitud", valorDireccion?.split("|")[0]);
            editarvalorEntidad("longitud", valorDireccion?.split("|")[1]);
        }
    }, [valorDireccion]);

    useEffect(() => {
        if (valueRender) {
            editarvalorEntidad("poligonoJurisdiccion", valorJurisdiccion);
        }
    }, [valorJurisdiccion]);

    useEffect(() => {
        if (valueRender) {
            let jsonPintar;

            if (jurisPolitica.departamento == 0) {
                googleMapModal.quitarTodosLosPoligonos();
            } else {
                if (jurisPolitica.provincia == 0) {
                    const infoPintar = departamentos.find(
                        (elem) =>
                            elem.codDepartamento == jurisPolitica.departamento
                    );
                    setValorJurisdiccion(infoPintar.puntosPoligonoDepartamento);
                    jsonPintar = devolverJson(
                        infoPintar.puntosPoligonoDepartamento
                    );
                } else {
                    if (jurisPolitica.distrito == 0) {
                        //solo dibuja provincia
                        const infoPintar = provincias.find(
                            (elem) =>
                                elem.codProvincia == jurisPolitica.provincia
                        );
                        setValorJurisdiccion(
                            infoPintar.puntosPoligonoProvincia
                        );
                        jsonPintar = devolverJson(
                            infoPintar.puntosPoligonoProvincia
                        );
                    } else {
                        //solo dibuja distrito
                        const infoPintar = distritos.find(
                            (elem) => elem.codDistrito == jurisPolitica.distrito
                        );
                        setValorJurisdiccion(infoPintar.puntosPoligonoDistrito);
                        jsonPintar = devolverJson(
                            infoPintar.puntosPoligonoDistrito
                        );
                    }
                }

                googleMapModal.quitarTodosLosPoligonos();

                googleMapModal.crearPoligono({
                    listLatLng: jsonPintar,
                });

                const bounds = new window.google.maps.LatLngBounds();
                jsonPintar?.forEach((coord) => {
                    bounds.extend(coord);
                });
                googleMapModal.map.fitBounds(bounds);
            }
        }
    }, [jurisPolitica]);

    useEffect(() => {
        if (tipoVista) {
            mostrarEntidadesMapa();
        }
    }, [tipoVista]);

    useEffect(() => {
        setCantidadDataTotal(entidades.length);
        //-------
        const nuevasCabeceras = [
            "NOMBRE ENTIDAD",
            "TIPO ENTIDAD",
            "LATITUD",
            "LONGITUD",
            "TELEFONO 1",
            "TELEFONO 2",
            "TELEFONO 3",
            "CELULAR",
            "RESPONSABLE",
            "DISPOSITIVO LEGAL CREACION",
            "DDN",
            "CANTIDAD EMPADRONADOS",
            "MCP",
            "DEPARTAMENTO",
            "PROVINCIA",
            "DISTRITO",
            "DIRECCION",
            "CORREO ELECTRONICO 1",
            "CORREO ELECTRONICO 2",
            "DISPOSITIVO LEGAL ADECUACION",
            "NUMERO POBLADORES INEI",
            "NUMERO POBLADORES MANUAL",
            "DATOS COMPLETOS",
        ];
        const arrNuevo = entidades.map((data) => {
            const {
                codDepartamento,
                codDistrito,
                codEntidad,
                codInstitucion,
                codProvincia,
                poligonoJurisdiccion,
                ...restoInfo
            } = data;
            const nuevoJson = Object.fromEntries(
                Object.values(restoInfo).map((data, i) => [
                    nuevasCabeceras[i],
                    data,
                ])
            );
            return nuevoJson;
        });
        const arrImpresion = arrNuevo;
        setArrParaExcel(arrNuevo);
        //-------
        let contador = 0;
        entidades.map((data, i) => {
            if (data.tieneDatosCompletos) {
                contador = contador + 1;
                setCantidadCompletos(contador);
            }
        });
    }, [entidades]);

    useEffect(() => {
        if (valueRender) {
            if (isOpen) {
                googleMapModal?.inicializarMapa(mapDivModal?.current, {
                    zoom: 12,
                });
                drawingManager1.setMap(googleMapModal.map);
                drawingManager1.setDrawingMode(null);
                if (
                    entidad.latitud &&
                    entidad.latitud != 0 &&
                    entidad.longitud &&
                    entidad.longitud != 0
                ) {
                    infoZonasGeograficas.marcadorModalRegistro =
                        googleMapModal.crearMarcador({
                            latLng: {
                                lat: entidad.latitud,
                                lng: entidad.longitud,
                            },
                            draggable: true,
                        });
                    infoZonasGeograficas.marcadorModalRegistro.addListener(
                        "dragend",
                        function (event) {
                            setValorDireccion(
                                `${event.latLng.lat()}|${event.latLng.lng()}`
                            );
                        }
                    );

                    googleMapModal.map.panTo(
                        infoZonasGeograficas.marcadorModalRegistro.getPosition()
                    );
                }

                if (entidad?.poligonoJurisdiccion?.length != 0) {
                    ultimoPoligono = googleMapModal.crearPoligono({
                        listLatLng: devolverJson(entidad.poligonoJurisdiccion),
                    });

                    ultimoPoligono.addListener("dblclick", () => {
                        ultimoPoligono.setMap(null);

                        if (infoZonasGeograficas.marcadorModalRegistro) {
                            crearPoligonoCuadrado(
                                infoZonasGeograficas.marcadorModalRegistro.getPosition()
                            );
                        }
                        // setValorJurisdiccion('');
                    });
                }
                // valoresXDefectoJson()
            }
        }
    }, [isOpen]);

    const funcionListarTabla = async () => {
        setEstaCargando(true);
        await listarEntidades();
        setEstaCargando(false);
    };

    const mostrarEntidadesMapa = () => {
        googleMap.quitarTodosLosMarcadores();
        googleMap.quitarTodosLosPoligonos();

        let validadorEntidades = false;
        if (entidades.length <= 5) {
            validadorEntidades = true;
        }

        entidades.map((data, i) => {
            if (data.latitud && data.longitud) {
                const marcadorTest = {
                    lat: data.latitud,
                    lng: data.longitud,
                };
                const marcadorEntidad = googleMap.crearMarcador({
                    latLng: marcadorTest,
                    // icon: iconoEntidad
                });
                const infoWindow = googleMap.crearInfowindow({});

                const generarInfoWindow = () => {
                    infoWindow.setContent(
                        `<div>ENTIDAD: ${data.nomEntidad}</div>`
                    );
                    infoWindow.open({
                        anchor: marcadorEntidad,
                        map: googleMap.map,
                        isOpen: true,
                    });
                };

                if (validadorEntidades) {
                    generarInfoWindow();
                }

                marcadorEntidad.addListener(
                    "click",
                    () => {
                        generarInfoWindow();
                    },
                    false
                );
            }

            const colorRandom = () => {
                const letras = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                    color += letras[Math.floor(Math.random() * 16)];
                }
                return color;
            };

            if (data.poligonoJurisdiccion) {
                //valida que exista
                const jsonTest = devolverJson(data.poligonoJurisdiccion);

                googleMap.crearPoligono({
                    listLatLng: jsonTest,
                    color: i == 0 ? "red" : colorRandom(),
                });

                if (i == 0) {
                    googleMap.updateCenterAndZoom(jsonTest);
                }
            }
        });
    };

    const valoresXDefectoJson = () => {
        const jsonTest = [];

        //para cambiar el valor de las alertas por defecto por las traidas por base de datos
        editarvalorEntidad(
            "parametrosTipoAlertaDetalle",
            entidad.codAlertaTipoConcatenado
        );

        entidad.codAlertaTipoConcatenado?.split(",").map((data, i) => {
            if (
                tipoAlertasPorEntidad.some((elem) => elem.codAlertaTipo == data)
            ) {
                jsonTest.push(
                    tipoAlertasPorEntidad.find(
                        (elem) => elem.codAlertaTipo == data
                    )
                );
            }
        });
        return jsonTest;
    };

    const devolverJson = (cadena) => {
        let json = [];
        cadena?.split(",").map((data, i) => {
            let temp = {
                lat: Number(data?.split(";")[0]),
                lng: Number(data?.split(";")[1]),
            };
            json.push(temp);
        });
        return json;
    };

    const editarvalorJurisPolitica = (key, value) => {
        setJurisPolitica((juris) => {
            return {
                ...juris,
                [key]: value,
            };
        });
    };

    const crearDrawingManager = () => {
        drawingManager1 = new window.google.maps.drawing.DrawingManager({
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                drawingModes: [
                    google.maps.drawing.OverlayType.MARKER, // Habilita dibujar marcadores
                    google.maps.drawing.OverlayType.POLYGON, // Habilita dibujar polígonos
                ],
            },
            polygonOptions: {
                fillColor: "#42a7f1",
                strokeColor: "#3454ff",
            },
        });
    };

    const crearPoligonoCuadrado = (ubicacionMarcador) => {
        const lat = ubicacionMarcador.lat();
        const lng = ubicacionMarcador.lng();

        const puntosCuadrado = [
            { lat: lat + 0.01, lng: lng - 0.01 }, // Esquina superior izquierda
            { lat: lat + 0.01, lng: lng + 0.01 }, // Esquina superior derecha
            { lat: lat - 0.01, lng: lng + 0.01 }, // Esquina inferior derecha
            { lat: lat - 0.01, lng: lng - 0.01 }, // Esquina inferior izquierda
            { lat: lat + 0.01, lng: lng - 0.01 }, // Volver a la esquina superior izquierda para cerrar el cuadrado
        ];

        ultimoPoligono = new google.maps.Polygon({
            paths: puntosCuadrado,
            strokeColor: "#0000FF", // Color azul para el borde del polígono
            strokeOpacity: 0.8, // Opacidad del borde del polígono
            strokeWeight: 2, // Grosor del borde del polígono
            fillColor: "#0000FF", // Color azul para el relleno del polígono
            fillOpacity: 0.35, // Opacidad del relleno del polígono
            editable: true,
            draggable: true,
            map: googleMapModal.map,
        });

        guardarNuevoValorPoligono(); // PARA GUARDAR EL POLIGONO INICIAL

        google.maps.event.addListener(
            ultimoPoligono.getPath(),
            "set_at",
            guardarNuevoValorPoligono
        );
        google.maps.event.addListener(
            ultimoPoligono.getPath(),
            "insert_at",
            guardarNuevoValorPoligono
        );
    };

    const guardarNuevoValorPoligono = () => {
        const vertices = ultimoPoligono.getPath();
        let valorPoligono = "";
        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            valorPoligono += `${xy.lat()};${xy.lng()},`;
        }

        const primerPunto = vertices.getAt(0);
        valorPoligono += `${primerPunto.lat()};${primerPunto.lng()}`;

        setValorJurisdiccion(valorPoligono);
    };

    const resetearPoligono = () => {
        if (ultimoPoligono) {
            ultimoPoligono.setMap(null);
            ultimoPoligono = null;

            // crearPoligonoTriangular(ubicacionPredeterminada);
        }
    };

    const validarJuridiccion = (valorSelect) => {
        setValorJurisdiccion("");
        poligonoEntidadPadre &&
            googleMapModal.quitarTodosLosPoligonosExcepto(poligonoEntidadPadre);

        switch (Number(valorSelect)) {
            case 0: //nada
                drawingManager1.setDrawingMode(null);
                break;
            case 1: //libre
                if (valorJurisdiccion.length == 0) {
                    drawingManager1.setDrawingMode(
                        google.maps.drawing.OverlayType.POLYGON
                    );

                    google.maps.event.addListener(
                        drawingManager1,
                        "overlaycomplete",
                        function (event) {
                            if (
                                event.type ===
                                google.maps.drawing.OverlayType.POLYGON
                            ) {
                                ultimoPoligono = event.overlay;
                                const vertices = event.overlay
                                    .getPath()
                                    .getArray();
                                let concatVertices = "";
                                vertices.map((data) => {
                                    concatVertices += `${data.lat()};${data.lng()},`;
                                });
                                concatVertices = concatVertices.slice(
                                    0,
                                    concatVertices.length - 1
                                );
                                setValorJurisdiccion(concatVertices);
                                drawingManager1.setDrawingMode(null);
                            }
                        }
                    );
                }
                break;
            case 2: //division politica
                drawingManager1.setDrawingMode(null);
                setJurisPolitica(jurisdiccionPoliticaDefault);
                break;
            default:
                break;
        }
    };

    const cargarEntidadParaEditar = async (codEntidad) => {
        if (mapLoaded) {
            // El mapa está cargado, proceder a cargar la información de la entidad
            await obtenerEntidad(codEntidad);
            await obtenerAtributosCP(codEntidad);

            editarvalorEntidad("parametrosTipoAlertaDetalle", 1006);

            // Otras operaciones necesarias
        } else {
            // El mapa aún no está cargado, puedes manejar este caso como creas conveniente
        }
    };

    // NUEVAS FUNCIONES CREADAS
    const agregarMarcadorRegistro = () => {
        if (infoZonasGeograficas.marcadorModalRegistro == "") {
            setInfoZonasGeograficas((valoresAnteriores) => {
                return {
                    ...valoresAnteriores,
                    marcadorModalRegistro: googleMapModal.crearMarcador({
                        draggable: true,
                    }),
                };
            });
            setValorDireccion(
                `${googleMapModal.latDefault}|${googleMapModal.lngDefault}`
            );
        } else {
            notify(
                "Ya existe un marcador en el mapa para el centro poblado",
                "info"
            );
        }
    };

    const agregarJurisdiccionRegistro = () => {
        if (infoZonasGeograficas.marcadorModalRegistro == "")
            notify("Agregar un marcador para crear la jurisdiccion", "info");
        else {
            if (!ultimoPoligono) {
                crearPoligonoCuadrado(
                    infoZonasGeograficas.marcadorModalRegistro.position
                );
            } else {
                notify(
                    "Ya existe un poligono en el mapa para el centro poblado",
                    "info"
                );
            }
        }
    };

    const dataParaExcel = () => {
        const arrNuevo = entidades.map((data) => {
            const {
                codDepartamento,
                codDistrito,
                codEntidad,
                codInstitucion,
                codProvincia,
                poligonoJurisdiccion,
                ...restoInfo
            } = data;
            return restoInfo;
        });

        console.log("arrNuevo -> ", arrNuevo);

        return arrNuevo;
    };

    return (
        <>
            <ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>
            <div className="w-full h-full flex flex-col">
                <ContenedorParametros
                    titulo={"Centros Poblados"}
                    // tablaId={'tabla-entidades'}
                >
                    <div className="flex gap-2 items-center justify-center">
                        <BotonNuevo // PARA EL REGISTRO DE CENTRO POBLADO
                            onClick={() => {
                                openModal();
                                setInfoZonasGeograficas(
                                    infoZonasGeograficasDefault
                                );
                                setEntidad(entidadDefault);
                                resetearPoligono(); // PARA PONER EL POLIGNO EN NULL NUEVAMENTE
                                setAtributosCP(atributosCPDefault);
                                editarvalorEntidad("codEntidadTipo", 5); // CENTRO POBLADO
                                editarvalorEntidad("codEntidadPadre", 2023);
                                editarvalorEntidad("codInstitucion", 1);
                                editarvalorEntidad(
                                    "parametrosTipoAlertaDetalle",
                                    1006
                                );
                                setDivEntidad(1);
                            }}
                        />

                        <BotonProcesar
                            onClick={funcionListarTabla}
                        ></BotonProcesar>

                        {/* <BotonExcelTest
                            tableId={'tabla-entidades'}
                            nombreArchivo={'Reporte Centros Poblados'}
                        ></BotonExcelTest> */}

                        <BtnExcelData data={arrParaExcel} />

                        <div className="flex items-center">
                            <div>Cambiar Vista:</div>
                            <SwitchTest
                                value={tipoVista}
                                action={() => {
                                    setTipoVista(!tipoVista);
                                }}
                            ></SwitchTest>
                        </div>
                    </div>
                </ContenedorParametros>

                <div className="flex-grow flex flex-col gap-4 overflow-auto">
                    <div
                        className={`w-full h-full ${tipoVista ? "" : "hidden"}`}
                    >
                        <div className={"w-full h-full"} ref={mapDiv}></div>
                    </div>

                    <div
                        className={`w-full h-full flex flex-col gap-2 justify-between ${
                            tipoVista ? "hidden" : ""
                        }`}
                    >
                        <div className="flex lg:flex-col items-center justify-between gap-2">
                            <div className="flex gap-5 font-semibold">
                                <span>
                                    Actualizados: {cantidadCompletos} /{" "}
                                    {cantidadDataTotal}
                                </span>
                            </div>
                            <div className="flex gap-2 justify-end">
                                <span>Buscar:</span>
                                <input
                                    value={textoBuscador}
                                    onChange={(e) =>
                                        setTextoBuscador(e.target.value)
                                    }
                                    className={`${classNameInput} w-[200px]`}
                                />
                            </div>
                        </div>

                        <div
                            className={`containerScroll overflow-auto flex-1 lg:!text-md`}
                        >
                            <table
                                id="tabla-entidades"
                                className="table lg:text-xs"
                            >
                                <thead>
                                    <tr>
                                        <th className="w-[30px] text-left">
                                            Nº
                                        </th>
                                        <th className="text-left">NOMBRE</th>
                                        <th>MCP</th>
                                        <th>DDN</th>
                                        <th>ALCALDE</th>
                                        <th>DISPO. LEGAL</th>
                                        <th>TELÉFONOS</th>

                                        <th className="w-2"></th>
                                        <th className="w-2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entidades.length > 0 ? (
                                        textoBuscador.length > 4 ? ( //para evitar que se sobrecargue mucho
                                            entidades
                                                .filter((elem) => {
                                                    const valores =
                                                        Object.values(elem);
                                                    const contieneRastro =
                                                        valores.some(
                                                            (valuesObject) =>
                                                                valuesObject
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        textoBuscador.toLowerCase()
                                                                    )
                                                        );
                                                    return contieneRastro;
                                                })
                                                .map((ent, i) => {
                                                    return (
                                                        <tr
                                                            key={i + 1}
                                                            className={`${
                                                                !ent.tieneDatosCompletos &&
                                                                "datosIncompletos"
                                                            }`}
                                                        >
                                                            <td className="text-left">
                                                                {i + 1}
                                                            </td>
                                                            <td className="text-left">
                                                                {ent.nomEntidad}
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.mcp}
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.ddn}
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    ent.nomResponsable
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    ent.dispositivoLegalCreacion
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.telefonoFijoUno ||
                                                                    ""}{" "}
                                                                {ent.telefonoFijoDos ||
                                                                    ""}{" "}
                                                                {ent.telefonoFijoTres ||
                                                                    ""}{" "}
                                                                {ent.numeroCelular ||
                                                                    ""}{" "}
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    onClick={() => {
                                                                        cargarEntidadParaEditar(
                                                                            ent.codEntidad
                                                                        );
                                                                        setDivEntidad(
                                                                            1
                                                                        );
                                                                    }}
                                                                    className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    onClick={async () => {
                                                                        setEntidadEliminar(
                                                                            ent.codEntidad
                                                                        );
                                                                        openModalEliminar();
                                                                    }}
                                                                    className="bg-red-500 hover:bg-red-600 text-[14px] px-[5px] py-1 rounded"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        ) : (
                                            entidades
                                                .slice(
                                                    cantidadFilas *
                                                        (paginaActual - 1),
                                                    cantidadFilas * paginaActual
                                                )
                                                .map((ent, i) => {
                                                    return (
                                                        <tr
                                                            key={i++}
                                                            className={`${
                                                                ent.tieneDatosCompletos &&
                                                                "datosIncompletos"
                                                            } bg-green-9`}
                                                        >
                                                            <td className="text-left">
                                                                {cantidadFilas *
                                                                    (paginaActual -
                                                                        1) +
                                                                    (i + 1)}
                                                            </td>
                                                            <td className="text-left">
                                                                {ent.nomEntidad}
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.mcp}
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.ddn}
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    ent.nomResponsable
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    ent.dispositivoLegalCreacion
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {ent.telefonoFijoUno ||
                                                                    ""}{" "}
                                                                {ent.telefonoFijoDos ||
                                                                    ""}{" "}
                                                                {ent.telefonoFijoTres ||
                                                                    ""}{" "}
                                                                {ent.numeroCelular ||
                                                                    ""}{" "}
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    onClick={() => {
                                                                        cargarEntidadParaEditar(
                                                                            ent.codEntidad
                                                                        );
                                                                        setDivEntidad(
                                                                            1
                                                                        );
                                                                    }}
                                                                    className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                                                >
                                                                    <i className="fas fa-edit text-white"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    onClick={async () => {
                                                                        setEntidadEliminar(
                                                                            ent.codEntidad
                                                                        );
                                                                        openModalEliminar();
                                                                    }}
                                                                    className="bg-red-500 hover:bg-red-600 text-[14px] px-[5px] py-1 rounded"
                                                                >
                                                                    <i className="fa fa-trash text-white"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={"9"}
                                                className="text-center"
                                            >
                                                No hay información para mostrar
                                                por el momento
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div
                            className={`flex items-center justify-end gap-4 px-4`}
                        >
                            <select
                                value={cantidadFilas}
                                onChange={(e) =>
                                    setCantidadFilas(e.target.value)
                                }
                                className={`${classNameInput} w-[100px]`}
                            >
                                {entidades.length > 10 ? (
                                    <option value={10}>10</option>
                                ) : (
                                    <></>
                                )}
                                {entidades.length > 20 ? (
                                    <option value={20}>20</option>
                                ) : (
                                    <></>
                                )}
                                {entidades.length > 40 ? (
                                    <option value={40}>40</option>
                                ) : (
                                    <></>
                                )}
                                <option value={entidades.length}>Todos</option>
                            </select>
                            <button
                                onClick={() =>
                                    setPaginaActual(
                                        paginaActual - 1 == 0
                                            ? Math.ceil(
                                                  entidades.length /
                                                      cantidadFilas
                                              )
                                            : paginaActual - 1
                                    )
                                }
                                className="border rounded h-[30px] px-2"
                            >
                                <i
                                    className="fa fa-angle-double-left"
                                    aria-hidden="true"
                                ></i>
                            </button>
                            <input
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const soloNumeros = Number(
                                        inputValue.replace(/[^0-9]/g, "")
                                    );
                                    setPaginaActual(soloNumeros);
                                }}
                                value={paginaActual}
                                className={`${classNameInput} w-[60px] text-center`}
                            />
                            <span>
                                de {Math.ceil(entidades.length / cantidadFilas)}
                            </span>
                            <button
                                onClick={() =>
                                    setPaginaActual(
                                        paginaActual + 1 >
                                            Math.ceil(
                                                entidades.length / cantidadFilas
                                            )
                                            ? 1
                                            : paginaActual + 1
                                    )
                                }
                                className="border rounded h-[30px] px-2"
                            >
                                <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isOpen}
                closeModal={() => {
                    closeModal();
                    setTipoJurisdiccion(0);
                    setJurisPolitica(jurisdiccionPoliticaDefault);

                    drawingManager1.setMap(null); //elimina el drawing mode

                    //para eliminar el marcador y poligono dibujados por el drawing mode
                    ultimoMarcador?.setMap(null);
                    ultimoMarcador = null;
                    ultimoPoligono?.setMap(null);
                    ultimoPoligono = null;

                    setValorDireccion("");
                    setValorJurisdiccion("");
                }}
                action={() => {
                    if (
                        (valorDireccion.length != 0 ||
                            (entidad.latitud.toString().length != 0 &&
                                entidad.longitud.toString().length != 0)) &&
                        (valorJurisdiccion.length != 0 ||
                            entidad.poligonoJurisdiccion.length != 0)
                    ) {
                        guardareditarEntidad();
                        entidad.codEntidad && guardarEditarAtributosCP();
                    } else {
                        notify(
                            "Es obligatorio la ubicacion y la jurisdiccion de la entidad",
                            "error"
                        );
                    }
                }}
                title={`${
                    entidad.codEntidad
                        ? `Editar [${entidad.nomEntidad}]`
                        : "Registrar centro poblado"
                }`}
            >
                <div className="flex gap-8 lg:gap-2 items-center flex-wrap justify-center ">
                    <div className="w-[1200px] lg:w-full h-[620px] lg:h-auto flex lg:flex-col">
                        <div className="absolute lg:relative w-1/3 lg:w-full border-b border-gray-500">
                            <div className=" flex justify-start text-center items-center w-full gap-[1px] ">
                                <button
                                    onClick={() => {
                                        setDivEntidad(1);
                                    }}
                                    style={{
                                        boxShadow:
                                            "rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset",
                                        border: "1px solid #336fbb",
                                    }}
                                    className={`${
                                        divEntidad == 1
                                            ? " bg-[#2e5289] "
                                            : "    "
                                    }]   hover:bg-[#2e5289] px-1 py-1 lg:px-0 lg:py-0`}
                                >
                                    Información General
                                </button>
                                {entidad.codEntidad ? (
                                    <button
                                        onClick={() => {
                                            setDivEntidad(2);
                                        }}
                                        style={{
                                            boxShadow:
                                                "rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset",
                                            border: "1px solid #336fbb",
                                        }}
                                        className={`${
                                            divEntidad == 2
                                                ? " bg-[#2e5289] "
                                                : "    "
                                        }]   hover:bg-[#2e5289] mr-[2px] px-1 py-1 lg:px-0 lg:py-0`}
                                    >
                                        {/* <i className="fa fa-circle text-[#bcbdbf] rounded-xl border-white border" style={{fontSize:'13px'}}/> */}
                                        Requisitos
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        {divEntidad == 1 && (
                            <div className="mt-12 lg:mt-0 w-1/3 lg:w-full flex flex-col justify-center form-content lg:text-xs">
                                <div className="lg:flex lg:flex-col">
                                    <label>Nombre</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad.nomEntidad}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "nomEntidad",
                                                    e.target.value
                                                )
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>D. Legal Creación</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad?.dispositivoLegal ||
                                                entidad?.dispositivoLegalCreacion
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "dispositivoLegalCreacion",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="lg:flex lg:flex-col">
                                    <label>D. Legal Adecuación</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad?.dispositivoLegalAdecuacion
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "dispositivoLegalAdecuacion",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>MCP</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad?.mcp}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "mcp",
                                                    e.target.value
                                                )
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="lg:flex lg:flex-col">
                                    <label>DDN</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad?.ddn}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "ddn",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>Teléfono 1</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad.telefonoFijoUno
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "telefonoFijoUno",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloCelular(e, e.target)
                                            }
                                        />
                                        <i
                                            className={`hover:scale-110 cursor-pointer fa fa-plus-square text-xl ${
                                                numeroTelefonos == 1
                                                    ? ""
                                                    : "!hidden"
                                            }`}
                                            aria-hidden="true"
                                            onClick={() => {
                                                setNumeroTelefonos(
                                                    numeroTelefonos + 1
                                                );
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    className={
                                        numeroTelefonos > 1
                                            ? "lg:flex lg:flex-col"
                                            : "!hidden"
                                    }
                                >
                                    <label>Teléfono 2</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad.telefonoFijoDos
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "telefonoFijoDos",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloCelular(e, e.target)
                                            }
                                        />
                                        <i
                                            className={`hover:scale-110 cursor-pointer fa fa-plus-square text-xl ${
                                                numeroTelefonos == 2
                                                    ? ""
                                                    : "!hidden"
                                            }`}
                                            aria-hidden="true"
                                            onClick={() => {
                                                setNumeroTelefonos(
                                                    numeroTelefonos + 1
                                                );
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    className={
                                        numeroTelefonos > 2
                                            ? "lg:flex lg:flex-col"
                                            : "!hidden"
                                    }
                                >
                                    <label>Teléfono 3</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad.telefonoFijoTres
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "telefonoFijoTres",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloCelular(e, e.target)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="lg:flex lg:flex-col">
                                    <label>Celular</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad.numeroCelular}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "numeroCelular",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloCelular(e, e.target)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>Población</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <label>INEI</label>

                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad?.cantidadPobladorRegistroINEI ||
                                                0
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "cantidadPobladorRegistroINEI",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloNumero(e, e.target)
                                            }
                                        />
                                        <label>REG.</label>

                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad?.cantidadPobladorRegistroManual ||
                                                0
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "cantidadPobladorRegistroManual",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) =>
                                                soloNumero(e, e.target)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>Email</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={
                                                entidad?.correoElectronicoUno
                                            }
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "correoElectronicoUno",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>Alcalde</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad?.responsable}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "responsable",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="lg:flex lg:flex-col">
                                    <label>Dirección</label>
                                    <div className="flex items-center gap-4 w-[250px] h-[30px]">
                                        <input
                                            className={`${classNameInput}`}
                                            defaultValue={entidad?.direccion}
                                            onChange={(e) =>
                                                editarvalorEntidad(
                                                    "direccion",
                                                    e.target.value
                                                )
                                            }
                                        />

                                        {infoZonasGeograficas.marcadorModalRegistro ==
                                        0 ? (
                                            <button
                                                onClick={
                                                    agregarMarcadorRegistro
                                                }
                                                className="text-xs bg-blue-600 px-2 h-full hover:scale-110 rounded"
                                            >
                                                <i
                                                    className="fa fa-map-marker"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        ) : (
                                            ""
                                        )}

                                        {ultimoPoligono ? (
                                            ""
                                        ) : (
                                            <button
                                                onClick={
                                                    agregarJurisdiccionRegistro
                                                }
                                                className="text-xs bg-orange-600 px-2 h-full hover:scale-110 rounded"
                                            >
                                                <i
                                                    className="fa fa-map"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`${
                                        tipoJurisdiccion == 2
                                            ? "lg:flex lg:flex-col"
                                            : "!hidden"
                                    }`}
                                >
                                    <label>Departamento</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select
                                            className={classNameInput}
                                            value={jurisPolitica.departamento}
                                            onChange={(e) => {
                                                editarvalorJurisPolitica(
                                                    "departamento",
                                                    e.target.value
                                                );
                                                // if(e.target.value == 0){
                                                editarvalorJurisPolitica(
                                                    "provincia",
                                                    0
                                                );
                                                editarvalorJurisPolitica(
                                                    "distrito",
                                                    0
                                                );
                                                // }
                                            }}
                                        >
                                            <option value={0}>
                                                -- Seleccione --
                                            </option>
                                            {departamentos.map((dep, i) => {
                                                return (
                                                    <option
                                                        key={i++}
                                                        value={
                                                            dep.codDepartamento
                                                        }
                                                    >
                                                        {dep.nomDepartamento}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${
                                        tipoJurisdiccion == 2
                                            ? "lg:flex lg:flex-col"
                                            : "!hidden"
                                    }`}
                                >
                                    <label>Provincia</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select
                                            className={classNameInput}
                                            value={jurisPolitica.provincia}
                                            onChange={(e) => {
                                                editarvalorJurisPolitica(
                                                    "provincia",
                                                    e.target.value
                                                );
                                                // if(e.target.value == 0){
                                                editarvalorJurisPolitica(
                                                    "distrito",
                                                    0
                                                );
                                                // }
                                            }}
                                        >
                                            <option value={0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {jurisPolitica.departamento == 0
                                                ? ""
                                                : provincias.map((pro, i) => {
                                                      return (
                                                          <option
                                                              key={i++}
                                                              value={
                                                                  pro.codProvincia
                                                              }
                                                          >
                                                              {pro.nomProvincia}
                                                          </option>
                                                      );
                                                  })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`${
                                        tipoJurisdiccion == 2
                                            ? "lg:flex lg:flex-col"
                                            : "!hidden"
                                    }`}
                                >
                                    <label>Distrito</label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select
                                            className={classNameInput}
                                            value={jurisPolitica.distrito}
                                            onChange={(e) =>
                                                editarvalorJurisPolitica(
                                                    "distrito",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={0}>
                                                -- Seleccione --
                                            </option>
                                            {jurisPolitica.provincia == 0 ||
                                            jurisPolitica.departamento == 0
                                                ? ""
                                                : distritos.map((dist, i) => {
                                                      return (
                                                          <option
                                                              key={i++}
                                                              value={
                                                                  dist.codDistrito
                                                              }
                                                          >
                                                              {dist.nomDistrito}
                                                          </option>
                                                      );
                                                  })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        {divEntidad == 2 && (
                            <div className=" mt-12 w-1/3 lg:w-full flex flex-col justify-start form-content  containerScroll  overflow-y-auto px-2 lg:text-xs">
                                <div className="flex mt-2 px-2 justify-between  w-full ">
                                    <label className="mr-5">Categoría:</label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadCategoria
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadCategoria",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>

                                            {categorias?.map((t) => {
                                                return (
                                                    <option
                                                        key={
                                                            t.codEntidadCategoria
                                                        }
                                                        value={
                                                            t.codEntidadCategoria
                                                        }
                                                    >
                                                        {t.nomEntidadCategoria}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className="flex  px-2 justify-between  w-full cursor-pointer"
                                    onClick={() => setVerLimites(!verLimites)}
                                >
                                    <label className="mr-5">Límites</label>
                                    <i className="fa fa-plus"></i>
                                </div>
                                {/* DIV LIMITES */}
                                {verLimites && (
                                    <div className={`flex-col border rounded border-gray-500 `}>
                                        <div className="mt-2 flex px-2 justify-between  w-full   ">
                                            <label className=" mb-5">
                                                Norte:{" "}
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localidadColindanteNorte
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localidadColindanteNorte",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full ">
                                            <label className=" mb-5">Sur</label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localidadColindanteSur
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localidadColindanteSur",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full">
                                            <label className="mr-5  mb-5">
                                                Este
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localidadColindanteEste
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localidadColindanteEste",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full ">
                                            <label className="mr-5  mb-5">
                                                Oeste
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localidadColindanteOeste
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localidadColindanteOeste",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* FIN DIV VER LIMITES  */}

                                <div className="flex px-2 justify-between w-full">
                                    <label className="mr-[10px]">
                                        Local Comunal
                                    </label>
                                    <label className="switch top-1">
                                        <input
                                            className=""
                                            type="checkbox"
                                            defaultChecked={
                                                atributosCP.tieneLocalComunal
                                            }
                                            onChange={(e) =>
                                                editarValorAtributosCP(
                                                    "tieneLocalComunal",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <div className="slider"></div>
                                    </label>
                                </div>

                                <div className="flex  px-2 justify-between  w-full ">
                                    <label className="mr-5">Dispersión:</label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadDispercion
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadDispercion",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {dispersion.map((d) => {
                                                return (
                                                    <option
                                                        key={
                                                            d.codEntidadDispercion
                                                        }
                                                        value={
                                                            d.codEntidadDispercion
                                                        }
                                                    >
                                                        {d.nomEntidadDispercion}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className="flex  px-2 justify-between  w-full  "
                                    onClick={() =>
                                        setVerEducacion(!verEducacion)
                                    }
                                >
                                    <label className="mr-5">Educación</label>
                                    <i className="fa fa-plus"></i>
                                </div>

                                {/* DIV EDUCACION */}
                                {verEducacion && (
                                    <div className="flex-col border rounded border-gray-500 ">
                                        <div className="flex mt-2   px-2 justify-between  w-full">
                                            <label className=" mb-5">
                                                Inicial
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localEscolarInicial
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localEscolarInicial",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full">
                                            <label className=" mb-5">
                                                Primaria
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localEscolarPrimaria
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localEscolarPrimaria",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full ">
                                            <label className=" mb-5">
                                                Secundaria
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.localEscolarSecundaria
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "localEscolarSecundaria",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* FIN DIV EDUCACION  */}

                                <div
                                    className="flex  px-2 justify-between  w-full  "
                                    onClick={() => setVerSalud(!verSalud)}
                                >
                                    <label className="mr-5">Salud</label>
                                    <i className="fa fa-plus"></i>
                                </div>

                                {/* DIV SALUD */}
                                {verSalud && (
                                    <div className=" flex-col border rounded border-gray-500 ">
                                        <div className="flex mt-2 px-2 justify-between  w-full ">
                                            <label className=" mb-5">
                                                Posta
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.postaSalud
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "postaSalud",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full">
                                            <label className=" mb-5">
                                                Puesto
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.puestoSalud
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "puestoSalud",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full ">
                                            <label className="  mb-5">
                                                Centro
                                            </label>
                                            <div className=" relative  text-right w-[200px]">
                                                <input
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.centroSalud
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "centroSalud",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* FIN DIV SALUD  */}

                                <div className="flex  px-2 justify-between  w-full ">
                                    <label className="mr-5">Transporte:</label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadTransporte
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadTransporte",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>

                                            {transporte.map((t) => {
                                                return (
                                                    <option
                                                        key={
                                                            t.codEntidadTransporte
                                                        }
                                                        value={
                                                            t.codEntidadTransporte
                                                        }
                                                    >
                                                        {t.nomEntidadTransporte}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className="flex  px-2 justify-between  w-full  "
                                    onClick={() => setVerVialidad(!verVialidad)}
                                >
                                    <label className="mr-5">Vialidad</label>
                                    <i className="fa fa-plus"></i>
                                </div>

                                {/* DIV VIALIDAD */}
                                {verVialidad && (
                                    <div className="flex-col border rounded border-gray-500 ">
                                        <div className="mt-2 flex px-2 justify-between  w-full   ">
                                            <label className="  mb-5">
                                                Vias Urbanas
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadCalleViaUrbana
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadCalleViaUrbana",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className=" mb-5">
                                                Vias Vecinales
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadCaminoViaVecinal
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadCaminoViaVecinal",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Trochas Carrozables
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadTrochaCarrozable
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadTrochaCarrozable",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Caminos Herraduras
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadRutaCaminoHerradura
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadRutaCaminoHerradura",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* FIN DIV VIALIDAD  */}

                                <div
                                    className="flex  px-2 justify-between  w-full  "
                                    onClick={() =>
                                        setVerEquipamiento(!verEquipamiento)
                                    }
                                >
                                    <label className="mr-5">
                                        Equipamiento urbano
                                    </label>
                                    <i className="fa fa-plus"></i>
                                </div>

                                {/* DIV Equipamiento Urbano */}
                                {verEquipamiento && (
                                    <div className="flex-col border rounded border-gray-500 ">
                                        <div className="mt-2 flex  px-2 justify-between  w-full   ">
                                            <label className="  mb-5">
                                                Plazas:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadPlaza
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadPlaza",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full ">
                                            <label className=" mb-5">
                                                Mercados:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadMercado
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadMercado",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Estadios:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadEstadio
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadEstadio",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Juzgados:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadJuzgadoPaz
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadJuzgadoPaz",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Cementerios:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadCementerio
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadCementerio",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Comisarias:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadComisaria
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadComisaria",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  px-2 justify-between  w-full ">
                                            <label className="  mb-5">
                                                Locales municipales:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadLocalComunal
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadLocalComunal",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Atractivos turisticos:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadAtractivoTuristico
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadAtractivoTuristico",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Coliseos:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadColiseo
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadColiseo",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex px-2 justify-between  w-full  ">
                                            <label className="  mb-5">
                                                Parques:
                                            </label>
                                            <div className=" relative  text-right w-[100px]">
                                                <input
                                                    type="number"
                                                    className={`${classNameInput}`}
                                                    defaultValue={
                                                        atributosCP?.cantidadParque
                                                    }
                                                    onChange={(e) =>
                                                        editarValorAtributosCP(
                                                            "cantidadParque",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* FIN DIV VIALIDAD  */}

                                <div className="flex  px-2 justify-between  w-full ">
                                    <label className="mr-5">
                                        Agua Potable:
                                    </label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadAguaPotable
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadAguaPotable",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {aguaPotable.map((t) => {
                                                return (
                                                    <option
                                                        key={
                                                            t.codEntidadAguaPotable
                                                        }
                                                        value={
                                                            t.codEntidadAguaPotable
                                                        }
                                                    >
                                                        {
                                                            t.nomEntidadAguaPotable
                                                        }
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="flex px-2 justify-between  w-full  ">
                                    <label className="mr-5">Desague:</label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadDesague
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadDesague",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {drenaje.map((d) => {
                                                return (
                                                    <option
                                                        key={
                                                            d.codEntidadDesague
                                                        }
                                                        value={
                                                            d.codEntidadDesague
                                                        }
                                                    >
                                                        {d.nomEntidadDesague}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="flex  px-2 justify-between  w-full ">
                                    <label className="mr-5">
                                        Conectividad:
                                    </label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadConectividad
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadConectividad",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {conectividad.map((c) => {
                                                return (
                                                    <option
                                                        key={
                                                            c.codEntidadConectividad
                                                        }
                                                        value={
                                                            c.codEntidadConectividad
                                                        }
                                                    >
                                                        {
                                                            c.nomEntidadConectividad
                                                        }
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="flex  px-2 justify-between  w-full ">
                                    <label className="mr-5">
                                        Electricidad:
                                    </label>
                                    <div className=" relative  text-right w-[200px]">
                                        <select
                                            className={`${classNameInput} `}
                                            defaultValue={
                                                atributosCP?.codEntidadElectricidad
                                            }
                                            onChange={(e) => {
                                                editarValorAtributosCP(
                                                    "codEntidadElectricidad",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={null || 0}>
                                                {" "}
                                                Seleccione{" "}
                                            </option>
                                            {electricidad.map((e) => {
                                                return (
                                                    <option
                                                        key={
                                                            e.codEntidadElectricidad
                                                        }
                                                        value={
                                                            e.codEntidadElectricidad
                                                        }
                                                    >
                                                        {
                                                            e.nomEntidadElectricidad
                                                        }
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="w-2/3 lg:w-full lg:h-[250px] flex flex-col justify-center pl-5 lg:pl-0">
                            <div
                                className="w-full h-full"
                                ref={mapDivModal}
                            ></div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenModalDetalle}
                closeModal={() => {
                    closeModalDetalle();
                }}
                action={() => {
                    guardarEditarAtributosCP();
                    closeModalDetalle();
                }}
                title={`Detalle de entidad `}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-full text-xl underline flex text-center justify-center">
                        {atributosCP?.nomEntidad}
                    </div>
                    <div className="w-[250px] flex">
                        <div className="w flex flex-col justify-center form-content">
                            <div className="relative mt-[8px] text-right max-w-full">
                                <label className="mr-[10px]">
                                    {"Centro Educativo"}
                                </label>
                                <label className="switch top-1">
                                    {/* <input className='' type="checkbox" checked={valorCheckCentroEducativo} onChange={(e) => {setValorCheckCentroEducativo(!valorCheckCentroEducativo);}} /> */}
                                    <input
                                        className=""
                                        type="checkbox"
                                        checked={
                                            atributosCP?.accesoCentroEducativo
                                        }
                                        onChange={(e) => {
                                            editarValorAtributosCP(
                                                "accesoCentroEducativo",
                                                !atributosCP?.accesoCentroEducativo
                                            );
                                        }}
                                    />
                                    <div className="slider"></div>
                                </label>
                            </div>

                            <div className="relative mt-[8px] text-right max-w-full">
                                <label className="mr-[10px]">
                                    {"Centro de Salud"}
                                </label>
                                <label className="switch top-1">
                                    <input
                                        className=""
                                        type="checkbox"
                                        checked={atributosCP?.accesoCentroSalud}
                                        onChange={(e) => {
                                            editarValorAtributosCP(
                                                "accesoCentroSalud",
                                                !atributosCP?.accesoCentroSalud
                                            );
                                        }}
                                    />
                                    <div className="slider"></div>
                                </label>
                            </div>

                            <div className="relative mt-[8px] text-right max-w-full">
                                <label className="mr-[10px]">
                                    {"Comisaria"}
                                </label>
                                <label className="switch top-1">
                                    <input
                                        className=""
                                        type="checkbox"
                                        checked={atributosCP?.accesoComisaria}
                                        onChange={(e) => {
                                            editarValorAtributosCP(
                                                "accesoComisaria",
                                                !atributosCP?.accesoComisaria
                                            );
                                        }}
                                    />
                                    <div className="slider"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenModalEliminar}
                closeModal={closeModalEliminar}
                action={() => {
                    eliminarEntidad(entidadEliminar, closeModalEliminar);
                }}
                title="Eliminar entidad"
                spinner={{}}
                textButtons={{ confirm: "Si", denied: "No" }}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[400px] px-4">
                        <label className="text-center">
                            ¿Está seguro de eliminar la Entidad?{" "}
                        </label>
                    </div>
                </div>
            </Modal>
        </>
    );
};
