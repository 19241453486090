import React, { useRef, useContext, useMemo, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/provider/UserProvider';
import { AuthFetch } from '../../../../services/api';
import { notify } from '../../../../utils/utils';

export const MapaAlertas = ({ googleMap }) => {
    const { stateUser } = useContext(UserContext);
    const mapDiv = useRef(null);
    const urlBaseEntidades = process.env.REACT_APP_PLAMIN_API + '/api/Entities'

    const obtenerPoligono = async () => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/jurisdictionByEntity?' + new URLSearchParams({
                CodEntidad: stateUser.codEntidad || 0
            })
        });
        
        if(response.isValid){
            mostrarZona(response.content[0].poligonoJurisdiccion)
        }else{
            notify(response.content, 'error');
        }
    }

    const mostrarZona = (listLatLng) => {
        googleMap.quitarTodosLosPoligonos();
        const completelistLatLng = listLatLng.split(',').map(latLng => {
            const [ lat, lng ] = latLng.split(';');
            return { lat: Number(lat), lng: Number(lng) };
        })

        googleMap.crearPoligono({
            id: `poligono-zona-0`,
            listLatLng: completelistLatLng,
            color: '#FF0000',
            opacidadFondo: 0.1,
        })

        googleMap.updateCenterAndZoom(completelistLatLng)
    }

    useEffect(() => {
        (async () => {
            if(mapDiv.current){
                googleMap.inicializarMapa(mapDiv.current,{
                    zoom : '',
                    ocultaOpciones : false,
                    ocultarMarcadores : false,
                    cursor : ''
                });
                await obtenerPoligono();
            }
        })()
    },[])

    return (
        <div ref={mapDiv} className='w-full h-full'></div>
    )
}