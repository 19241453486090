import React, { useEffect, useState } from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const ModalAsignacion = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true},
    clasesModal,
    cambioValorInput,
    valorInicial
}) => {

    // const [ valorInput,setValorInput ] = useState(valorInicial)
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 

    return (
    <>  
        {isOpen ? (
            <>
                <Draggable handle="strong">
                    <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-[1050] outline-none focus:outline-none">
                        <div className={`${clasesModal ? clasesModal : ''} relative w-${width ? width : 'auto'} my-6 mx-auto max-w-[90%]`}>
                        {/*content*/}
                            <div className="bg-[#2d2f30] border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <strong>
                                    <div className="divMovibleCabecera bg-[#003478]  flex items-start justify-between p-5 border-b border-solid border-[#4e4e4e] rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            {title}
                                        </h3>
                                        <button
                                            className=""
                                            onClick={closeModal}
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </strong>
                                {/*body*/}
                                <div className="relative p-6 flex-auto bg-[#2f3134]">
                                    { children }
                                </div>
                                {/*footer*/}
                                <div className="bg-[#2d2f30] flex items-center justify-end p-6 rounded-b text-left w-full ">
                                    {/* <div className="flex items-center">
                                        <div className="mr-2">Radio: </div>
                                        <input 
                                            type="number"
                                            value={valorInput}
                                            className="caret-black w-20 rounded-sm mr-2 text-black p-1 appearance-none"
                                            maxLength={5}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.slice(0,e.target.maxLength)
                                            }}
                                            onChange={(e) => {
                                                setValorInput(e.target.value)
                                                cambioValorInput(e.target.value)
                                            }}
                                        />
                                        <div className="absolute right-[192px] text-black">mts.</div>
                                    </div> */}
                                {validButton.confirm ?
                                    <button
                                        className="min-w-[100px] bg-blue-500 hover:bg-blue-600 text-white active:bg-blue-600 font-bold capitalize text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                        type="button"
                                            onClick={async () => {
                                                mostrarSpinner();
                                                await action();
                                                ocultarSpinner();
                                            }}
                                    >
                                        {
                                            spinner 
                                            ? <Spinner spinner={spinner.toString()}></Spinner>
                                            : <span>{textButtons.confirm}</span>
                                        }
                                    </button>
                                    : ''
                                }
                                {validButton.denied ?
                                    <button
                                    className=" text-white-500 hover:bg-stone-600 rounded background-transparent font-bold capitalize px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                    type="button"
                                    onClick={closeModal}
                                    >
                                        {textButtons.denied} 
                                    </button>
                                    : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </>
        ) : null}
        </>
    );
}