import React, { useEffect } from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const ModalCentrosPoblados = ({
    isOpen, 
    closeModal, 
    children, 
    title, 
    clasesModal
}) => {
    useEffect(() => {
        const eventoEsc = (event) => {
            if (event.key === 'Escape') {
                closeModal()
            }
        }

        document.addEventListener('keydown',eventoEsc)

        return(() => {
            document.removeEventListener('keydown',eventoEsc)
        })
    },[])

    return (
        <>  
        {isOpen ? (
            <>
                <Draggable handle="strong">
                    <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-[100] outline-none focus:outline-none">
                        <div className={`flex rounded-lg shadow-lg flex-col w-[90vw] h-[95vh] overflow-auto`} style={{boxShadow: 'rgb(0 0 0) 0px 0px 25px 8px'}}>
                            {/*header*/}
                            <div className="sticky top-0 z-10">
                                <div className=" bg-[#003478]  flex items-start justify-between p-5 border-b border-solid border-[#4e4e4e] rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        {title}
                                    </h3>
                                    <button
                                        className="cursor-pointer"
                                        onClick={closeModal}
                                        onTouchEnd={closeModal}         // PARA EL CLICK EN LA VISTA MOVIL
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            {/*body*/}
                            <div className="p-3 bg-[#2f3134] flex flex-grow overflow-auto">
                                { children }
                            </div>
                      </div>
                    </div>
                </Draggable>
            </>
        ) : null}
        </>
    );
}