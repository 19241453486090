import React, { useEffect, useState, useRef, useContext } from 'react';
import { BotonProcesar } from '../../components/buttons/BotonProcesar';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { Modal } from '../../components/modal/Modal';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { ContenedorTabla } from '../../components/utils/ContenedorTabla';
import { useDocumentos } from '../../../hooks/personas/useDocumentos';
// import { usePersonas } from '../../../hooks/personas/usePersonas';
import { useUsuariosIS } from '../../../hooks/personas/useUsuariosIS';
import fotoDefault from '../../../assets/images/usuarioDefault.jpg';
import { generarNombreUnico, notify, primeraLetraMayuscula, soloCelular, soloDNI } from '../../../utils/utils';
import { Entidades } from './Entidades';
import { useEntidades } from '../../../hooks/useEntidades';
import { UserContext } from '../../../context/provider/UserProvider';
import { NuevoSelectAutocomplete } from '../../components/forms/NuevoSelectAutocomplete';
import Select2Plamin from '../../components/forms/Select2Plamin';
// import { Tooltip } from '../../components/utils/Tooltip';
import { Tooltip } from '../../components/utils/Tooltip';

export const Personas = () => {
    const {
        usuarios,
        listarUsuarios,
        guardarEditarUsuario,
        usuario, 
        setUsuario,
        editarValorUsuario, 
        obtenerUsuario, 
        isOpenModal, 
        closeModal, 
        cargarDatosPersona,
        fotoUrlUsuario,
        setFotoFile,
        setFotoUrlUsuario,
        eliminarPersona, 
        isOpenModalEliminar, 
        closeModalEliminar, 
        usuarioEliminar,
        cargandoDNI,
        roles,
        asignarUsuario
    } = useUsuariosIS();

    const { documentos } = useDocumentos();
    const [selectedFile, setSelectedFile] = useState()

    const {listarEntidades,entidades} = useEntidades()
    const classNameInput = "text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const inputFoto = useRef();
    const {stateUser}  = useContext(UserContext)

    const [registrarEditar,setRegistrarEditar] = useState(true)

    const [urlRegistroPersona,setUrlRegistroPersona] = useState(fotoDefault)
    const [dioClickGuardar,setDioClickGuardar] = useState(false)

    useEffect(()=> {
        listarEntidades()
    },[])

    useEffect(() => {
        if(usuario.uid){
            setUrlRegistroPersona(usuario.urlFoto || fotoDefault)
        }
    },[usuario])

    useEffect(() => {
        if(dioClickGuardar){
            (async() => {
                await guardarEditarUsuario()
            })()
            setDioClickGuardar(false)
        }
    },[dioClickGuardar])

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlUsuario(fotoDefault)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlUsuario(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }

    const funcionSubirImagenMinio = async () => {
        // const nombreArchivo = generarNombreUnico('registroPersona')
        const nombreArchivo = `${usuario.nombre.toLowerCase().replace(/\s/g, '')}_${usuario.numDocumento}`
        const promise = await fetch(urlRegistroPersona)
        const blob = await promise.blob()
        const file = new File([blob],nombreArchivo,{type: blob.type})

        const urlSubirArchivo = `https://miniowebapi.abexacloud.com/api/Archivo/subirArchivo?NombreCarpeta=tgps&NombreArchivo=%2Ffotos-personas%2F${nombreArchivo}`
		const urlVer =`https://miniowebapi.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=tgps&NombreImagen=fotos-personas%2F${nombreArchivo}`
		const formData = new FormData();
		formData.append("archivo", file)
		const response = await fetch(urlSubirArchivo,{
			method:'POST',
			body: formData
		}).then(res => res.json())
		if(response.isValid){
            notify('Archivo subido correctamente','success')
            editarValorUsuario('urlFoto',urlVer)
		}else{
			notify('Error al enviar el archivo, inténtelo de nuevo','error')
		}
    }

    const registrarEditarPersona = async () => {
        if(urlRegistroPersona == fotoDefault || urlRegistroPersona == usuario.urlFoto){ 
            // PARA QUE NO EJECUTE EL REGISTRO EN MINIO CUANDO LA FOTO SEA LA MISMA O LA DEFAULT
        }else{
            await funcionSubirImagenMinio()
        }
        setDioClickGuardar(true)
    }

    return (
        <>
            <ContenedorParametros
                titulo="Personas"
                tablaId={'tabla-personas'}
            >
                <div>
                    <BotonProcesar onClick={() => listarUsuarios()} ></BotonProcesar>
                </div>

                <div>
                    <BotonNuevo onClick={() => {obtenerUsuario();setRegistrarEditar(true);setUrlRegistroPersona(fotoDefault)}}></BotonNuevo>
                </div>
            </ContenedorParametros>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-personas' className="table">
                    <thead>
                        <tr>
                            <th className="w-[30px]">N</th>
                            <th className="text-left">NOMBRE</th>
                            <th>DOCUMENTO</th>
                            <th>TELÉFONO</th>
                            <th>U.CREACIÓN</th>
                            <th className="w-[10px]"></th>
                            <th className="w-[10px]"></th>

                        </tr>
                    </thead>
                    <tbody>
                    {   
                            usuarios?.length > 0 
                            ? usuarios?.map((u, i) => {
                                return (
                                    <tr key={i+1}>
                                        <td>{ i + 1}</td>
                                        <td className="text-left"> {primeraLetraMayuscula(u?.nombres)} {primeraLetraMayuscula(u?.apellidoPaterno)} {primeraLetraMayuscula(u?.apellidoMaterno)}</td>
                                        <td className="text-center">{u.numDocumento}</td>
                                        <td className="text-center">{u.telefonoActual}</td>
                                        <td className="text-center">{u.usuarioCreacion}</td>
                                        <td className="text-center w-[10px]" >
                                            <button onClick={() => {obtenerUsuario(u.uid);setRegistrarEditar(false)}} className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                                <i className="fas fa-edit"></i>
                                            </button>
                                        </td>    
                                        <td className="text-center w-[10px]" >
                                            <button  onClick={()=> {setUsuario(u);usuarioEliminar();}} className="bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <Modal 
                isOpen={isOpenModal}
                closeModal={closeModal}
                action={() => {
                    registrarEditarPersona()
                    // funcionSubirImagenMinio()
                    // guardarEditarUsuario()
                }}
                title={`${registrarEditar ? 'Registrar persona' : 'Editar persona'}`}
            >
                <div className="flex  items-center flex-wrap justify-center">
                    <div className="w-[500px] px-4">
                        <div className="flex flex-col justify-between w-full form-content">                        
                            <div className='lg:flex-col lg:w-full'>
                                <label>Tipo Documento: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <select className={`${classNameInput} `} defaultValue={usuario.codDocumentoTipo || 1} onChange={(e) => {editarValorUsuario("codDocumentoTipo", e.target.value)}} >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            documentos.map(d => {
                                                return <option key={d.codDocumentoTipo} value={d.codDocumentoTipo}>{d.abrevDocumentoTipo}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Número de Documento: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        placeholder=''
                                        autoFocus={true}
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={usuario.numDocumento}
                                        onChange={(e) => {
                                            editarValorUsuario("numDocumento", e.target.value);
                                        }}
                                        onKeyPress={(e) => soloDNI(e, e.target)} 
                                    />
                                    <i 
                                        data-for='Busqueda' 
                                        data-tip='Buscar persona' 
                                        onClick={() => cargarDatosPersona(usuario.numDocumento)} 
                                        className={`${cargandoDNI? ' fa fa-spinner animate-spin  ' : ' fa fa-search '} hover:scale-105  cursor-pointer p-1 rounded-md`} 
                                        aria-hidden="true"
                                    ></i>
                                    <Tooltip id={'Busqueda'}></Tooltip>
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Nombre: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input  
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={usuario.nombre || usuario.nombPersona} 
                                        onChange={(e) => editarValorUsuario("nombre", e.target.value)} 
                                    />
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Apellido Paterno: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input
                                        className={`${classNameInput} caret-input`}
                                        defaultValue={usuario?.apellidoPaterno} 
                                        onChange={(e) => editarValorUsuario("apellidoPaterno", e.target.value)} 
                                    />
                                </div>
                            </div>
                            
                            <div className='lg:flex-col lg:w-full'>
                                <label>Apellido Materno: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={usuario?.apellidoMaterno}
                                        onChange={(e) => editarValorUsuario("apellidoMaterno", e.target.value)} 
                                    />
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Teléfono: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={usuario.telefono || usuario.telefonoActual}
                                        onChange={(e) => editarValorUsuario("telefono", e.target.value)} 
                                        onKeyPress={(e) => soloCelular(e,e.target)}
                                    />
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Correo Electronico: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <input 
                                        className={`${classNameInput} caret-input`} 
                                        defaultValue={usuario.correoElectronico || usuario.correoActual}
                                        onChange={(e) => editarValorUsuario("correoElectronico", e.target.value)} 
                                    />
                                </div>
                            </div>

                            <div className='lg:flex-col lg:w-full'>
                                <label>Nombre de usuario: </label>
                                <div className="flex items-center gap-4 w-[250px]  ">

                                        <input 
                                            placeholder=''
                                            className={`
                                                ${classNameInput} caret-input 
                                                
                                                `}  
                                                defaultValue={usuario.uid || usuario.uidNuevo}
                                                onChange={(e) => editarValorUsuario("uidNuevo", e.target.value)} 
                                                disabled = {usuario?.uid? true : false}
                                                />
                                        {usuario?.uidNuevo?.length>15 && 
                                            <>
                                                <i data-tip='maximoUID' data-for='maximoUID'  className='fa fa-info-circle text-orange-500  '/>
                                                <Tooltip id="maximoUID">El nombre de usuario es demasiado largo</Tooltip>
                                            </>
                                        }
                                    <div>
                                    </div>
                                </div>

                            </div>



                            <div className={`lg:flex-col lg:w-full `}>
                                <label>Entidad: </label>
                                <div className="flex items-center gap-4 w-[250px]">
                                    <Select2Plamin
                                        options={
                                            entidades.map(option => ({
                                                value: option.codEntidad,
                                                label:  option?.mcp? `${option?.nomEntidad} - ${option?.mcp}` : option?.nomEntidad 
                                            }))
                                        }
                                        funcionChange={(valor) => {
                                            editarValorUsuario("codEntidad",valor)
                                        }}
                                        valorDefault={usuario.codEntidad}
                                    ></Select2Plamin>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="text-center">
                        <img className='rounded-xl w-[250px] h-[250px] mb-1 lg:w-[150px] lg:h-[150px]' src={urlRegistroPersona} alt='' />
                        <input onChange={(e) => {
                            const foto = e.target.files[0]
                            const url = URL.createObjectURL(foto)
                            setUrlRegistroPersona(url)
                        }} ref={inputFoto} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png' />
                        <label htmlFor="file-1">
                            <i className="fa fa-image iborrainputfile text-[#FFFFFF] text-lg"></i> 
                            <span className="iborrainputfile"> Seleccione</span>
                        </label>
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(usuario.uid)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[400px] px-4">
                        <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                    </div>
                </div>
            </Modal>
        </>
    )


}