import {React,useState} from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStateManager } from 'react-select';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('https://img.icons8.com/ios/20/place-marker--v1.png')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#4383e3',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('https://img.icons8.com/ios-filled/20/menu--v6.png')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#4383e3',
    borderRadius: 20 / 2,
  },
}));


export default function SwitchTest(props) {
    return (
        <MaterialUISwitch sx={{ m: 1 }} onChange={props.action} checked={props.value || false} />
    );
}