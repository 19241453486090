import React from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const BtnExcelData = ({data}) => {

    const handleExport = () => {
        // Crear un libro de trabajo y una hoja de trabajo
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Establecer estilos para una celda específica (por ejemplo, A1)
        const cellStyle = {
            fill: {
                fgColor: { rgb: "FFFF00" } // Color amarillo de fondo
            },
            font: {
                color: { rgb: "000000" }, // Color de la fuente (opcional)
                bold: true // Fuente en negrita (opcional)
            }
        };

        // Establecer contenido en la celda A1
        const cellAddress = 'A1';
        worksheet[cellAddress] = {
            ...worksheet[cellAddress], // Mantener las propiedades existentes
            ...{ s: cellStyle } // Aplicar nuevos estilos a la celda
        };

        // Ajustar el ancho de las columnas (opcional)
        const colWidths = data.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const cellLength = String(row[key]).length;
                acc[key] = Math.max(acc[key] || 0, cellLength);
            });
            return acc;
        }, {});

        const wscols = Object.keys(colWidths).map((key) => ({
            wch: colWidths[key] + 1 // Ajusta el ancho de la columna (más 1 para margen adicional)
        }));
        worksheet['!cols'] = wscols;

        // Agregar la hoja de trabajo al libro de trabajo
        XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

        // Generar archivo Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array"
        });

        // Guardar el archivo Excel
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "datos.xlsx");
    };

    return (
        <button className='bg-green-800 rounded text-[#ffff]' onClick={handleExport}>
            <i className=' fa fa-file-excel-o bg-green-800  px-4 py-1 text-2xl rounded hover:bg-[#155bb5]'></i>
        </button>
    );
};
