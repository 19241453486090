import React,{useRef, useState} from "react";

export const SearchTable = ({ tablaId }) => {
    const [ verInput, setVerInput ] = useState() 
    const getByFilter = async (value) =>{
        const tabla = document.querySelector('#' + tablaId);
        const rows = tabla.children[1].children;
        const expresion = new RegExp(`${value}.*`, "i")

        for (let i = 0; i < rows.length; i++) {
            const cols = rows[i].children;
            let ocultar = true;

            for(let j = 0; j < cols.length; j++) {
                const value = cols[j].innerHTML;
                
                const booool = expresion.test(value);
                if (booool) {
                    ocultar = false;
                    break;
                }
            }
            rows[i].style.display = ocultar ? "none" : "";
        }
    }

    const mostrarInput = () => {
        verInput ? setVerInput(false) : setVerInput(true);
    }

    return (
    <>
        <div className="absolute flex right-0" style={{zIndex:44}}>
            <div className="">
                <input 
                    onChange={e => getByFilter(e.target.value)} 
                    className={ ` 
                        ${verInput ? '': ' hidden ' } 
                        w-[180px] 
                        h-[30px]  
                        px-4 
                        text-[13px] 
                        rounded-md 
                        focus:outline-none 
                        focus:ring-1 
                        focus:ring-blue-500 
                        caret-input 
                        text-[#FFFFFF] 
                        bg-[#27272a] 
                    `} 
                    type="text" 
                    placeholder="Búsqueda" 
                    autoComplete="off" 
                    autoFocus={ true }
                />
            </div>
            <button 
                onClick={mostrarInput} 
                className="h-[30px] w-[30px] rounded-lg bg-[#27272a] text-[#FFFFFF]"
            >
                <i className="fa fa-search " aria-hidden="true" />
            </button>
        </div>
    </>
    );
}