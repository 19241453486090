import React from 'react';
import { useEstados } from '../../../../hooks/useEstados';
import { primeraLetraMayuscula } from '../../../../utils/utils';

export const BotonesEstado = ({ listarIncidentes, estadoActual ,setValorSelEstado, funcionCambio }) => {
    const [ estados ] = useEstados();
    
    return (
        <div onClick={() => {
            funcionCambio()
        }} className='absolute bottom-0 z-10000000'>
                {
                    estados.map (e => {
                        let color = 'text-[#ffffff]  rounded-xl border-white border '
                        if (e.codEstado === 9){///Pendiente
                            color = 'text-[#fa050d]  rounded-xl border-white border '
                        } else if(e.codEstado === 45){//En Proceso
                            color = 'text-[#04bf19]  rounded-xl border-white border '
                        }else if (e.codEstado === 7){// Cerrado
                            color= 'text-[#000000]  rounded-xl border-white border '
                        }
                        return <button key={e.codEstado} style={{boxShadow:'rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset',border:'1px solid #336fbb'}}  onClick={() => {listarIncidentes(e.codEstado);setValorSelEstado(e.codEstado)}}  className={`${estadoActual === e.codEstado ? ' bg-[#2e5289] ':'  '}]   hover:bg-[#2e5289] mr-[2px] px-1 py-1 lg:px-0 lg:py-0 `}><i className={`fa fa-circle ${color}`} style={{fontSize:'13px'}}></i>{primeraLetraMayuscula(e.nomEstado)}</button>
                    })
                }
                {/* <button onClick={() => listarIncidentes(0)} style={{boxShadow:'rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset',border:'1px solid #336fbb'}} className={`${botonPintar === 0 ? ' bg-[#2e5289] ':'    '}]  hover:bg-[#2e5289] mr-[2px] lg:bg-green-500`}> */}
                <button onClick={() => listarIncidentes(0)} style={{boxShadow:'rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset',border:'1px solid #336fbb'}} className={`${estadoActual === 0 ? ' bg-[#2e5289] ':'    '}]   hover:bg-[#2e5289] mr-[2px] px-1 py-1 lg:px-0 lg:py-0`}>
                    <i className="fa fa-circle text-[#bcbdbf] rounded-xl border-white border" style={{fontSize:'13px'}}/>
                    Todos
                </button>       
        </div>  
    )
}