import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/provider/UserProvider'
import { AuthFetch } from '../../services/api'
import { notify, urlApiPlamin } from '../../utils/utils'

export const useRutasAcceso = () => {
  const {stateUser} = useContext(UserContext)
  const codEntidad = stateUser.codEntidad
  const uid = stateUser.uid

  const rutaAccesoRegistroDefault = {
    puntoOrigen: "",
    puntoDestino: "",
    tipoTransporte: "",
    temporada: "",
    distancia: 0,
    tiempoViajeMinutos: 0,
    comentario: "",
    puntosLineaMapa: ""
  }

  const [rutaAccesoRegistro,setRutaAccesoRegistro] = useState(rutaAccesoRegistroDefault)
  const [detalleRutaAcceso,setDetalleRutaAcceso] = useState({})
  const [centrosPoblados,setCentrosPoblados] = useState([])
  
  const listarCentrosPoblados = async () => {
    const response = await AuthFetch({
      url: urlApiPlamin + '/Entities/populatedCenter/accessRoutes'
    })

    if(response.isValid){
      setCentrosPoblados(response.content)
    }else{
      notify(response.exceptions[0].description,'error')
    }
  }

  const listarRutasAccesoPorCentroPoblado = async (codEntidad) => {
    const response = await AuthFetch({
      url: urlApiPlamin + '/Entities/populatedCenter/accessRoutes/routesByEntity?' + new URLSearchParams({
        CodEntidad: codEntidad
      })
    })

    /*
      content: {
        "detalladoEntidad": {
          "nomEntidad": "string",
          "latitud": 0,
          "longitud": 0
        },
        "rutasAcceso": [
          {
            "codEntidadRutaAcceso": 0,
            "puntosLineaMapa": "string"
          }
        ]
      }
    */
    
    return response?.content || {}
  }
  
  const obtenerDetalleRutaAcceso = async (codRutaAcceso) => {
    const response = await AuthFetch({
      url: urlApiPlamin + '/Entities/populatedCenter/accessRoutes/detail?' + new URLSearchParams({CodEntidadRutaAcceso: codRutaAcceso})
    })

    if(response.isValid){
      setDetalleRutaAcceso(response.content)
    }else{
      notify(response.exceptions[0].description,'error')
    }
  }

  const registrarRutaAcceso = async ({codigoEntidadRegistro = codEntidad,puntosLineaMapa = ''}) => {  // LA RUTA DE ACCESO VA A SER POR CENTRO POBLADO A ELECCION (SI NO SE ENVIA PARAMETRO VA A SER LA ENTIDAD DEL USUARIO)
    const response = await AuthFetch({
      url     : urlApiPlamin + '/Entities/populatedCenter/accessRoutes',
      method  : 'POST',
      body    : JSON.stringify({
        ...rutaAccesoRegistro,
        codEntidad: codigoEntidadRegistro,
        puntosLineaMapa: puntosLineaMapa
      })
    })

    return {
      CodResultado : response.isValid ? 1 : 0,
      DesResultado : response.isValid ? response.content : response.exceptions[0].description
    }
  }

  const eliminarRutaAcceso = async (codRutaAcceso) => {
    const response = await AuthFetch({
      url     : urlApiPlamin + '/Entities/populatedCenter/accessRoutes?' + new URLSearchParams({CodEntidadRutaAcceso: codRutaAcceso}),
      method  : 'DELETE',
    })

    return {
      CodResultado : response.isValid ? 1 : 0,
      DesResultado : response.isValid ? response.content : response.exceptions[0].description
    }
  }

  const actualizarValorRutaAccesoRegistro = (clave,valor) => {
    setRutaAccesoRegistro(valAnterior => {
      return {
        ...valAnterior,
        [clave] : valor
      }
    })
  }

  return {
    listarCentrosPoblados,
    centrosPoblados,
    listarRutasAccesoPorCentroPoblado,
    obtenerDetalleRutaAcceso,
    detalleRutaAcceso,
    registrarRutaAcceso,
    rutaAccesoRegistro,
    setRutaAccesoRegistro,
    rutaAccesoRegistroDefault,
    actualizarValorRutaAccesoRegistro,
    eliminarRutaAcceso
  }
}
