import { useState } from 'react';
import { notify } from '../utils/utils';
import { AuthFetch } from '../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useRegiones = () => {
    const [ paises, setPaises ] = useState([]);
    const [ departamentos, setDepartamentos ] = useState([]);
    const [ provincias, setProvincias ] = useState([]);
    const [ distritos, setDistritos ] = useState([]);

    const obtenerPaises = async () => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Regions/countries'
        });

        if (respuesta.isValid) {
            setPaises(d => respuesta.content);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const obtenerDepartamentos = async (codPais) => {
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Regions/departments?' + new URLSearchParams({
                codPais: codPais || 1
            })
        });

        if (respuesta.isValid) {
            setDepartamentos(d => respuesta.content);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const obtenerProvincias = async (codDepartamento) => {
        if (codDepartamento === 0) {
            setProvincias(p => []);            
            return;
        }

        if (!codDepartamento) {
            notify('Debe enviar un departamento', 'error');
            return;
        }
        
        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Regions/provinces?' + new URLSearchParams({
                codDepartamento: codDepartamento
            })
        });

        if (respuesta.isValid) {
            setProvincias(p => respuesta.content);
            setDistritos(d => []);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const obtenerDistritos = async (codProvincia) => {
        if (codProvincia === 0) {
            setDistritos(d => []);
            return;
        }

        if (!codProvincia) {
            notify('Debe enviar una provincia', 'error');
            return;
        }

        const respuesta = await AuthFetch({
            url: urlBasePlamin + '/Regions/districts?' + new URLSearchParams({
                codProvincia: codProvincia
            })
        });

        if (respuesta.isValid) {
            setDistritos(d => respuesta.content);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    return [ paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ]
}