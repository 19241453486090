import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { GoogleMap } from '../../../utils/googlemaps';
import { useRegiones } from '../../../hooks/useRegiones';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'; 

const catastroTipo = {
    MOSTRAR_POLIGONOS: 'MOSTRAR_POLIGONOS',
    CAMBIAR_VALOR: 'CAMBIAR_VALOR'
}

const estadoInicial = {
    codPais: 1,
    codDepartamento: 0,
    codProvincia: 0,
    codDistrito: 0,
    googleMap: new GoogleMap()
}

const catastroTerritorialReducer = (state, action) => {
    switch (action.type) {
        case catastroTipo.MOSTRAR_POLIGONOS:
            const { arregloPoligonos, puntosPoligonoPadre, keys } = action.payload;
            
            state.googleMap.quitarTodosLosPoligonos();
            
            arregloPoligonos.forEach(poligono => {
                poligono[keys.puntos].split('|').forEach((puntosDivididos, index) => {
                    if (puntosDivididos != '') {
                        const listLatLng = puntosDivididos.split(',').map(latLng => {
                            const [ lat, lng ] = latLng.split(';');
                            return { lat: Number(lat), lng: Number(lng) };
                        })
                        
                        state.googleMap.crearPoligono({
                            id: `${poligono[keys.codigo]}-${poligono[keys.nombre]}-poligono-${index + 1}`,
                            listLatLng: listLatLng
                        })
                    }
                })
            })
            
            if (puntosPoligonoPadre) {
                const completelistLatLng = puntosPoligonoPadre.split(',').map(latLng => {
                    const [ lat, lng ] = latLng.split(';');
                    return { lat: Number(lat), lng: Number(lng) };
                })
                
                state.googleMap.updateCenterAndZoom(completelistLatLng)
            }
            
            return state
        case catastroTipo.CAMBIAR_VALOR:
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: value
            }
        default:
            throw new Error('Not implemented');
    }
}

const useCatastroTerritorial = () => {
    const [ state, dispatch ] = useReducer(catastroTerritorialReducer, estadoInicial);
    const [ paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ] = useRegiones();
    
    useMemo(() => {
        obtenerPaises();
    }, [])

    useMemo(() => {
        obtenerDepartamentos(state.codPais);
    }, [paises])

    useMemo(() => {     
        if (state.codDepartamento === 0) {
            dispatch({ type: catastroTipo.CAMBIAR_VALOR, payload: { key: 'codDistrito', value: 0 }})

            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: departamentos,
                puntosPoligonoPadre: paises.find(p => p.codPais === state.codPais)?.puntosPoligonoPais,
                keys: {
                    puntos: "puntosPoligonoDepartamento",
                    codigo: "codDepartamento",
                    nombre: "nomDepartamento"
                }
            }})
        }  
        obtenerProvincias(Number(state.codDepartamento))
        obtenerDistritos(0)
    }, [state.codDepartamento])

    useMemo(() => {
        if (state.codProvincia === 0 && state.codDepartamento > 0) {
            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: provincias,
                puntosPoligonoPadre: departamentos.find(d => d.codDepartamento === state.codDepartamento)?.puntosPoligonoDepartamento,
                keys: {
                    puntos: "puntosPoligonoProvincia",
                    codigo: "codProvincia",
                    nombre: "nomProvincia"
                }
            }})
        }
        obtenerDistritos(Number(state.codProvincia))
    }, [state.codProvincia])

    useEffect(() => {
        if (distritos.length > 0) {
            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: state.codDistrito > 0 ? distritos.filter(d => d.codDistrito == state.codDistrito) : distritos,
                puntosPoligonoPadre: state.codDistrito > 0 ? distritos.find(d => d.codDistrito === state.codDistrito)?.puntosPoligonoDistrito : provincias.find(p => p.codProvincia === state.codProvincia)?.puntosPoligonoProvincia,
                keys: {
                    puntos: "puntosPoligonoDistrito",
                    codigo: "codDistrito",
                    nombre: "nomDistrito"
                }
            }})
        }
    }, [state.codDistrito])

    useEffect(() => {
        if (state.codDepartamento === 0 && departamentos.length > 0) {
            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: departamentos,
                puntosPoligonoPadre: paises.find(p => p.codPais === state.codPais)?.puntosPoligonoPais,
                keys: {
                    puntos: "puntosPoligonoDepartamento",
                    codigo: "codDepartamento",
                    nombre: "nomDepartamento"
                }
            }})
        }
    }, [departamentos])

    useEffect(() => {
        if (provincias.length > 0) {
            dispatch({ type: catastroTipo.CAMBIAR_VALOR, payload: { key: 'codProvincia', value: 0 }})

            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: provincias,
                puntosPoligonoPadre: departamentos.find(d => d.codDepartamento === state.codDepartamento)?.puntosPoligonoDepartamento,
                keys: {
                    puntos: "puntosPoligonoProvincia",
                    codigo: "codProvincia",
                    nombre: "nomProvincia"
                }
            }})
        }
    }, [provincias])

    useEffect(() => {
        if (distritos.length > 0) {
            dispatch({ type: catastroTipo.CAMBIAR_VALOR, payload: { key: 'codDistrito', value: 0 }})

            dispatch({ type: catastroTipo.MOSTRAR_POLIGONOS, payload: {
                arregloPoligonos: distritos,
                puntosPoligonoPadre: provincias.find(p => p.codProvincia === state.codProvincia)?.puntosPoligonoProvincia,
                keys: {
                    puntos: "puntosPoligonoDistrito",
                    codigo: "codDistrito",
                    nombre: "nomDistrito"
                }
            }})
        }
    }, [distritos])
    
    return {
        state,
        dispatch,
        departamentos,
        provincias,
        distritos
    }
}

export const CatastroTerritorial = () => {
    const { state, dispatch, departamentos, provincias, distritos } = useCatastroTerritorial();
    const mapDiv = useRef();

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   

    useEffect(() => {
        state.googleMap.inicializarMapa(mapDiv.current, { zoom: 6 });
    }, [])

    return (
        <>
            <div className='w-full h-full flex flex-col'>
                <ContenedorParametros
                    titulo='División Política'
                >
                    <div>
                        <select className={`${classNameInput}`} value={state.codDepartamento} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codDepartamento', value: Number(e.target.value)}})}>
                            <option value="0">-- Seleccionar --</option>
                            {
                                departamentos.map(d => {
                                    return <option key={d.codDepartamento} value={d.codDepartamento}>{d.nomDepartamento}</option>
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <select className={`${classNameInput}`} value={state.codProvincia} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codProvincia', value: Number(e.target.value)}})}>
                            <option value="0">-- Seleccionar --</option>
                            {
                                provincias.map(p => {
                                    return <option key={p.codProvincia} value={p.codProvincia}>{p.nomProvincia}</option>
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <select className={`${classNameInput}`} value={state.codDistrito} onChange={(e) => dispatch({type: catastroTipo.CAMBIAR_VALOR, payload: {key: 'codDistrito', value: Number(e.target.value)}})}>
                            <option value="0">-- Seleccionar --</option>
                            {
                                distritos.map(d => {
                                    return <option key={d.codDistrito} value={d.codDistrito}>{d.nomDistrito}</option>
                                })
                            }
                        </select>
                    </div>
                </ContenedorParametros>
                <div className='flex-1' ref={mapDiv}>
                </div>
            </div>
        </>
    )
}