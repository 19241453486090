import React, { useEffect, useRef, useState } from "react";

export const MultiSelector = ({
    opciones = [],
    value,
    setValue
}) => {
    const refDiv = useRef()
    const [isOpen,setIsOpen] = useState(false)

    useEffect(() => {
        const funcionHover = (event) => {
            (refDiv.current && !refDiv.current.contains(event.target)) && setIsOpen(false)
        }
        document.addEventListener('click',funcionHover)
        return(() => {
            document.removeEventListener('click',funcionHover)
        })
    },[])

    const agregarQuitarValor = (seleccionado) => {
        const arrClon = [...value]
        if(arrClon.some(e => e == seleccionado)) {
            return arrClon.filter(e => e != seleccionado) 
        }else{
            arrClon.push(seleccionado)
            return arrClon
        }
    }

    return (
        <div ref={refDiv} className="relative w-full flex flex-col">
            <div onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? 'border border-[#3b82f6]' : ''} bg-[#27272a] px-4 text-xs h-[30px] flex items-center justify-between rounded cursor-pointer`}>{value.length} seleccionados <i className={`fa ${isOpen ? 'fa-angle-up' : 'fa-angle-down'} `} aria-hidden="true"></i></div>
            <div className={`${isOpen ? '' : 'hidden'} absolute w-full bg-[#27272a] border border-[#767676] top-full`}>
                <ul className="w-full max-h-[200px] overflow-auto containerScroll">
                    {
                        opciones.map((data,i) => {
                            return (
                                <li 
                                    key={i+1}
                                    onClick={() => setValue(agregarQuitarValor(data.valor.toLowerCase()))}
                                    className={`${value.some(e => e.toLowerCase() == data.valor.toLowerCase()) ? 'bg-[#1967d2]' : 'hover:bg-[#1967d2]'} w-full text-xs px-4 py-1 cursor-pointer`}
                                >
                                    {data.texto}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
};
