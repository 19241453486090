import React, { useState } from 'react'
import { AuthFetch } from '../services/api'
import { notify } from '../utils/utils'

const urlApi = 'https://monitoreowebapi.abexa.pe'

export const useIndicadoresCentrosPoblados = () => {
    const [arrActualizadosPorMes,setArrActualizadosPorMes] = useState([])
    const [arrTotalizados,setArrTotalizados] = useState([])
    const [arrRegistrosCP,setArrRegistrosCP] = useState([])

    const listarActualizadosPorMes = async () => {
        const response = await AuthFetch({
            url: urlApi + '/listarActializadosPorMes'
        })
        if(response.isValid){
            setArrActualizadosPorMes(response.content)
        }else{
            notify('Ocurrio un error','error')
        }
    }

    const listarTotalizados = async () => {
        const response = await AuthFetch({
            url: urlApi + '/listarTotalizados'
        })
        if(response.isValid){
            setArrTotalizados(response.content[0])
        }else{
            notify('Ocurrio un error','error')
        }
    }

    const listarRegistrosCP = async () => {
        const response = await AuthFetch({
            url: urlApi + '/listarRegistrosCP'
        })
        if(response.isValid){
            setArrRegistrosCP(response.content[0])
        }else{
            notify('Ocurrio un error','error')
        }
    }

    return {
        listarActualizadosPorMes,
        arrActualizadosPorMes,

        listarTotalizados,
        arrTotalizados,
        
        listarRegistrosCP,
        arrRegistrosCP
    }
}