import React, { useState, useContext, useEffect } from 'react';
import { Modal } from '../app/components/modal/Modal';
import { useModal } from './useModal';
import { notify } from '../utils/utils';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';


const urlBaseAlertas = process.env.REACT_APP_PLAMIN_API + '/api/Entities'

const tipoAlertaDefaul = {
    codTipoAlerta: 0,
    nombre:"",
    abreviatura: "",
    codCategoria:"",
}

export const useTiposAlertas = () => {
    const { stateUser}= useContext(UserContext);
    const [ tiposAlertas, setTiposAlertas ] = useState([])
    const [ tipoAlerta, setTipoAlerta ] = useState(tipoAlertaDefaul)
const [isOpen, openModal, closeModal] = useModal()
 
    useEffect(()=> {
        (async ()=> {
            await listarTipoAlertas()
        })()
    },[]);

    const listarTipoAlertas = async (codEntidad) => {
        const response = await AuthFetch({
            url: urlBaseAlertas + '/type?' + new URLSearchParams({
                codEntidad: codEntidad || 0
            })
        });

        if(response.isValid){
            setTiposAlertas(response.content);
        }else{
            notify(response.content, 'error');
        }
    }



    const listarTipoAlertasPorCategorias = async (codCategoria) => {
        const response = await AuthFetch({
            url: urlBaseAlertas + '/typeAlerts?' + new URLSearchParams({
                codAlertaTipoCategoria: codCategoria || 0
            })
        });

        if(response.isValid){
            setTiposAlertas(response.content);
        }else{
            notify(response.content, 'error');
        }
    }
    const guardarEditarTipoAlerta = async () => {

        
        const esGuardar = tipoAlerta.codTipoAlerta <= 0;
        const response = await AuthFetch({
            url: urlBaseAlertas + '/endpointRegisro',
            method: esGuardar ? 'POST' : 'PUT', 
            body: JSON.stringify({
                ...(!esGuardar && { codTipoAlerta: tipoAlerta.codTipoAlerta }),
                nomUsuario: tipoAlerta.nomUsuario,
                claveUsuario: tipoAlerta.claveUsuario,
                codPersona: tipoAlerta.codPersona,
                imagenUsuario:tipoAlerta.imagenUsuario,
                codUsuarioTipo: tipoAlerta.codUsuarioTipo,
                codUsuarioAccion: tipoAlerta.codUsuarioAccion,//stateUSer.codUsuario,
                codComisaria: tipoAlerta.codComisaria
            })
        });
        notify(response.content,(response.isValid ? 'success' : 'error'));
        if (response.isValid){
            setTipoAlerta(tipoAlertaDefaul);
            await listarTipoAlertas();
        }
        // closeModal();
    }

    const editarValorTipoAlerta = (key, value) => {
        setTiposAlertas(tipoAlerta => {
            return {
                ...tipoAlerta,
                [key]:value
            }
        });
    }

    return {
        tiposAlertas,
        editarValorTipoAlerta,
        listarTipoAlertasPorCategorias,
        listarTipoAlertas,        
    };

}
